import { useGetContract } from 'helpers/hooks/useGetContract';
import { useState } from 'react';
import { callNotification } from 'helpers/notification';
import { parseErrorToUserReadableMessage } from 'helpers/format';
import BigNumber from "bignumber.js";
import { liquidityAbi } from '../contractsAbi/liquidityAbi';
import { useWeb3React } from '@web3-react/core';
import { Contract } from '@ethersproject/contracts';

export function transactionalNumberToNumber(amount, decimals = 18) {
  return new BigNumber(amount, 10)
    .dividedBy(new BigNumber(10).pow(decimals))
    .toNumber();
}

export const useFrgxPriceByAmount = () => {
  const [frgxPriceByAmountIsLoading, setFrgxPriceByAmountIsLoading] = useState(false);
  const { getContract } = useGetContract();
  const [frgxPrice, setFrgxPrice] = useState(null);
  const [frgxPriceBase, setFrgxPriceBase] = useState(null);
  const { account, provider } = useWeb3React();

  const getFrgxPriceByAmount = async (amount, bumpPercent = 5, baseAmount = null) => {
    if (!frgxPriceByAmountIsLoading) {
      setFrgxPrice(null);
      setFrgxPriceByAmountIsLoading(true);
      try {
        const contractPancake = await new Promise(function(resolve, rejected) {
          if (provider) {
            const contract = new Contract(
              '0x3b672190BC166a72BF146994151513773Af1dB86',
              liquidityAbi,
              provider?.getSigner(account).connectUnchecked() || provider,
            );

            resolve(contract);
          } else {
            rejected("error init contract: ");
          }
        });
        // const contractPancake = await getContract('pancake');

        const [reserve0, reserve1] = await contractPancake.getReserves();
        const frgxCurrency =
          transactionalNumberToNumber(reserve1.toString()) / transactionalNumberToNumber(reserve0.toString());

        const total = parseFloat(amount / frgxCurrency);

        const totalRegistryPrice = (total + (total / 100) * bumpPercent).toFixed(3);

        if (baseAmount) {
          const baseTotal = parseFloat(baseAmount / frgxCurrency);
          const baseTotalPrice = (baseTotal + (baseTotal / 100) * bumpPercent).toFixed(3);

          setFrgxPriceBase(baseTotalPrice);
        }

        setFrgxPrice(totalRegistryPrice);

        return totalRegistryPrice;
      } catch (e) {
        console.log(e);
        callNotification({ type: 'error', message: parseErrorToUserReadableMessage(e) });
      } finally {
        setFrgxPriceByAmountIsLoading(false);
      }
    }
  };

  return {
    frgxPrice,
    frgxPriceBase,
    frgxPriceByAmountIsLoading,
    getFrgxPriceByAmount,
  };
};
