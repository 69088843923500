import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getMatrixB } from '../../../../store/matrixBSlice/selectors';
import { useCheckMatrixStatus } from '../../../../helpers/hooks/useCheckMatrixStatus';
import { LEVEL_STATUSES } from '../../../../helpers/instrument';
import { ActivateLvlModal } from '../../../../components/Modals/ActivateLvlModal';
import { useModal } from '../../../../helpers/hooks/useModal';
import { Link } from 'react-router-dom';
import { useCheckPreviewMode } from '../../../../helpers/hooks/useCheckPreviewMode';

export const LevelsDays = () => {
  const matrixInfo = useSelector(getMatrixB);
  const [currentLevel, setCurrentLevel] = useState(null);
  const { matrixStatus, checkMatrixStatus } = useCheckMatrixStatus();
  const { onOpen, onClose, openedModal } = useModal();
  const { isPreviewMode } = useCheckPreviewMode();

  useEffect(() => {
    if (matrixInfo.levels) {
      checkMatrixStatus(matrixInfo);
    }
  }, [matrixInfo]);

  const filterInfo = (level) => {
    switch (level.status) {
      case LEVEL_STATUSES.GREEN:
        return {
          star: 'greenStar',
          blackText: true,
        };
      case LEVEL_STATUSES.ORANGE:
        return {
          star: 'orangeStar',
          blackText: true,
        };
      case LEVEL_STATUSES.RED:
        return {
          star: 'redStar',
          blackText: false,
        };
      default:
        return null;
    }
  };

  const openModal = (level) => {
    setCurrentLevel(level);
    onOpen();
  };

  return (
    <div className="flex items-center justify-between space-x-3 w-full h-full max-h-[310px] levels-days-bg rounded-3xl backdrop-blur-large p-6 sm:p-4 sm:max-h-full">
      {matrixStatus?.length > 0 ? (
        <>
          {matrixStatus.slice(0, 3)?.map((item, itemIndex) => {
            return (
              <div
                className="relative flex flex-col items-center justify-between h-full w-full py-4 bg-white-30 rounded-2xl levels-days-shadow sm:min-h-[200px] overflow-hidden"
                key={itemIndex}
              >
                <span className="font-light text-white-500 z-[111]">{item?.remainingDays} days left</span>
                <div className="absolute top-[47%] sm:top-[45%] -translate-y-1/2 flex items-centet justify-center w-[180%]">
                  <img src={`/icons/dashboard/levelsDays/${filterInfo(item)?.star}.svg`} />
                  <span
                    className={`absolute top-[51%] -translate-y-1/2 text-[15px] ${
                      filterInfo(item)?.blackText ? 'text-black' : 'text-white'
                    } poppins font-medium`}
                  >
                    Lvl {item?.levelNumber}
                  </span>
                </div>
                {!isPreviewMode && (
                  <button
                    onClick={() => openModal(item)}
                    className="z-[3] bg-primary-500 px-[17px] h-[30px] rounded-[30px]"
                  >
                    <span className="text-black text-sm poppins">Extend</span>
                  </button>
                )}
              </div>
            );
          })}
        </>
      ) : (
        <div className="flex flex-col items-center justify-between h-full w-full p-4 sm:space-y-8">
          <span className="text-sm text-white-500 font-light text-center">
            All your levels are over <br />
            Activate again to receive rewards
          </span>
          <div className="relative flex-shrink-0 flex items-center justify-center">
            <img className="w-[60px] h-[60px]" src="/icons/instrument/star_disabled.svg" />
            <img
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-6 h-6"
              src="/icons/instrument/star_lock.svg"
            />
          </div>
          <Link
            to="/programs/frgx"
            className="flex items-center justify-center bg-primary-500 h-[30px] px-[17px] rounded-[30px]"
          >
            <span className="text-primary-950 poppins text-sm leading-[20px] font-medium">Go to Levels</span>
          </Link>
        </div>
      )}
      <ActivateLvlModal openedModal={openedModal} onClose={onClose} level={currentLevel?.levelNumber} />
    </div>
  );
};
