import React from 'react';
import { ItemFarm } from './ItemFarm';
import { CreateNewFarm } from './CreateNewFarm';

export const Farms = ({ stakingState, fetchStakingInfo }) => {
  const { userStakes } = stakingState;
  const filteredUserStakes = userStakes.filter((item) => !!item.activationTime);

  return (
    <div className="flex flex-col space-y-9 w-full overflow-auto">
      <span className="text-4xl text-white font-medium poppins">Your stakes</span>

      <div className="grid grid-cols-3 gap-6 sm:flex-col sm:space-x-0 sm:grid-cols-1">
        {!!filteredUserStakes.length &&
          filteredUserStakes?.map((item) => {
            return (
              <ItemFarm
                key={item.index}
                {...item}
                index={item.index}
                stakingState={stakingState}
                fetchStakingInfo={fetchStakingInfo}
              />
            );
          })}
        {filteredUserStakes.length < 5 && (
          <CreateNewFarm fetchStakingInfo={fetchStakingInfo} stakingState={stakingState} />
        )}
      </div>
    </div>
  );
};
