import React, { useEffect, useMemo, useState } from 'react';
import { parseCookies } from 'nookies';

import { BaseLayout } from '../../layouts';
import { Input, BreadCrumbs, LoadingAnimation, PageLockedBanner } from '../../components';
import { useAuth } from '../../helpers/hooks/useAuth';
import { UserRepository } from '../../connectors/repositories/user';
import { useRequest } from '../../helpers/hooks/useRequest';
import { useModal } from '../../helpers/hooks/useModal';
import { AuthModal } from '../../components/Modals/AuthModal';
import { callNotification } from '../../helpers/notification';
import { ImageUploader } from '../../components/ImageUploader';
import { UploadRepository } from '../../connectors/repositories/upload';
import { useCheckPreviewMode } from '../../helpers/hooks/useCheckPreviewMode';

export const Settings = () => {
  const account = localStorage.getItem('account');
  const { openedModal, onClose, onOpen } = useModal();
  const { authAccount, isComplete } = useAuth();
  const { data, call, isLoading } = useRequest(UserRepository.getProfile);
  const [inputsValue, setInputsValue] = useState({
    nickname: '',
    email: '',
    x: '',
    instagram: '',
  });
  const [avatar, setAvatar] = useState(null);
  const { isPreviewMode, isDoneCheckedPreview } = useCheckPreviewMode();

  const cookies = parseCookies();
  const apiToken = useMemo(() => {
    return cookies?.apiToken;
  }, [isComplete, account]);

  useEffect(() => {
    if (!isPreviewMode && isDoneCheckedPreview) {
      if (!apiToken) {
        onOpen();
        return;
      }
      onClose();
      call();
    }
  }, [apiToken, account, isPreviewMode, isDoneCheckedPreview]);

  const onLogIn = () => {
    authAccount();
  };

  useEffect(() => {
    if (!data) return;
    if (data?.imageUrl) {
      localStorage.setItem('avatar', data?.imageUrl);
      setAvatar(data?.imageUrl);
    }
    setInputsValue({
      nickname: data?.nickname,
      email: data?.email,
      x: data?.x,
      instagram: data?.instagram,
    });
    localStorage.setItem('nickname', data?.nickname);
  }, [data]);

  const settingsList = [
    {
      key: 'nickname',
      title: 'Username',
      placeholder: 'Enter username',
    },
    {
      key: 'email',
      title: 'Email Address',
      placeholder: 'Enter your email',
    },
    {
      key: 'x',
      title: 'X (Twitter)',
      placeholder: 'Enter your twitter',
    },
    {
      key: 'instagram',
      title: 'Instagram',
      placeholder: 'Enter your instagram',
    },
  ];

  const onChangeInput = (event, key) => {
    setInputsValue((prev) => ({
      ...prev,
      [key]: event.target.value,
    }));
  };

  const onSaveSettings = async () => {
    await UserRepository.postProfile(inputsValue.nickname, inputsValue.email, inputsValue.x, inputsValue.instagram)
      .then(() => {
        localStorage.setItem('nickname', inputsValue?.nickname);
        callNotification({ type: 'success', message: 'Settings saved' });
      })
      .catch((e) => callNotification({ type: 'error', message: e?.response?.data?.message || 'Something went wrong' }));
  };

  const onSaveAvatar = async () => {
    await UploadRepository.postAvatar(avatar)
      .then((response) => {
        localStorage.setItem('avatar', response?.newImageUrl);
        callNotification({ type: 'success', message: 'Settings saved' });
      })
      .catch((e) => callNotification({ type: 'error', message: e?.response?.data?.message || 'Something went wrong' }));
  };

  return (
    <BaseLayout>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <>
          <div className="flex flex-col items-start pb-6 relative space-y-9 sm:flex-col sm:space-x-0 sm:space-y-8 sm:items-start z-[1] ">
            <BreadCrumbs title="Profile" />
            {isPreviewMode ? (
              <PageLockedBanner />
            ) : (
              <div className="flex space-x-[80px] sm:flex-col-reverse sm:space-x-0 sm:w-full">
                <div className="flex flex-col space-y-12 sm:pt-8 sm:space-y-8">
                  <div className="flex flex-col space-y-8 ">
                    {settingsList?.map((item, itemIndex) => {
                      return (
                        <div
                          key={itemIndex}
                          className="flex flex-col space-y-4 sm:space-y-3 min-w-[450px] sm:min-w-full"
                        >
                          <span className="poppins text-lg text-white">{item?.title}</span>
                          <Input
                            placeholder={item?.placeholder}
                            value={inputsValue?.[item.key] || ''}
                            onChange={(e) => onChangeInput(e, item.key)}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <button
                    onClick={onSaveSettings}
                    className="flex items-center justify-center bg-secondary-500 px-5 h-[48px] rounded-2xl max-w-[150px] sm:max-w-full"
                  >
                    <span className="text-base text-white poppins">Save settings</span>
                  </button>
                </div>
                <div className="flex flex-col space-y-6 sm:space-y-4">
                  <span className="poppins text-lg text-white">Profile Image</span>
                  <ImageUploader
                    className="cursor-pointer w-[165px] rounded-[15px] h-[165px] flex justify-center items-center"
                    maxFileSize={2097152}
                    value={avatar}
                    onChange={(value) => setAvatar(value)}
                  >
                    <div className="bg-white-30 w-[152px] h-[152px] p-2.5 rounded-3xl">
                      <button className="flex items-center justify-center w-full h-full bg-white-70 rounded-2xl">
                        <img className="w-12 h-12" src="/icons/settings/pencil.svg" />
                      </button>
                    </div>
                  </ImageUploader>
                  <button
                    onClick={onSaveAvatar}
                    className="flex mt-[40px] items-center justify-center bg-secondary-500 px-5 h-[48px] rounded-2xl max-w-[150px] sm:max-w-full"
                  >
                    <span className="text-base text-white poppins">Save avatar</span>
                  </button>
                </div>
              </div>
            )}
          </div>
          <img className="absolute h-full top-0 right-0 sm:hidden z-[0]" src="/images/settings/topShadow.png" />
          <img className="absolute h-full bottom-0 right-0 z-[0]" src="/images/settings/bottomShadow.png" />
        </>
      )}
      <AuthModal openedModal={openedModal} onLogIn={onLogIn} />
    </BaseLayout>
  );
};
