import React from 'react';
import { LevelsStatus } from '../LevelsStatus';

export const Header = () => {
  return (
    <div className="fixed top-0 w-full z-[99] bg-[#0B0B0B]">
      <div className=" hidden sm:flex flex justify-between items-center flex-shrink-0 items-center px-4 h-[72px] rounded-b-3xl w-full">
        <img className="w-[39px] h-[36px]" src="/images/header/logo.png" />
        <LevelsStatus />
      </div>
    </div>
  );
};
