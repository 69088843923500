import React, { Children } from 'react';

export const AboutBorder = ({ children, className }) => {
  return (
    <div className="about-blocks-border p-[1px] rounded-3xl">
      <div
        className={`flex flex-col items-center justify-center w-full h-full bg-[#080909] rounded-3xl overflow-hidden relative ${className}`}
      >
        {children}
      </div>
    </div>
  );
};
