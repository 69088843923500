import React, { useState } from "react";
import Countdown from "react-countdown";
import { startUsdtMatrixTime } from "../../../helpers/constants";
import { fromUnixTime } from "date-fns";

export const Timer = ({ isCompleted, setIsCompleted }) => {
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
          return null;
        } else {
            const finalHours = hours < 10 ? `0${hours}` : hours;
            const finalMinutes = minutes < 10 ? `0${minutes}` : minutes;
            const finalSeconds = seconds < 10 ? `0${seconds}` : seconds;
          return (
           <div className="flex items-center justify-center font-bold">
                <span className="w-[25px] flex items-center justify-center">{finalHours}</span>
                <span className="w-[5px] flex items-center justify-center">:</span>
                <span className="w-[25px] flex items-center justify-center">{finalMinutes}</span>
                <span className="w-[5px] flex items-center justify-center">:</span>
                <span className="w-[25px] flex items-center justify-center">{finalSeconds}</span>
           </div>
          );
        }
      };

      if (isCompleted) {
        return null;
      }
    return (
        <Countdown date={fromUnixTime(startUsdtMatrixTime)} renderer={renderer} onComplete={() => setIsCompleted(true)} />
    )
}