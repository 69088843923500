import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getMatrixB } from '../../store/matrixBSlice/selectors';
import { useCheckMatrixStatus } from '../../helpers/hooks/useCheckMatrixStatus';
import clsx from 'clsx';

export const LevelsStatus = ({ className = '' }) => {
  const matrixInfo = useSelector(getMatrixB);
  const { matrixStatus, checkMatrixStatus } = useCheckMatrixStatus();
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (matrixInfo.levels) {
      checkMatrixStatus(matrixInfo);
    }
  }, [matrixInfo]);

  if (!matrixStatus[0]) {
    return null;
  }

  return (
    <div
      className={clsx(
        `flex items-center space-x-2 ${matrixStatus[0]?.colorStatus?.bg} h-[40px] rounded-xl w-fit`,
        className,
      )}
    >
      <button onClick={() => setIsOpened((temp) => !temp)}>
        <img className="p-1" src="/icons/dashboard/userInfo/staticIcon.svg" />
      </button>
      {isOpened && (
        <>
          <span className="text-base text-black">Level {matrixStatus[0]?.levelNumber}</span>
          <div className="flex items-center justify-center rounded-lg bg-[#0B0B0D] w-[115px] h-[30px]">
            <span className={`${matrixStatus[0]?.colorStatus?.text} text-base`}>
              {matrixStatus[0]?.remainingDays} days left
            </span>
          </div>
          <div className="w-0.5"></div>
        </>
      )}
    </div>
  );
};
