import React, { useEffect, useState } from 'react';
import { BreadCrumbs, LevelsStatus } from 'components';
import { useLazyQuery } from '@apollo/client';
import { BaseLayout } from 'layouts';
import { shortenAddress } from '../../helpers/format';
import { PartnersInfoModal } from '../../components/Modals/PartnersInfoModal';
import { useModal } from '../../helpers/hooks/useModal';
import { GET_USER_PARTNERS, CHECK_UPLINE_INTERNAL_ID } from '../../helpers/graphRequests';
import { useWeb3React } from '@web3-react/core';
import { copy } from '../../helpers/copy';
import { fromUnixTime, isBefore } from 'date-fns';
import { UserRepository } from '../../connectors/repositories/user';
import { getUser } from '../../store/userSlice/selectors';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getAddress } from '../../helpers/format';
import { useCheckUpline } from '../../helpers/hooks/useCheckUpline';

export const Partners = ({ refNumber = 0 }) => {
  const { openedModal, onClose, onOpen } = useModal();
  const { account } = useWeb3React();
  const userRefLink = useSelector(getUser);
  const [currentUserId, setCurrentUserId] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const previewUserId = searchParams.get('previewUser') || null;

  const refLink = window.location.host + '/?ref=' + userRefLink?.refNumber;

  const {
    isValidUpline: isValidPreviewId,
    loadingCheckUpline: loadingCheckPreviewId,
    checkUpline: checkPreviewId,
    isDoneChecked,
    uplineQraphInfo: userQraphInfo,
  } = useCheckUpline();

  useEffect(() => {
    if (!!previewUserId) {
      checkPreviewId({ value: previewUserId });
    }
  }, [previewUserId]);

  useEffect(() => {
    if (userQraphInfo) {
      setCurrentUserId(userQraphInfo?.id);
    }
  },[userQraphInfo])


  useEffect(() => {
    if (!getAddress(previewUserId) && !account) {
      checkPreviewId({ value: previewUserId });
    } else {
      if (!!account) {
        setCurrentUserId(account);
      } else if (!!getAddress(previewUserId)) {
        setCurrentUserId(previewUserId);
      } else {
        setCurrentUserId(null);
      }
    }
  }, [account, previewUserId]);

  const [callRequest, { data }] = useLazyQuery(GET_USER_PARTNERS, {
    variables: { user: null },
  });

  console.log(currentUserId, !!account)

  useEffect(() => {
    if (currentUserId) {
      callRequest({ variables: { user: currentUserId?.toLocaleLowerCase() } });
    }
  }, [currentUserId]);

  const [currentUser, setCurrentUser] = useState(null);

  const openCurrentUser = (item) => {
    setCurrentUser(item);
    callUserInfo(item?.id);
    onOpen();
  };

  const callUserInfo = async (id) => {
    try {
      const result = await UserRepository.getProfileWithoutToken(id);
      if (result) {
        setCurrentUser((prev) => ({ ...prev, ...result }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const chechCountActiveLvls = (levels) => {
    return levels.reduce((acc, item) => {
      if (Number(item.expiredAt) !== 0 && isBefore(new Date(), fromUnixTime(item.expiredAt))) {
        return (acc = acc + 1);
      }
      return acc;
    }, 0);
  };
  const chechCountActiveLvlsUsdt = (levels) => {
    return levels.reduce((acc, item) => {
      if (Number(item.activationTimes) !== 0) {
        return (acc = acc + 1);
      }
      return acc;
    }, 0);
  };
  return (
    <BaseLayout>
      <div className="flex items-center space-x-6 pb-6 relative sm:flex-col sm:space-x-0 sm:space-y-4 sm:items-start">
        <BreadCrumbs title="Your Partners">
          <LevelsStatus />
        </BreadCrumbs>
      </div>
      <div className="flex flex-col py-7 tableBg rounded-3xl overflow-auto sm:py-3 space-y-[50px]">
        {data?.users?.length === 0 ? (
          <div className="flex flex-col items-center justify-center min-h-[478px] w-full space-y-6">
            <img src="/icons/partners/folderIcon.svg" />
            <span className="text-white-300 text-base">You don't have partners, invite now</span>
            <button
              onClick={() => copy(refLink)}
              className="flex items-center justify-center bg-white-70 min-w-[351px] rounded-2xl py-3 shadow-item backdrop-blur-large"
            >
              <span className="text-base text-white poppins font-semibold">Copy Referral Link</span>
            </button>
          </div>
        ) : (
          <div className="relative flex flex-col w-full flex-1">
            <table className="min-w-max w-full table-auto">
              <thead>
                <tr className="text-white-500 text-xs text-sm text-white-500">
                  <th className="p-4 text-left sm:px-2">ID</th>
                  <th className="p-4 text-left sm:px-2">Wallet</th>
                  <th className="p-4 text-center sm:px-2">Active Lvls (FRGX)</th>
                  <th className="p-4 text-center sm:px-2">Active Lvls (USDT)</th>
                  <th className="p-4 text-left sm:px-2">Info</th>
                </tr>
              </thead>
              <tbody className="text-sm text-white align-center sm:w-full">
                {data?.users?.map((item, itemIndex) => {
                  return (
                    <tr className={`${itemIndex % 2 === 0 ? 'bg-white-30' : ''} w-full `} key={itemIndex}>
                      <td className="px-4 text-left whitespace-nowrap sm:px-2 h-[64px] ">
                        <span>ID {item?.internalId}</span>
                      </td>
                      <td className="px-4 text-left whitespace-nowrap sm:px-2 h-[64px] flex items-center space-x-2 w-fit">
                        <span className="sm:hidden">{item?.id}</span>
                        <span className="hidden sm:flex">{shortenAddress(item?.id)}</span>
                        <button onClick={() => copy(item?.id)} className="p-1 rounded-lg bg-white-70">
                          <img className="shrink-0" src="/icons/partners/copyIcon.svg" />
                        </button>
                      </td>
                      <td className="px-4 text-center whitespace-nowrap sm:px-2 h-[64px] ">
                        <span>{chechCountActiveLvls(item.levels)}</span>
                      </td>
                      <td className="px-4 text-center whitespace-nowrap sm:px-2 h-[64px] ">
                        <span>{chechCountActiveLvlsUsdt(item.levels2)}</span>
                      </td>
                      <td className="px-4 text-right whitespace-nowrap sm:px-2 h-[64px] ">
                        <button
                          onClick={() => openCurrentUser(item)}
                          className="flex items-center justify-between bg-white-70 rounded-full py-1.5 max-w-[91px] px-2 w-full"
                        >
                          <span className="text-xs text-white font-semibold">More info</span>
                          <img src="/icons/partners/infoIcon.svg" />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <PartnersInfoModal openedModal={openedModal} onClose={onClose} currentUser={currentUser} />
    </BaseLayout>
  );
};
