import React, { useMemo } from 'react';
import { bigIntToInt } from '../../../../helpers/numbers';
import { isAfter, fromUnixTime } from 'date-fns';
import { Link } from 'react-router-dom';
import { getUnixTime, format } from 'date-fns';

export const TotalActivity = ({ matrixInfo = {}, partners24 = 0 }) => {
  const profitFrgx24 =
    !!matrixInfo?.levels &&
    matrixInfo?.levels?.reduce((result, item) => {
      let isTodayReward = Number(item?.lastRewardDay) === Math.floor(getUnixTime(new Date()) / (60 * 60 * 24));
      let temp = isTodayReward ? bigIntToInt(item?.reward24) || 0 : 0;
      return result + temp;
    }, 0);
  const profitUsdt24 =
    !!matrixInfo?.levels2 &&
    matrixInfo?.levels2?.reduce((result, item) => {
      let isTodayReward = Number(item?.lastRewardDay) === Math.floor(getUnixTime(new Date()) / (60 * 60 * 24));
      let temp = isTodayReward ? bigIntToInt(item?.reward24) || 0 : 0;
      return result + temp;
    }, 0);

  const activatyInfo = useMemo(
    () => [
      {
        title: 'Partners',
        icon: 'partnerIcon.svg',
        value: `+${partners24}` || 0,
      },
      {
        title: 'Profit',
        icon: 'frgx.png',
        value: `+${profitFrgx24}` || 0,
      },
      {
        title: 'Profit',
        icon: 'usdt.png',
        value: `+${profitUsdt24}` || 0,
      },
    ],
    [partners24, profitFrgx24, profitUsdt24],
  );
  return (
    <div className="w-full flex flex-col justify-between p-6 total-activity-bg max-w-[286px] h-full max-h-[310px] rounded-3xl backdrop-blur-large sm:max-w-full sm:max-h-full space-y-6 sm:p-0 sm:pt-6 sm:px-4 sm:pb-4">
      <span className="text-base poppins text-white-500 font-medium">Personal activity {format(new Date(), 'dd MMM')}</span>
      <div className="flex flex-col space-y-3">
        {activatyInfo?.map((item, itemIndex) => {
          return (
            <div className="flex items-center justify-between bg-white-30 rounded-2xl px-4 h-[48px]" key={itemIndex}>
              <div className="flex space-x-3 items-center w-full  ">
                <span className="font-light text-sm text-white-500">{item?.title}</span>
                <span className="text-white font-medium poppins text-lg">{item?.value}</span>
              </div>
              <img className="h-6 w-6" src={`/icons/dashboard/totalActivity/${item?.icon}`} />
            </div>
          );
        })}
        <Link
          to={'/programs' + location.search}
          className="flex items-center justify-center bg-white-70 rounded-2xl py-3.5  backdrop-blur-large shadow-item"
        >
          <span className="text-sm text-white poppins">Overview</span>
        </Link>
      </div>
    </div>
  );
};
