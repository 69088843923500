import React, { useState } from 'react';
import { BaseLayout } from '../../layouts';
import { BreadCrumbs, LevelsStatus } from '../../components';
import { ProgramCard } from '../../components/ProgramCard';

export const Programs = () => {
  return (
    <div className="flex flex-col">
      <BaseLayout>
        <div className="flex items-center space-x-6 pb-6 relative sm:flex-col sm:space-x-0 sm:space-y-4 sm:items-start">
          <BreadCrumbs className="sm:hidden" title="Programs">
            <LevelsStatus />
          </BreadCrumbs>
        </div>
        <div className="grid grid-cols-2 gap-2 sm:grid-cols-1">
          <ProgramCard programType={'usdt'} />
          <ProgramCard programType={'frgx'} />
        </div>
      </BaseLayout>
    </div>
  );
};
