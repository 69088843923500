import { contractFrgxAbi } from './contractsAbi/Frgx';
import { contractUsdtAbi } from './contractsAbi/Usdt';
import { contractMatrixBaseAbi } from './contractsAbi/matrixBase';
import { contractMatrixUsdtAbi } from './contractsAbi/matrixUsdt';
import { contractStakingPoolAbi } from './contractsAbi/stakingPool';
import { contractFarmingPoolAbi } from './contractsAbi/farmingPool';
import { contractInternalRouterAbi } from './contractsAbi/InternalRouter';
import { contractPaymentRouterAbi } from './contractsAbi/paymentRouter';
import { pancakeSwapAbi } from './contractsAbi/pancakeSwap';

export default {
  contractFrgx: process.env.REACT_APP_CONTRACT_FRGX,
  contractUsdt: process.env.REACT_APP_CONTRACT_USDT,
  contractMatrixBase: process.env.REACT_APP_CONTRACT_MATRIX_B,
  contractMatrixUsdt: process.env.REACT_APP_CONTRACT_MATRIX_USDT,
  contractFarmingPool: process.env.REACT_APP_CONTRACT_FARMING_POOL,
  contractStakingPool: process.env.REACT_APP_CONTRACT_STAKING_POOL,
  contractPancake: process.env.REACT_APP_PANCAKE,
  apiQraph: process.env.REACT_APP_GRAPH_URL,
  apiUrl: process.env.REACT_APP_API_URL,
  requestApiUrl: process.env.REACT_APP_API_URL,
  allowedChainId: process.env.REACT_APP_ALLOWED_CHAIN_ID,
  internalRouter: process.env.REACT_APP_CONTRACT_INTERNAL_ROUTER,
  paymentRouter: process.env.REACT_APP_CONTRACT_PAYMENT_ROUTER,

  stand: process.env.REACT_APP_STAND,

  contractUsdtAbi: contractUsdtAbi,
  contractFrgxAbi: contractFrgxAbi,
  contractMatrixBaseAbi: contractMatrixBaseAbi,
  contractMatrixUsdtAbi: contractMatrixUsdtAbi,
  contractStakingPoolAbi: contractStakingPoolAbi,
  contractFarmingPoolAbi: contractFarmingPoolAbi,
  contractInternalRouterAbi: contractInternalRouterAbi,
  contractPaymentRouterAbi: contractPaymentRouterAbi,
  contractPancakeAbi: pancakeSwapAbi,
};
