import React from 'react';
import { Modal } from '../../Modal';
import { Link } from 'react-router-dom';

export const AuthModal = ({ openedModal, onLogIn }) => {
  return (
    <Modal isOpened={openedModal} isDisableOnClose>
      <div className="flex relative flex-col items-center instrument-nonactivate-bg w-full rounded-large py-9 mt-[60px] z-[111] sm:rounded-2xl sm:px-[14px]">
        <Link to="/">
          <img className="absolute cursor-pointer top-4 right-6 h-[30px] w-[30px]" src="/icons/modal/closeIcon.svg" />
        </Link>

        <img className="pb-9 sm:pb-5" src="/icons/instrument/levelPrices/star.svg" />
        <button
          onClick={onLogIn}
          className="h-[62px] w-[351px] mb-5 mr-5 ml-5 bg-lightGray rounded-2xl shadow-wrapper sm:w-full"
        >
          <span className="text-base text-white poppins">Log in to view and edit profile</span>
        </button>
      </div>
    </Modal>
  );
};
