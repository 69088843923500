import React, { useMemo } from 'react';
import { Modal } from '../../Modal';
import { shortenAddress } from '../../../helpers/format';
import { copy } from '../../../helpers/copy';
import { bigIntToInt } from '../../../helpers/numbers';
import { fromUnixTime, isBefore } from 'date-fns';
import { Link } from 'react-router-dom';

export const PartnersInfoModal = ({ openedModal, onClose, currentUser }) => {
  const chechCountActiveLvls = () => {
    return currentUser?.levels.reduce((acc, item) => {
      if (Number(item.expiredAt) !== 0 && isBefore(new Date(), fromUnixTime(item.expiredAt))) {
        return (acc = acc + 1);
      }
      return acc;
    }, 0);
  };

  const chechCountActiveLvlsUsdt = () => {
    return currentUser?.levels2.reduce((acc, item) => {
      if (Number(item.activationTimes) !== 0) {
        return (acc = acc + 1);
      }
      return acc;
    }, 0);
  };

  const partnerInfo = useMemo(
    () => [
      {
        title: 'Active levels (FRGX)',
        count: chechCountActiveLvls(),
      },
      {
        title: 'Active levels (USDT)',
        count: chechCountActiveLvlsUsdt(),
      },
      {
        title: 'His Partners',
        count: currentUser?.partners || 0,
      },
      {
        title: 'His Profit FRGX',
        count: `${bigIntToInt(currentUser?.totalReward) || 0} FRGX`,
      },
      {
        title: 'His Profit USDT',
        count: `${bigIntToInt(currentUser?.totalReward2) || 0} USDT`,
      },
    ],
    [currentUser],
  );

  const styleBg = {
    backgroundImage: `url(${currentUser?.imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <Modal isOpened={openedModal} onClose={onClose} withoutClose={true}>
      <div className="flex flex-col items-center justify-center space-y-6 sm:w-full ">
        <div className="flex flex-col items-center justify-center space-y-2">
          <h2 className="leading-10 text-white poppins font-semibold sm:text-[36px]">Partner info</h2>
        </div>
        <div className="flex flex-col items-start justify-center partners-info-modal-bg  backdrop-blur-large border border-[1px] border-white-100 w-[326px] h-[3236] rounded-3xl space-y-6 p-6 sm:p-0 sm:px-4 sm:rounded-none sm:rounded-t-3xl sm:w-full sm:pt-6 sm:pb-[50px] mt-auto">
          <div className="flex justify-between items-center w-full">
            <div style={styleBg} className="w-[60px] h-[60px] bg-white rounded-full" />
            <div className="flex flex-col items-end space-y-2">
              <span className="poppins text-xl text-white font-semibold">{currentUser?.nickname}</span>
              <div className="flex space-x-[10px]">
                <span className="text-white-500 text-base">ID{shortenAddress(currentUser?.id)}</span>
                <button onClick={() => copy(currentUser?.id)} className="p-1 rounded-lg bg-white-70">
                  <img src="/icons/partners/copyIcon.svg" />
                </button>
              </div>
            </div>
          </div>
          <div className="border-[1px] border-white-70 w-full" />
          <div className="flex flex-col space-y-4 w-full text-white">
            {partnerInfo.map((item, itemIndex) => {
              return (
                <div className="flex justify-between w-full" key={itemIndex}>
                  <span className="text-xs">{item?.title}</span>
                  <span className="text-xs">{item?.count}</span>
                </div>
              );
            })}
          </div>
          <Link
            to={`/?previewUser=${currentUser?.internalId}`}
            className="flex items-center justify-between pl-4 pr-2 h-[56px] bg-white-70 w-full rounded-2xl"
          >
            <span className="text-base poppins text-white font-medium">Open profile</span>
          </Link>
        </div>
      </div>
    </Modal>
  );
};
