import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { shortenAddress } from '../../../../helpers/format';
import { MatrixUserInfoModal } from '../../../../components/Modals/MatrixUserInfoModal';
import { useModal } from '../../../../helpers/hooks/useModal';
import { UsdtMatrixItem } from '../../UsdtMatrix';
import { MatrixLevelInfoModal } from '../../../../components/Modals/MatrixLevelInfoModal';

export const PartnersStructure = ({ levelNumber, missedInfo, matrixs, isFreezed = false }) => {
  const [openedUser, setOpenedUser] = useState(null);

  const matrixsPlaces = [
    matrixs?.place1,
    matrixs?.place2,
    matrixs?.place3,
    matrixs?.place4,
    matrixs?.place5,
    matrixs?.place6,
    matrixs?.place7,
    matrixs?.place8,
    matrixs?.place9,
    matrixs?.place10,
    matrixs?.place11,
    matrixs?.place12,
    matrixs?.place13,
    matrixs?.place14,
  ];

  console.log(matrixs);

  const allPartners = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1,
      },
    },
  };

  const totalPartners = {
    hidden: { scale: 0, opacity: 0 },
    visible: {
      opacity: 1,
      scale: 1,
    },
  };

  const { openedModal, onClose, onOpen } = useModal();
  const { openedModal: openedInfoModal, onClose: onCloseInfo, onOpen: onOpenInfo } = useModal();

  const openCurrentUser = (item) => {
    setOpenedUser(item);
    onOpen();
  };

  const style = {
    backgroundImage: 'url(/images/instrument/main/freezeBg.webp)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  };

  return (
    <div className="relative flex items-start justify-evenly space-x-[55px] team-building-matrix-bg rounded-3xl h-[390px] sm:h-[200px] w-full space-x-3 py-8 sm:justify-center sm:space-x-[85px] sm:rounded-2xl">
      {/* left side */}
      <motion.div
        variants={allPartners}
        whileInView="visible"
        initial="hidden"
        className="flex flex-col items-center relative z-[2]"
      >
        <UsdtMatrixItem setCurrentUser={openCurrentUser} value={matrixsPlaces[0]} firstly={true} />
        <motion.div
          variants={totalPartners}
          className="flex space-x-[104px] absolute bottom-[-50px] sm:space-x-[42px] sm:bottom-[-24px] "
        >
          <UsdtMatrixItem setCurrentUser={openCurrentUser} value={matrixsPlaces[2]} />
          <UsdtMatrixItem setCurrentUser={openCurrentUser} value={matrixsPlaces[3]} />
        </motion.div>
        <motion.div
          variants={totalPartners}
          className="flex space-x-3 absolute bottom-[-125px] sm:bottom-[-57px] sm:space-x-[5.15px]"
        >
          <UsdtMatrixItem setCurrentUser={openCurrentUser} value={matrixsPlaces[6]} />
          <UsdtMatrixItem setCurrentUser={openCurrentUser} value={matrixsPlaces[7]} />
          <UsdtMatrixItem setCurrentUser={openCurrentUser} value={matrixsPlaces[8]} />
          <UsdtMatrixItem setCurrentUser={openCurrentUser} value={matrixsPlaces[9]} />
        </motion.div>
      </motion.div>

      {/* right side */}

      <motion.div
        variants={allPartners}
        whileInView="visible"
        initial="hidden"
        className="flex flex-col items-center relative z-[2]"
      >
        <UsdtMatrixItem setCurrentUser={openCurrentUser} value={matrixsPlaces[1]} firstly={true} />
        <motion.div
          variants={totalPartners}
          className="flex space-x-[104px] absolute bottom-[-50px] sm:space-x-[42px] sm:bottom-[-24px] "
        >
          <UsdtMatrixItem setCurrentUser={openCurrentUser} value={matrixsPlaces[4]} />
          <UsdtMatrixItem setCurrentUser={openCurrentUser} value={matrixsPlaces[5]} />
        </motion.div>
        <motion.div
          variants={totalPartners}
          className="flex space-x-3 absolute bottom-[-125px] sm:bottom-[-57px] sm:space-x-[5.15px]"
        >
          <UsdtMatrixItem setCurrentUser={openCurrentUser} value={matrixsPlaces[10]} />
          <UsdtMatrixItem setCurrentUser={openCurrentUser} value={matrixsPlaces[11]} />
          <UsdtMatrixItem setCurrentUser={openCurrentUser} value={matrixsPlaces[12]} />
          <UsdtMatrixItem setCurrentUser={openCurrentUser} value={matrixsPlaces[13]} />
        </motion.div>
      </motion.div>

      <MatrixUserInfoModal
        openedUser={openedUser}
        setOpenedUser={openCurrentUser}
        openedModal={openedModal}
        onClose={onClose}
      />
      <MatrixLevelInfoModal
        levelNumber={levelNumber}
        missedInfo={missedInfo}
        openedModal={openedInfoModal}
        onClose={onCloseInfo}
      />
      <button
        onClick={onOpenInfo}
        className="absolute top-5 right-5 sm:top-0 sm:right-0 opacity-75 z-[4] flex items-center justify-center h-10 w-10"
      >
        <img className="w-[30px] h-[30px]" src="/icons/instrument/matrixInfo.svg" alt="" />
      </button>
      {isFreezed && <div style={style} className="absolute z-[-1] top-0 left-0 w-full h-full !m-0" />}
    </div>
  );
};
