import React from 'react';

export const Staking = () => {
  return (
    <div className="flex items-center justify-between w-full max-w-[1115px] sm:px-4 sm:flex-col sm:pt-20">
      <img className="hidden sm:flex" src="/images/about/staking/stakingMob.png" />
      <div className="flex flex-col space-y-12">
        <div className="flex flex-col space-y-8 max-w-[584px] sm:space-y-6 sm:max-w-full">
          <span className="text-[54px] text-white poppins font-semibold text-left sm:text-h2">
            Qornex <span className="about-title">Staking</span> <span className="about-title">Strategy</span> for
            earning FRGX on BNB Chain
          </span>
          <span className="text-white-500 leading-8 font-light">
            Staking in Qornex allows participants to earn income by locking FRGX tokens for a certain period of time.
            Rewards for staking are accrued in FRGX.
          </span>
        </div>
        <div className="flex justify-between rounded-medium staking-table-bg backdrop-blur-large p-12 sm:p-6 sm:rounded-3xl">
          <div className="space-y-6">
            <span className="text-white-500 text-sm poppins">
              Token <br />
              locking period
            </span>
            <div className="flex flex-col space-y-4">
              <span className="text-white leading-6">30 days</span>
              <span className="text-white leading-6">60 days</span>
              <span className="text-white leading-6">90 days</span>
              <span className="text-white leading-6">180 days</span>
              <span className="text-white leading-6">360 days</span>
            </div>
          </div>
          <div className="space-y-6">
            <span className="text-white-500 text-sm poppins">
              Number of <br /> locks per 1 account
            </span>
            <div className="flex flex-col space-y-4">
              <span className="text-white leading-6">1</span>
              <span className="text-white leading-6">1</span>
              <span className="text-white leading-6">1</span>
              <span className="text-white leading-6">1</span>
              <span className="text-white leading-6">1</span>
            </div>
          </div>
          <div className="space-y-6">
            <span className="text-white-500 text-sm poppins">
              Deposit <br />
              type
            </span>
            <div className="flex flex-col space-y-4">
              <span className="text-white leading-6">Basic</span>
              <span className="text-white leading-6">Advanced</span>
              <span className="text-white leading-6">Increased</span>
              <span className="text-white leading-6">Enhanced</span>
              <span className="text-white leading-6">Maximum</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-8 max-w-[584px] sm:space-y-6">
          <span className="text-white-500 leading-8 font-light">
            Staking gives holders the opportunity to capitalize on their tokens, and provides additional stability for
            the FRGX community.
          </span>
          <span className="text-white-500 leading-8 font-light">
            Interest on staking and earning FRGX tokens on BNB Chain is determined according to the following formula.
          </span>
        </div>
      </div>
      <img className="sm:hidden" src="/images/about/staking/staking.png" />
    </div>
  );
};
