import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

export const Main = ({ matrixProfit, programType }) => {
  const cardInfo = useMemo(() => {
    switch (programType) {
      case 'usdt': {
        return {
          tokenIcon: 'usdt',
          shadow: 'greenShadow',
          title: 'USDT',
          linkButtonBg: 'bg-[#50AF95]',
          desc: 'Matrix program for affiliate marketing with rewards in USDT and FRGX without level expiration dates.',
        };
      }
      case 'frgx': {
        return {
          tokenIcon: 'frgx',
          shadow: 'purpleShadow',
          title: 'FRGX',
          linkButtonBg: 'bg-[#6850F3]',
          desc: 'DeFi platform for building online business with rewards from farming and affiliate marketing.',
        };
      }
    }
  }, [programType]);

  return (
    <div className="flex flex-col justify-between w-full h-[275px] border-[1px] border-white-100 instruments-nonactive-bg rounded-3xl p-6 relative overflow-hidden backdrop-blur-large">
      <div className="flex flex-col space-y-[20px] z-[1]">
        {/* <div className="flex justify-between w-full ">
          <span className="text-lg text-white poppins font-semibold">Program</span>
        </div> */}
        <div className="flex space-x-6 items-end">
          <img className="w-20 h-20" src={`/images/instruments/main/${cardInfo?.tokenIcon}.png`} />
          <div className="flex flex-col space-y-1">
            <span className="text-base text-white-500 poppins font-semibold">Profit</span>
            <span className="text-h1 text-white poppins font-semibold">{matrixProfit}</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-3 z-[1]">
        <span className="text-white poppins text-lg font-semibold">Qornex {cardInfo?.title}</span>
        <div className="w-full flex justify-between">
          <span className="text-sm text-white-500 font-light max-w-[337px]">{cardInfo?.desc}</span>
          <Link
            to={`/programs/${programType}` + location.search}
            className={`flex-shrink-0 w-10 h-10 flex justify-center items-center rounded-xl ${cardInfo?.linkButtonBg}`}
          >
            <img src="/icons/instruments/arrow.svg" />
          </Link>
        </div>
      </div>
      <img
        className="absolute bottom-0 left-0 z-[0] w-full"
        src={`/images/instruments/shadows/${cardInfo?.shadow}.png`}
      />
    </div>
  );
};
