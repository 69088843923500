import React, { useMemo } from 'react';
import { ProgramCard } from '../..';
import Countdown from 'react-countdown';
import { fromUnixTime } from 'date-fns';
import config from '../../../../helpers/config';
import { startUsdtMatrixTime } from '../../../../helpers/constants';

export const Timer = ({ setIsComplete }) => {

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return null;
    } else {
      return (
        <div className="flex flex-col justify-between items-center w-full h-[275px] max-w-[546px] sm:max-w-full border-[1px] border-white-100 timer-bg rounded-3xl p-6 relative overflow-hidden backdrop-blur-large">
          <div className="flex-1 items-center flex space-x-2">
            <div className="flex flex-col items-center z-[1] space-y-3">
              <div className="flex items-center justify-center rounded-xl h-[58px] w-[58px] backdrop-blur-small bg-white">
                <span className="text-xl font-semibold text-[#348A73]">{days}</span>
              </div>
              <span className="text-sm text-white leading-[9px]">Days</span>
            </div>
            <div className="flex flex-col items-center z-[1] space-y-3">
              <div className="flex items-center justify-center bg-white rounded-xl h-[58px] w-[58px] backdrop-blur-small">
                <span className="text-xl font-semibold text-[#348A73]">{hours}</span>
              </div>
              <span className="text-sm text-white leading-[9px]">Hours</span>
            </div>
            <div className="flex flex-col items-center z-[1] space-y-3">
              <div className="flex items-center justify-center bg-white rounded-xl h-[58px] w-[58px] backdrop-blur-small">
                <span className="text-xl font-semibold text-[#348A73]">{minutes}</span>
              </div>
              <span className="text-sm text-white leading-[9px]">Min</span>
            </div>
            <div className="flex flex-col items-center z-[1] space-y-3">
              <div className="flex items-center justify-center bg-white rounded-xl h-[58px] w-[58px] backdrop-blur-small">
                <span className="text-xl font-semibold text-[#348A73]">{seconds}</span>
              </div>
              <span className="text-sm text-white leading-[9px]">Sec</span>
            </div>
          </div>
          <div className="flex flex-col space-y-3 z-[1] w-full">
            <span className="text-white poppins text-lg font-semibold">Qornex USDT</span>
            <span className="text-sm text-white-500 font-light max-w-[223px]">
              Matrix program for affiliate marketing with rewards in USDT and FRGX without level expiration dates.
            </span>
          </div>
          <img className="absolute top-0 left-0" src="/images/instruments/timer/lines.png" />
          <img className="absolute bottom-0 right-0 max-w-[258px] " src="/images/instruments/timer/usdtPic.png" />
        </div>
      );
    }
  };
  return <Countdown date={fromUnixTime(startUsdtMatrixTime)} renderer={renderer} onComplete={() => setIsComplete(true)} />;
};
