import React from 'react';

export const Farming = () => {
  return (
    <div className="flex items-center justify-between pt-[200px] w-full max-w-[1115px] sm:pt-20 sm:px-4 sm:flex-col-reverse">
      <div className="flex flex-col space-y-8 max-w-[584px] sm:space-y-6 sm:max-w-full">
        <span className="text-[54px] text-white poppins font-semibold text-left sm:text-h2">
          Qornex <span className="about-title">Auto Farming</span> of FRGX token on <br className="sm:hidden" /> BNB
          Chain
        </span>
        <span className="text-white-500 leading-8 font-light">
          With each activated level, you start farming FRGX automatically. 8% of the level cost is sent to the shared
          staking pool and begins to farm your income.
        </span>
        <div className="flex flex-col space-y-1">
          <div className="flex items-center space-x-3">
            <div className="w-3 h-3 rounded-full bg-[#DDA8FD]" />
            <span className="leading-8 text-white">
              The deposit is non-refundable; participants will only receive the interest.
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <div className="w-3 h-3 rounded-full bg-[#DDA8FD]" />
            <span className="leading-8 text-white">The maximum return on each deposit is 300%.</span>
          </div>
        </div>
      </div>
      <div className="relative">
        <img className="sm:hidden" src="/images/about/farming/token.png" />
        <img className="hidden sm:flex" src="/images/about/farming/tokenMob.png" />
        <img className="absolute top-0 sm:left-0 sm:top-[-100px]" src="/images/about/farming/shadow.png" />
      </div>
    </div>
  );
};
