import React from 'react';

export const BuyFrgxBanner = () => {
  const styleBg = {
    backgroundImage: `url(/images/dashboard/buyFRGXbanner.png)`,
    backgroundSize: 'cover',
  };

  return (
    <a
      style={styleBg}
      target="_blank"
      href="https://pancakeswap.finance/swap?chain=bsc&inputCurrency=BNB&outputCurrency=0xC703dA39aE3B9Db67C207c7Bad8100E1AfdC0F9c"
      className="relative flex-shrink-0 h-[115px] w-full flex staking-banner-bg rounded-3xl sm:max-w-full "
      rel="noreferrer"
    >
      <span className="absolute top-1/2 -translate-y-1/2 right-6 text-white-900 poppins text-xl font-semibold">
        Buy $FRGX
      </span>
    </a>
  );
};
