import React from 'react';

export const Partners = ({ count }) => {
  return (
    <div className="flex items-center space-x-3 bg-white-30 shadow-item sm:flex-row p-3 rounded-2xl sm:w-full ">
      <img className="shrink-0 w-10 h-10" src="/images/dashboard/partnersBlueIcon.svg" />
      <div className="flex flex-col">
        <span className="text-sm font-medium poppins text-white">Your partners</span>
        <span className="text-sm poppins text-white-500 font-light">{count}</span>
      </div>
    </div>
  );
};
