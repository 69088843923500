import React, { useEffect, useMemo, useState } from 'react';
import { PreviewMode } from './Components/PreviewMode';
import { LoginOrRegister } from './Components/LoginOrRegister';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/userSlice/selectors';
import { useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { useLazyQuery } from '@apollo/client';
import { updateUser } from '../../store/userSlice';
import { updateMatrixB } from '../../store/matrixBSlice';
import { GET_USER_DATA, GET_MATRIX_DATA } from '../../helpers/graphRequests';
import { destroyCookie } from 'nookies';
import { UserRepository } from '../../connectors/repositories/user';
import { MoreAboutQornex } from './Components/MoreAboutQornex';
import { useCheckUpline } from '../../helpers/hooks/useCheckUpline';

export const CheckConnect = ({ choosedMode, setChoosedMode, previewUserId = null }) => {
  const [initStep, setInitStep] = useState(0);
  const { account } = useWeb3React();
  const [currentUserId, setCurrentUserId] = useState(undefined);

  const {
    isValidUpline: isValidPreviewId,
    loadingCheckUpline: loadingCheckPreviewId,
    checkUpline: checkPreviewId,
    isDoneChecked,
    uplineQraphInfo: userQraphInfo,
  } = useCheckUpline();

  useEffect(() => {
    if (!!previewUserId) {
      checkPreviewId({ value: previewUserId });
    }
  }, [previewUserId]);

  useEffect(() => {
    if (userQraphInfo?.id) {
      setCurrentUserId(userQraphInfo?.id);
    }
  }, [userQraphInfo]);

  const dispatch = useDispatch();
  const currentUser = useSelector(getUser);
  const [callRequest, props] = useLazyQuery(GET_USER_DATA, {
    variables: { user: null },
    fetchPolicy: 'cache-and-network',
  });
  const [callRequestMatrix, propsMatrix] = useLazyQuery(GET_MATRIX_DATA, {
    variables: { user: null },
    fetchPolicy: 'cache-and-network',
  });
  const { loading, data, called } = props ?? {};
  const { loadingMatrix, dataMatrix, calledMatrix } = propsMatrix ?? {};

  const setUserInfo = async () => {
    try {
      await UserRepository.getProfileWithoutToken(currentUserId).then((result) => {
        localStorage.setItem('avatar', result?.imageUrl);
        localStorage.setItem('nickname', result?.nickname);
      });
    } catch (e) {}
  };

  useEffect(() => {
    if (
      localStorage.getItem('account') !== 'undefined' ||
      (currentUserId !== undefined && localStorage.getItem('account') !== currentUserId)
    ) {
      destroyCookie(null, 'apiToken');
      localStorage.setItem('account', '');
      localStorage.setItem('avatar', '');
      localStorage.setItem('nickname', '');
      setUserInfo();
    }
    if (localStorage.getItem('nickname') === null && localStorage.getItem('avatar') === null) {
      setUserInfo();
    }

    localStorage.setItem('account', currentUserId);
  }, [currentUserId]);

  const initUserInfo = async (userAddress) => {
    try {
      await callRequest({ variables: { user: userAddress.toLowerCase() } }).then((result) => {
        if (!!result?.data?.user?.id) {
          const { id, refNumber, referral, internalId, totalPartners, tokenToReceive } = result?.data?.user;
          dispatch(updateUser({ id, internalId, refNumber, referral, totalPartners, tokenToReceive }));
        } else {
          dispatch(updateUser({ id: '' }));
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const initMatrixInfo = async (currentUserId) => {
    try {
      await callRequestMatrix({ variables: { user: currentUserId.toLowerCase() } }).then((result) => {
        if (!!result?.data?.user && !!result?.data?.user?.levels) {
          dispatch(
            updateMatrixB({
              loading: result?.loading,
              called: result?.called,
              levels: result?.data?.user?.levels,
              levels2: result?.data?.user?.levels2,
            }),
          );
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (currentUser?.id && currentUserId) {
      initMatrixInfo(currentUserId);
    }
  }, [currentUser?.id, currentUserId]);

  useEffect(() => {
    if (!!currentUserId) {
      dispatch(updateUser({ id: '' }));
      initUserInfo(currentUserId);
    }
  }, [currentUserId]);

  const styleBg = useMemo(() => {
    if (account && !!data && !data?.user?.id) {
      return {
        backgroundImage: 'url(/images/checkConnect/register.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      };
    }
    return {
      backgroundImage: 'url(/images/checkConnect/connectWallet.png)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    };
  });

  useEffect(() => {
    if (previewUserId) {
      setChoosedMode('PreviewMode');
    }
  }, [previewUserId]);

  const isNeedToRegister = account && !data?.user?.id && !loading && called;

  const renderContent = useMemo(() => {
    switch (choosedMode) {
      case 'PreviewMode':
        return (
          <PreviewMode
            setCurrentUserId={setCurrentUserId}
            previewUserId={previewUserId}
            setChoosedMode={setChoosedMode}
          />
        );
      case 'LoginOrRegister':
        return (
          <LoginOrRegister
            isNeedToRegister={isNeedToRegister}
            data={data}
            loading={loading}
            called={called}
            currentUserId={currentUserId}
            setCurrentUserId={setCurrentUserId}
          />
        );
      default:
        return (
          <div className="flex sm:flex-col items-center justify-center fixed top-0 left-0 right-0 bottom-0 h-screen bg-[#0B0B0B] z-[9998] overflow-hidden">
            {initStep === 0 ? (
              <>
                <div style={styleBg} className="w-[41%] h-full sm:hidden" />
                <div className="relative z-[999] w-[59%] sm:w-full h-full flex flex-col items-center justify-center p-2.5 overflow-hidden">
                  <div className="flex flex-col justify-center sm:justify-center w-full max-w-[460px] h-full text-white">
                    <div className="flex flex-col mb-[56px] space-y-10 sm:pt-10">
                      <h1 className="text-[40px] leading-[48px] font-semibold">Welcome to Qornex</h1>
                      <div className="flex flex-col space-y-2.5 items-center justify-center">
                        <button
                          onClick={() => setChoosedMode('LoginOrRegister')}
                          className="rounded-[16px] bg-primary-500 text-primary-950 py-5 w-full"
                        >
                          Login or Register
                        </button>
                        <button onClick={() => setChoosedMode('PreviewMode')} className="">
                          use Preview Mode
                        </button>
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={() => setInitStep(1)}
                    className="z-10 absolute bottom-5 left-1/2 -translate-x-1/2 text-white-500 rounded-full border border-white-500 px-3 py-1.5 hover:text-white hover:border-white"
                  >
                    More about Qornex
                  </button>
                </div>
                <img className="absolute bottom-0 right-0 z-[1]" src="/images/checkConnect/shadow.png" />
              </>
            ) : (
              <MoreAboutQornex setInitStep={setInitStep} />
            )}
          </div>
        );
    }
  }, [initStep, choosedMode, previewUserId, data, loading, called, isNeedToRegister, currentUserId]);

  if (currentUser?.id) {
    return null;
  }

  return renderContent;
};
