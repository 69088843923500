import React from 'react';
import { Header } from 'components';
import { menuList } from '../../helpers/menu';
import { Link } from 'react-router-dom';
import { useDeactivationWallet } from 'helpers/hooks/useDeactivationWallet';
import { clearUser } from '../../store/userSlice';
import { useDispatch } from 'react-redux';
import { PreviewMode } from '../PreviewMode';
import { useSearchParams } from 'react-router-dom';

export const BurgerMenu = ({ setChoosedMode, isActive, setIsActive }) => {
  const dispatch = useDispatch();
  const { deactivationWallet } = useDeactivationWallet();
  const [searchParams, setSearchParams] = useSearchParams();

  const logout = () => {
    deactivationWallet();
    dispatch(clearUser());
    setIsActive(false);
    setChoosedMode(null);
    searchParams.delete('previewUser');
    setSearchParams(searchParams);
  };
  return (
    <div
      className={`hidden sm:flex sm:py-[72px] transition-all duration-700 justify-start items-start bg-[#0B0B0B] fixed top-0 left-0 z-20 inset-0 h-screen w-screen z-[999] flex-col`}
    >
      <Header />
      <div className="flex flex-col justify-between h-full px-4 pb-[112px] w-full ">
        <div className="flex flex-col items-start space-y-3.5 w-full">
          {menuList?.map((item, itemIndex) => {
            return (
              <Link
                onClick={() => setIsActive(false)}
                className="w-full"
                to={item?.href + location.search}
                key={itemIndex}
              >
                <h2 className="text-3xl text-white poppins font-medium">{item?.title}</h2>
              </Link>
            );
          })}
          <button onClick={logout} className="text-3xl text-white poppins font-medium">
            Log out
          </button>
        </div>
        {/* <div className="flex flex-col items-center w-full space-y-6 text-4xl text-white">
          <span className="text-white poppins font-medium text-lg leading-[20px]">Join us</span>
          <div className="flex justify-between w-full">
            {socialsList?.map((item, itemIndex) => {
              return (
                <a href="/" className="">
                  <img src={`/icons/socials/${item?.icon}.svg`} />
                </a>
              );
            })}
          </div>
        </div> */}
      </div>
    </div>
  );
};
