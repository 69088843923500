import React from 'react';
import { PROGRAM_EXPIRED, PROGRAM_NAMES, PROGRAM_CURRENCY, MATRIX_USDT_СOMMISIONS } from '../../../helpers/constants';
import { Timer } from '../../../features/program/ActiveLvl/components/Timer';
import { MAIN_BG_COLOR, MAIN_TEXT_COLOR } from '../../../helpers/program';

export const TableItem = ({ program, activeLvlInfo, lvl, price, isActive, isReadyToActivate }) => {
  const isBaseProgram = program === PROGRAM_NAMES.MATRIX_B;
  return (
    <div
      className={`flex items-center justify-between pl-4 pr-2 py-4 w-full rounded-3xl ${
        isActive && 'bg-white-10 border-[1px] border-white-100'
      }  ${isReadyToActivate && 'bg-white-100'} sm:flex-col sm:space-y-3`}
      key={lvl}
    >
      <div className="flex space-x-4">
        <span className="text-lg poppins text-white">Level {lvl}</span>
        {activeLvlInfo && isBaseProgram && (
          <div className={`flex space-x-2`}>
            <span className="text-white-500">
              <Timer time={Number(activeLvlInfo?.expiredAt)} />
            </span>
          </div>
        )}
      </div>
      {isReadyToActivate && (
        <button className={`w-[215px] py-2 flex items-center justify-center rounded-xl`}>
          <span className={`${MAIN_TEXT_COLOR?.[program]} text-sm font-semibold`}>Ready to activate</span>
        </button>
      )}

      <div className="flex items-center space-x-2.5 sm:py-2">
        {isBaseProgram && (
          <>
            <span className="poppins text-white">1X</span>
            <div className="flex min-w-[70px] sm:min-w-0 items-center justify-center py-2 px-3 bg-white-70 shadow-item backdrop-blur-large rounded-xl">
              <span className="text-white-500 text-xs font-medium">
                {PROGRAM_EXPIRED?.[PROGRAM_NAMES.MATRIX_B]?.[lvl]} days
              </span>
            </div>
          </>
        )}
        <div className={`flex min-w-[95px] sm:min-w-0 items-center justify-center py-2 px-3 ${MAIN_BG_COLOR?.[program]} shadow-item backdrop-blur-large rounded-xl`}>
          <span className="text-black text-xs font-medium">
            {price + MATRIX_USDT_СOMMISIONS?.[lvl]} {PROGRAM_CURRENCY[program]}
          </span>
        </div>
      </div>
    </div>
  );
};
