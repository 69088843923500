import { useState } from 'react';
import { CHECK_UPLINE_ADDRESS, CHECK_UPLINE_REFNUMBER, CHECK_UPLINE_INTERNAL_ID } from '../graphRequests';
import { useLazyQuery } from '@apollo/client';
import { getAddress } from '../format';

export const useCheckUpline = () => {
  const [uplineQraphInfo, setUplineGraphInfo] = useState(null);
  const [isValidUpline, setIsValidUpline] = useState(false);
  const [loadingCheckUpline, setLoadingCheckUpline] = useState(false);
  const [isDoneChecked, setIsDoneChecked] = useState(false);

  const [callUplineAddress, propsAddress] = useLazyQuery(CHECK_UPLINE_ADDRESS, {
    variables: { user: null },
    fetchPolicy: 'cache-and-network',
  });

  const [callUplineID, propsID] = useLazyQuery(CHECK_UPLINE_INTERNAL_ID, {
    variables: { user: null },
    fetchPolicy: 'cache-and-network',
  });

  const [callUplineNumber, propsNumber] = useLazyQuery(CHECK_UPLINE_REFNUMBER, {
    variables: { user: null },
    fetchPolicy: 'cache-and-network',
  });

  const { loadingAddress, dataAddress, calledAddress } = propsAddress ?? {};
  const { loadingNumber, dataNumber, calledNumber } = propsNumber ?? {};
  const { loadingID, dataID, calledID } = propsID ?? {};

  const checkUpline = async (uplineData) => {
    setIsDoneChecked(false);
    setLoadingCheckUpline(true);
    if (uplineData !== 0) {
      if (!!getAddress(uplineData?.value)) {
        try {
          const result = await callUplineAddress({ variables: { user: uplineData?.value?.toLocaleLowerCase() } });
          if (!!result?.data?.users[0]?.id) {
            setIsValidUpline(true);
            setLoadingCheckUpline(false);
            setUplineGraphInfo(result?.data?.users[0]);
          } else {
            setIsValidUpline(false);
            setLoadingCheckUpline(false);
          }
        } catch (e) {
          console.log(e);
          setLoadingCheckUpline(false);
        }
      } else {
        if (uplineData?.type === 'ref') {
          try {
            const result = await callUplineNumber({ variables: { refNumber: uplineData?.value } });
            if (!!result?.data?.users[0]?.id) {
              setIsValidUpline(true);
              setLoadingCheckUpline(false);
              setUplineGraphInfo(result?.data?.users[0]);
            } else {
              setIsValidUpline(false);
              setLoadingCheckUpline(false);
            }
          } catch (e) {
            console.log(e);
            setLoadingCheckUpline(false);
          }
        } else {
          try {
            const result = await callUplineID({ variables: { internalId: Number(uplineData?.value) } });
            if (!!result?.data?.users[0]?.id) {
              setIsValidUpline(true);
              setLoadingCheckUpline(false);
              setUplineGraphInfo(result?.data?.users[0]);
            } else {
              setIsValidUpline(false);
              setLoadingCheckUpline(false);
            }
          } catch (e) {
            console.log(e);
            setLoadingCheckUpline(false);
          }
        }
      }
    } else {
      setIsValidUpline(true);
      setLoadingCheckUpline(false);
    }
    setIsDoneChecked(true);
  };

  return {
    uplineQraphInfo,
    checkUpline,
    isValidUpline,
    loadingCheckUpline,
    isDoneChecked,
  };
};
