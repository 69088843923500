import React from 'react';
import { copy } from '../../../../../helpers/copy';

export const Reflink = ({ internalId = 1, partners24 = 0 }) => {
  const refLink = window.location.host + '/?refid=' + internalId;
  return (
    <div
      className={`flex flex-col justify-between my-4 ${
        !!partners24 ? 'h-[106px]' : 'h-[64px]'
      } bg-white-30 rounded-2xl shadow-item`}
    >
      <div className="flex bg-white-30 rounded-2xl h-[64px] p-3 space-x-3">
        <button
          onClick={() => copy(refLink)}
          className="w-10 h-10 rounded-xl bg-white-50 flex items-cener justify-center p-2 flex-shrink-0"
        >
          <img src="/icons/dashboard/userInfo/refLinkIcon.svg" />
        </button>
        <div className="flex flex-col overflow-hidden">
          <span className="text-base font-medium text-white poppins">Referral link</span>
          <span className="scroll-hidden overflow-auto text-sm text-white-500 font-light whitespace-nowrap">
            {refLink}
          </span>
        </div>
      </div>
      {!!partners24 && <span className="p-3 text-white-500 font-light text-sm">+ {partners24} partners in 24 hrs</span>}
    </div>
  );
};
