import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useGetContract } from '../../../helpers/hooks/useGetContract';
import { CONTRACT_NAMES, ContractNames, PROGRAM_NAMES } from '../../../helpers/constants';
import { useApproveWithChecks } from '../../../helpers/hooks/useApproveWithChecks';
import { useWeb3React } from '@web3-react/core';
import { checkApprove } from '../../../helpers/checks';
import { STATUSES_ENUM } from '../../../helpers/hooks/useChecks';
import { toWei } from '../../../helpers/numbers';
import clsx from 'clsx';
import { useCallTransaction } from '../../../helpers/hooks/useCallTransaction';
import { callNotification } from '../../../helpers/notification';
import { parseErrorToUserReadableMessage } from '../../../helpers/format';
import { TransactionStatuses } from '../../TransactionStatuses';

export const Days = ({
  amount,
  stakingState,
  isLoadingStake,
  statuses,
  onClickStake,
  callChecks,
  setCurrentDay,
  currentDay,
  stakingDays,
}) => {
  const web3Props = useWeb3React();
  const { account } = web3Props;

  const isLoadingAny = Object.values(statuses).some((status) => status === STATUSES_ENUM.WAIT);

  useEffect(() => {
    const dayIndex = stakingDays.findIndex((day) => !day.disabled);

    setCurrentDay(dayIndex);
  }, [stakingDays]);

  useEffect(() => {
    if (account) {
      callChecks();
    }
  }, [account]);

  const renderButtonText = useMemo(() => {
    if (isLoadingAny || isLoadingStake) {
      return <span className="text-white poppins font-medium leading-5">Loading...</span>;
    }

    if (statuses.checkApprove === STATUSES_ENUM.ERROR) {
      return <span className="text-white poppins font-medium leading-5">Approve</span>;
    }

    return <span className="text-white poppins font-medium leading-5">Stake</span>;
  }, [statuses, isLoadingAny, isLoadingStake]);

  return (
    <div className="flex flex-col space-y-6 w-full">
      <div className="flex flex-col space-y-2 w-full ">
        <span className="text-xs text-white-500 font-light">
          <span className="text-white">Days</span>
        </span>
        <div className="flex justify-between w-full ">
          {stakingDays?.map((item, itemIndex) => {
            const isActive = itemIndex === currentDay;
            const totalBonus = (parseFloat(stakingState?.apy) * 100).toFixed(0) * stakingState?.bonuses[itemIndex];
            return (
              <button
                onClick={() => {
                  setCurrentDay(itemIndex);
                }}
                className={clsx('relative rounded-xl min-w-[86px] py-2 sm:min-w-[62px]', {
                  'bg-[#EBFF29] text-black': isActive && !item.disabled,
                  'bg-white-70 text-white-500': !isActive,
                  'pointer-events-none': item.disabled,
                })}
                key={itemIndex}
              >
                {!!totalBonus && (
                  <span className="flex items-center justify-center absolute -top-2.5 -right-2.5 px-1.5 py-0.5 text-black text-[10px] bg-[#EBFF29] rounded-full">
                    +{totalBonus.toFixed(2)}%
                  </span>
                )}
                <span className="text-base">{item?.days}</span>
              </button>
            );
          })}
        </div>
      </div>
      <button
        onClick={onClickStake}
        disabled={isLoadingAny || !amount || isLoadingStake}
        className="flex items-center justify-center w-full bg-secondary-500 rounded-2xl py-3.5"
      >
        {renderButtonText}
      </button>
    </div>
  );
};
