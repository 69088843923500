import React, { useMemo, useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import loadingQornexAnimation from '../../../animations/loadingQornex.json';
import { useCheckUpline } from '../../../helpers/hooks/useCheckUpline';
import { Input } from '../../Input';
import { useSearchParams } from 'react-router-dom';

export const PreviewMode = ({ setCurrentUserId, previewUserId = null, setChoosedMode }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [checkedPreviewId, setCheckedPreviewId] = useState(previewUserId);
  const {
    isValidUpline: isValidPreviewId,
    loadingCheckUpline: loadingCheckPreviewId,
    checkUpline: checkPreviewId,
    isDoneChecked,
    uplineQraphInfo: previewQraphInfo,
  } = useCheckUpline();

  useEffect(() => {
    if (!previewUserId) {
      setCurrentUserId(undefined);
    }
  }, [previewUserId]);

  useEffect(() => {
    if (checkedPreviewId) {
      checkPreviewId({ value: checkedPreviewId });
    }
  }, [checkedPreviewId]);

  const openPreviewId = () => {
    if (isValidPreviewId) {
      setCurrentUserId(previewQraphInfo?.id);
      setSearchParams((params) => {
        params.set('previewUser', checkedPreviewId);
        return params;
      });
    }
  };

  const styleBg = useMemo(() => {
    return {
      backgroundImage: 'url(/images/checkConnect/connectWallet.png)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    };
  });

  return (
    <div className="flex sm:flex-col items-center justify-center fixed top-0 left-0 right-0 bottom-0 h-screen bg-[#0B0B0B] z-[9998] overflow-hidden">
      <div style={styleBg} className="w-[41%] h-full sm:hidden" />
      <div className="z-[999] w-[59%] sm:w-full h-full flex flex-col items-center justify-center p-2.5 overflow-hidden">
        {!!previewUserId ? (
          <Lottie animationData={loadingQornexAnimation} loop={true} />
        ) : (
          <div className="flex flex-col justify-center sm:justify-center w-full max-w-[460px] h-full text-white">
            <div className="flex flex-col mb-[56px] space-y-10 sm:pt-10">
              <h1 className="text-[40px] leading-[48px] font-semibold">Enter preview address</h1>
              <div className="flex flex-col space-y-2.5 items-center justify-center">
                <div className="relative flex items-center w-full">
                  <Input
                    placeholder="Enter upline address"
                    value={checkedPreviewId}
                    onChange={(e) => setCheckedPreviewId(e.target.value)}
                  />
                  <div className="absolute top-[50%] -translate-y-1/2 left-1">
                    <img
                      className={`w-5 h-5 ${loadingCheckPreviewId && !!checkedPreviewId ? 'animate-spin' : ''}`}
                      src={
                        !isDoneChecked
                          ? '/icons/loading_circle.svg'
                          : isValidPreviewId
                          ? '/icons/success_arrow.svg'
                          : '/icons/error_arrow.svg'
                      }
                    />
                  </div>
                </div>
                <button
                  disabled={!isDoneChecked}
                  onClick={() => openPreviewId()}
                  className="rounded-[16px] bg-primary-500 text-primary-950 py-5 w-full"
                >
                  Go to dashboard
                </button>
                <button
                  onClick={() => {
                    setCheckedPreviewId(null);
                    setChoosedMode('Base');
                  }}
                  className=""
                >
                  return to login
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <img className="absolute bottom-0 right-0 z-[1]" src="/images/checkConnect/shadow.png" />
    </div>
  );
};
