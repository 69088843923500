import React from 'react';
import { useDeactivationWallet } from 'helpers/hooks/useDeactivationWallet';
import { MenuButtons } from './MenuButtons';
import { useDispatch } from 'react-redux';
import { clearUser } from '../../store/userSlice';
import { useSearchParams } from 'react-router-dom';

export const LeftBar = ({ setChoosedMode }) => {
  const dispatch = useDispatch();
  const shadowBg = { backgroundImage: `url('/images/leftBar/shadow.png')`, backgroundSize: 'cover' };

  const { deactivationWallet } = useDeactivationWallet();

  const [searchParams, setSearchParams] = useSearchParams();

  const logout = () => {
    deactivationWallet();
    dispatch(clearUser());
    setChoosedMode(null);
    searchParams.delete('previewUser');
    setSearchParams(searchParams);
  };

  return (
    <div className="flex flex-col justify-between items-end left_bar_size h-screen px-6 pt-10 pb-9 sm:hidden sticky top-0 z-[49] ">
      <div className="z-[10] flex flex-col items-start space-y-11 w-[180px] ">
        <img className="h-[40px] max-w-full" src="/images/leftBar/logo.png" />
        <MenuButtons />
      </div>
      <button onClick={logout} className="z-[10] flex space-x-3 w-[180px]">
        <img src="/icons/leftBar/logOutIcon.svg" />
        <span className="text-white">Log out</span>
      </button>
    </div>
  );
};
