import React, { useEffect, useState, useMemo } from 'react';
import { BuyLevelButton } from '../../BuyLevelButton';
import { useCallTransaction } from '../../../helpers/hooks/useCallTransaction';
import { updateUser } from '../../../store/userSlice';
import { useLazyQuery } from '@apollo/client';
import { GET_USER_DATA, GET_MATRIX_DATA } from '../../../helpers/graphRequests';
import { useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { Input, TransactionStatuses } from 'components';
import { useCheckReflink } from '../../../helpers/hooks/useCheckReflink';
import { useCheckUpline } from '../../../helpers/hooks/useCheckUpline';
import { getAddress, shortenAddress } from '../../../helpers/format';
import { EXPLORER_URL } from '../../../helpers/constants';
import config from '../../../helpers/config';

export const Register = ({}) => {
  const [currency, setCurrency] = useState('usdt');
  const { onCallTransaction, transactionInfo } = useCallTransaction();
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const [upline, setUpline] = useState(0);
  const [openedUplineInput, setOpenedUplineInput] = useState(false);
  const [callRequest] = useLazyQuery(GET_USER_DATA, { variables: { user: null }, fetchPolicy: 'no-cache' });
  const { uplineData, checkReflinkId, checkReflink } = useCheckReflink();
  const { isValidUpline, loadingCheckUpline, checkUpline, uplineQraphInfo } = useCheckUpline();

  useEffect(() => {
    checkReflink();
    checkReflinkId();
  }, []);

  useEffect(() => {
    if (uplineData) {
      setUpline(uplineData);
    }
  }, [uplineData]);

  useEffect(() => {
    checkUpline(upline);
  }, [upline]);

  const checkAccountData = async () => {
    try {
      const result = await callRequest({ variables: { user: account.toLocaleLowerCase() } });
      if (!!result?.data?.user?.id) {
        const { id, refNumber, referral, partners } = result?.data?.user;
        dispatch(updateUser({ id, refNumber, referral, partners }));
      }
      location.reload()
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (transactionInfo?.isSuccess && account) {
      checkAccountData();
    }
  }, [transactionInfo, account]);

  const uplineInputRender = useMemo(() => {
    if (openedUplineInput) {
      return (
        <div className="relative flex items-center w-full">
          <Input
            placeholder="Enter upline ID or address"
            value={upline?.value}
            onChange={(e) => setUpline({ ...upline, value: e.target.value })}
          />
          <div className="absolute top-[50%] -translate-y-1/2 left-1">
            <img
              className={`w-5 h-5 ${loadingCheckUpline ? 'animate-spin' : ''}`}
              src={
                loadingCheckUpline
                  ? '/icons/loading_circle.svg'
                  : isValidUpline
                  ? '/icons/success_arrow.svg'
                  : '/icons/error_arrow.svg'
              }
            />
          </div>
          {isValidUpline && (
            <button
              onClick={() => setOpenedUplineInput(false)}
              className="rounded-[12px] flex items-center justify-center absolute right-2 top-1/2 -translate-y-1/2 w-10 h-10 bg-primary-500"
            >
              <img src="/icons/registration/rightBottom_arrow.svg" alt="" />
            </button>
          )}
        </div>
      );
    }

    return null;
  }, [openedUplineInput, upline, isValidUpline, loadingCheckUpline]);

  const renderCurrencyToggle = useMemo(() => {
    return (
      <div className="flex w-full h-[55px] flex-shrink-0 border border-white-100 rounded-[16px] p-2 !mt-0">
        <div
          onClick={() => setCurrency('usdt')}
          className={`${
            currency === 'usdt' ? 'bg-main-usdt hover:opacity-75' : 'bg-transparent text-white'
          } mr-1 cursor-pointer uppercase font-semibold rounded-[12px] h-full flex flex-1 items-center justify-center`}
        >
          usdt
        </div>
        <div
          onClick={() => setCurrency('bnb')}
          className={`${
            currency === 'bnb' ? 'bg-main-usdt hover:opacity-75' : 'bg-transparent text-white hover:bg-white-50'
          } mx-1 cursor-pointer uppercase font-semibold rounded-[12px] h-full flex flex-1 items-center justify-center`}
        >
          bnb
        </div>
        <div
          onClick={() => setCurrency('frgx')}
          className={`${
            currency === 'frgx' ? 'bg-main-usdt hover:opacity-75' : 'bg-transparent text-white hover:bg-white-50'
          } ml-1 cursor-pointer uppercase font-semibold rounded-[12px] h-full flex flex-1 items-center justify-center`}
        >
          frgx
        </div>
      </div>
    );
  }, [currency]);

  return (
    <div className="flex flex-col justify-center sm:justify-center w-full max-w-[460px] h-full text-white">
      {transactionInfo?.hash ? (
        <div className="flex flex-col items-center space-y-16">
          <TransactionStatuses transactionInfo={transactionInfo} />
          {!transactionInfo?.isSuccess ? (
            <a
              href={EXPLORER_URL + '/tx/' + transactionInfo?.hash}
              target="_blank"
              rel="noreferrer"
              className="flex items-center justify-center text-white-300 poppins font-medium rounded-[16px] bg-white-50 py-5 w-full max-w-[264px]"
            >
              Explore transaction
            </a>
          ) : (
            <button
              className="flex items-center justify-center text-white poppins font-medium rounded-[16px] bg-white-50 py-5 w-full max-w-[264px]"
              onClick={checkAccountData}
            >
              Login
            </button>
          )}
        </div>
      ) : (
        <>
          <div className="flex flex-col mb-[56px] space-y-2 sm:pt-10">
            <h1 className="text-[40px] leading-[48px] font-semibold">Registration in Qornex</h1>
            <div className="flex flex-col">
              <div className="flex items-center space-x-1.5">
                {!openedUplineInput && (
                  <>
                    <div className="flex items-center flex-wrap text-white">
                      <span className="text-white-500 mr-1.5">
                        Your upline {getAddress(upline?.value) ? 'address' : 'ID'}
                      </span>
                      <span>
                        {Number(upline) === 0
                          ? 'hub'
                          : getAddress(upline?.value)
                          ? shortenAddress(upline?.value)
                          : upline?.value}
                      </span>
                    </div>
                    <button
                      onClick={() => setOpenedUplineInput((temp) => !temp)}
                      disabled={transactionInfo?.isSuccess || transactionInfo?.isWaiting}
                    >
                      <img className="h-5 w-5" src="/icons/registration/pensil.svg" alt="" />
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-3 w-full">
            {uplineInputRender}
            {renderCurrencyToggle}
            <BuyLevelButton
              isFirstBuy
              disabled={!isValidUpline || loadingCheckUpline}
              loading={loadingCheckUpline}
              onCallTransaction={onCallTransaction}
              text="Register"
              currency={currency}
              program={'usdt'}
              level={1}
              uplineData={{
                data: uplineQraphInfo?.id || 0,
                error: !isValidUpline,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};
