import React from 'react';
import { Link } from 'react-router-dom';

export const StakingBanner = () => {
  return (
    <Link
      to={'/staking' + location.search}
      className="w-full flex staking-banner-bg rounded-3xl h-full p-3 space-x-4 sm:max-w-full "
    >
      <img className="rounded-2xl h-[91px] w-[91px]" src="/images/dashboard/stakingBanner/percent.png" />
      <div className="flex flex-col justify-center space-y-2">
        <span className="text-sm text-white-500">Qornex Staking</span>
        <span className="text-base text-white font-medium sm:text-sm sm:font-light">
          Stake your FRGX and get rewards
        </span>
      </div>
    </Link>
  );
};
