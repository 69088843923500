import React from 'react';

export const Advantages = () => {
  const advantagesList = [
    {
      title: 'Farming',
      desc: 'Activate levels to farm FRGX automatically and get profits from a shared staking pool.',
      img: 'farming',
    },
    {
      title: 'Staking',
      desc: 'Stake any amount of FRGX for 30 days or more and receive rewards.',
      img: 'staking',
    },
    {
      title: 'Referral bonus',
      desc: 'Create and develop your own affiliate team to get referral bonus.',
      img: 'referral',
    },
  ];
  return (
    <div className="flex flex space-x-6 pt-[200px] sm:pt-20 sm:flex-col sm:space-x-0 sm:space-y-6 sm:w-full sm:px-4">
      {advantagesList.map((item, itemIndex) => {
        return (
          <div
            className="about-blocks-border p-[1px] rounded-[40px] min-h-[460px] sm:min-h-full sm:rounded-3xl sm:w-full"
            key={itemIndex}
          >
            <div className="flex flex-col justify-between bg-[#080909] h-full w-full rounded-[40px] relative overflow-hidden sm:rounded-3xl sm:w-full">
              <div className="flex flex-col space-y-3 pt-8 px-6 sm:pt-6">
                <h2 className="text-white leading-8 font-semibold sm:text-xl z-[1]">{item?.title}</h2>
                <span className="text-white-500 font-light leading-8 max-w-[300px] sm:max-w-full z-[1]">
                  {item?.desc}
                </span>
              </div>
              <div className="flex items-end justify-center w-full">
                <img className="sm:max-h-[300px] w-fit z-[1]" src={`/images/about/advantages/${item?.img}.png`} />
                <img className="w-full absolute bottom-0 z-[0]" src="/images/about/advantages/shadow.png" />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
