import React, { useState, useMemo } from 'react';
import { useGetContract } from '../../../helpers/hooks/useGetContract';
import { useCallTransaction } from '../../../helpers/hooks/useCallTransaction';
import { CONTRACT_NAMES } from '../../../helpers/constants';

export const ClaimInfo = ({ farmState, isLoadingFirst }) => {
  const [isLoadingClaim, setIsLoadingClaim] = useState(false);
  const { getContract } = useGetContract();
  const { onCallTransaction, transactionInfo } = useCallTransaction();

  const onClaim = async () => {
    if (!isLoadingClaim) {
      setIsLoadingClaim(true);
      try {
        const contract = await getContract(CONTRACT_NAMES.FARMING_POOL);

        const result = await contract.claim();

        onCallTransaction(result);
      } catch (e) {
        //
      }

      setIsLoadingClaim(false);
    }
  };

  const percents = useMemo(() => {
    return {
      limit: 300,
      current:
        farmState.historicalDep && farmState.historicalReceived
          ? (farmState.historicalReceived / (farmState.historicalDep * 3)) * 100
          : 0,
    };
  }, [farmState]);

  return (
    <div className="flex flex-col justify-between p-6 space-y-11 z-[3] sm:p-4 sm:w-full">
      <div className="flex items-center space-x-3">
        <img className="w-[37px] h-[37px]" src="/images/farming/claiming/frgxLogo.png" />
        <span className="text-white poppins text-lg font-medium">FRGX</span>
      </div>
      <div className="flex flex-col space-y-6">
        <div className="flex space-x-12 pb-5 sm:flex-col sm:space-x-0 sm:pb-0 sm:pt-[66px] sm:space-y-4">
          <div className="flex flex-col space-y-2">
            <div className="sm:flex sm:items-end sm:space-x-2 ">
              <span className="text-white leading-5">Available for claim</span>
              <img className="hidden sm:flex" src="/icons/claiming/questionIcon.svg" />
            </div>
            <span className="poppins text-[#D9E854] text-[40px] font-medium leading-[48px] sm:text-4xl">
              {isLoadingFirst ? '...' : farmState.available > farmState.limit ? farmState.limit : farmState.available}
            </span>
          </div>
          {/* <div className="hidden sm:flex border-[1px] border-white-100" /> */}
          {/* <div className="flex flex-col space-y-2">
            <div className="sm:flex sm:items-end sm:space-x-2 ">
              <span className="text-white leading-5">You Claimed</span>
              <img className="hidden sm:flex" src="/icons/claiming/questionIcon.svg" />
            </div>

            <span className="poppins text-white text-[40px] font-medium leading-[48px] sm:text-4xl">
              {isLoadingFirst ? '...' : farmState.historicalReceived}
            </span>
          </div> */}
        </div>
        <div className="flex flex-col space-y-3">
          <span className="text-white leading-5">Reward scale</span>
          <div className="relative overflow-hidden rounded-full max-w-[405px] w-full h-[26px] bg-white-70 text-xs font-medium sm:max-w-full">
            <span className="absolute left-3 text-left top-1/2 -translate-y-1/2">{percents.current.toFixed(0)}%</span>
            <div style={{ width: `${percents.current / 3}%` }} className="bg-primary-500 w-1/2 h-full" />
            <span
              className={`absolute right-3 text-right top-1/2 -translate-y-1/2 ${
                percents.current / 3 > 75 ? '' : 'text-white-500'
              } `}
            >
              {(300 - percents.current).toFixed(0)}%
            </span>
          </div>
          <span className="text-white-500 text-xs  !mt-2">
            You can earn up to 300% yield on farming. Here you see the progress.
          </span>
        </div>
      </div>
      <button
        onClick={onClaim}
        disabled={!farmState.available}
        className="flex items-center justify-center bg-[#D9E854] rounded-2xl z-[3] sm:w-full"
      >
        <span className="poppins text-[#151603] leading-5 py-3.5 font-medium">Claim</span>
      </button>
    </div>
  );
};
