import React, { useState } from 'react';
import { useGetContract } from './useGetContract';
import { DEFAULT_GAS_LIMIT, ContractNames, CONTRACT_NAMES, nameToAddress } from '../constants';
import { callNotification } from '../notification';
import config from '../config';

export const useChangeProfitCurrency = () => {
  const [isLoadingChangeCurrency, setIsLoadingChangeCurrency] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState(config.contractFrgx);
  const [installedCurrency, setInstalledCurrency] = useState(config.contractFrgx);
  const { getContract } = useGetContract();

  const changeCurrency = async () => {
    if (currentCurrency === installedCurrency) {
      callNotification({ message: 'This currency is already installed' });
    } else {
      if (!isLoadingChangeCurrency) {
        setIsLoadingChangeCurrency(true);
        const nextCurrencyAddress = currentCurrency;
        console.log(nextCurrencyAddress, 'HUIHUIJUHUHUH');

        try {
          const contract = await getContract(ContractNames.INTERNAL_ROUTER);

          let gas = null;
          try {
            gas = await contract.estimateGas.setTokenToReceive(nextCurrencyAddress);
          } catch (e) {
            console.log(e);
            return Promise.reject(e);
          }

          const result = await contract.setTokenToReceive(nextCurrencyAddress, {
            gasLimit: DEFAULT_GAS_LIMIT,
          });

          if (result) {
            setInstalledCurrency(nextCurrencyAddress);
            callNotification({ message: 'Successfully changed' });
            return result;
          }
        } catch (e) {
          console.log(e);
          return Promise.reject(e);
        } finally {
          setIsLoadingChangeCurrency(false);
        }
      }
    }
  };

  return {
    installedCurrency,
    currentCurrency,
    setCurrentCurrency,
    setInstalledCurrency,
    changeCurrency,
    isLoadingChangeCurrency,
  };
};
