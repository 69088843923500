import React, { useEffect, useState } from 'react';
import { LeftBar, Header, Footer } from 'components';
import { MobMenu } from '../../components';
import { PreviewMode } from '../../components/PreviewMode';
import { CheckConnect } from '../../components';
import { useSearchParams } from 'react-router-dom';

export const BaseLayout = ({ children }) => {
  const [choosedMode, setChoosedMode] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const previewUserId = searchParams.get('previewUser') || null;

  return (
    <>
      <div className="flex min-h-screen relative sm:overflow-x-hidden">
        <LeftBar setChoosedMode={setChoosedMode} />
        <div className="flex flex-col w-full z-[3] sm:pt-[82px] sm:pb-[80px]">
          {previewUserId ? <PreviewMode previewUserId={previewUserId} className="hidden sm:flex " /> : <Header />}
          <div className="flex flex-col py-10 px-12 z-[1] w-full h-full sm:px-4 sm:py-0 max-w-[1250px] sm:pb-[20px]">
            {children}
          </div>
          <Footer />
          <MobMenu setChoosedMode={setChoosedMode} />
        </div>
      </div>
      <CheckConnect setChoosedMode={setChoosedMode} choosedMode={choosedMode} previewUserId={previewUserId} />
    </>
  );
};
