import { useState } from 'react';
import { fromUnixTime, differenceInDays, isAfter } from 'date-fns';
import { LEVEL_STATUSES, LEVEL_STATUSES_COLOURS } from '../instrument';

export const useCheckMatrixStatus = () => {
  const [matrixStatus, setMatrixStatus] = useState([]);

  const checkMatrixStatus = (matrix) => {
    let result = matrix.levels.reduce((acc, item) => {
      if (Number(item.expiredAt) !== 0 && !isAfter(new Date(), fromUnixTime(item.expiredAt))) {
        const remDays = differenceInDays(fromUnixTime(item.expiredAt), new Date());
        const status = remDays > 6 ? LEVEL_STATUSES.GREEN : remDays > 4 ? LEVEL_STATUSES.ORANGE : LEVEL_STATUSES.RED;
        const colorStatus = LEVEL_STATUSES_COLOURS?.[status];
        return [
          ...acc,
          { levelNumber: item?.levelNumber, remainingDays: remDays, status: status, colorStatus: colorStatus },
        ];
      }
      return acc;
    }, []);

    result = result.sort((a, b) => (a.remainingDays > b.remainingDays ? 1 : -1));
    setMatrixStatus(result);
  };

  return {
    matrixStatus,
    checkMatrixStatus,
  };
};
