import React from 'react';

export const LevelInfo = ({
  isOpen,
  levelNumber,
  recyclesTotal,
  setCurrentCycle,
  currentCycle = 0,
  isFreezed = false,
}) => {
  const isAllowUpCycle = currentCycle < recyclesTotal;
  const isAllowDownCycle = currentCycle > 0;
  const isOnlyOneCycle = recyclesTotal === 0;

  const cycleUp = (e) => {
    e.stopPropagation();
    if (isAllowUpCycle) {
      setCurrentCycle(++currentCycle);
    }
  };

  const cycleDown = (e) => {
    e.stopPropagation();
    if (isAllowDownCycle) {
      setCurrentCycle(--currentCycle);
    }
  };

  return (
    <div className="z-[20] flex justify-end space-x-3 sm:flex-col sm:space-x-0 sm:space-y-3 sm:w-full">
      {isOpen && (
        <div
          onClick={(e) => e.stopPropagation()}
          className="flex-shrink-0 space-x-4 flex items-center justify-center bg-lightGray rounded-2xl space-x-2 px-3 py-3 shadow-wrapper min-w-[160px] sm:w-full"
        >
          {!isOnlyOneCycle && (
            <button
              disabled={!isAllowDownCycle}
              onClick={cycleDown}
              className="bg-white-70 w-6 h-6 flex items-center justify-center rounded-[8px] text-white-500 poppins font-semibold"
            >
              -
            </button>
          )}
          <div className="flex-1 flex items-center justify-center text-white-500 poppins font-semibold">
            Cycle {currentCycle + 1}
          </div>
          {!isOnlyOneCycle && (
            <button
              disabled={!isAllowUpCycle}
              onClick={cycleUp}
              className="bg-white-70 w-6 h-6 flex items-center justify-center rounded-[8px] text-white-500 poppins font-semibold"
            >
              +
            </button>
          )}
        </div>
      )}
      <div className="sm:hidden flex-shrink-0 flex items-center space-x-3">
        {isFreezed && (
          <div className="flex-shrink-0 bg-[#61B4E2] rounded-xl w-[48px] h-[48px] flex items-center justify-center shadow-wrapper">
            <img className="max-h-[60%]" src="/icons/instrument/freeze.svg" alt="" />
          </div>
        )}
        <div className="flex items-center justify-center bg-white-70 w-[48px] h-[48px] rounded-xl shadow-wrapper">
          <img className={`${isOpen ? 'rotate-[-90deg]' : ''}`} src="/icons/instrument/arrow.svg" />
        </div>
      </div>
    </div>
  );
};
