import React, { useEffect } from 'react';
import { useBalance } from '../../../../../helpers/hooks/useBalance';
import { useWeb3React } from '@web3-react/core';
import { ContractNames } from '../../../../../helpers/constants';

export const Balance = () => {
  const { account } = useWeb3React();
  const { balance, fetchBalance, isLoadingBalance } = useBalance();
  const { balance: balanceUsdt, fetchBalance: fetchUsdtBalance, isLoadingBalance: isLoadingUsdtBalance } = useBalance();

  useEffect(() => {
    if (account) {
      fetchBalance(ContractNames.FRGX);
      fetchUsdtBalance(ContractNames.USDT);
    }
  }, [account]);

  return (
    <>
      <div className="flex items-center space-x-3 bg-white-30 shadow-item sm:flex-row p-3 rounded-2xl sm:w-full ">
        <img className="shrink-0 w-10 h-10" src="/images/dashboard/profitInfo/frgxLogo.png" />
        <div className="flex flex-col">
          <span className="text-sm font-medium poppins text-white">Account balance</span>
          <span className="text-sm poppins text-white-500 font-light">{isLoadingBalance ? '-' : balance} FRGX</span>
        </div>
      </div>
      <div className="flex items-center space-x-3 bg-white-30 shadow-item sm:flex-row p-3 rounded-2xl sm:w-full ">
        <img className="shrink-0 w-10 h-10" src="/images/instruments/main/usdt.png" />
        <div className="flex flex-col">
          <span className="text-sm font-medium poppins text-white">Account balance</span>
          <span className="text-sm poppins text-white-500 font-light">{isLoadingUsdtBalance ? '-' : balanceUsdt} USDT</span>
        </div>
      </div>
    </>
  );
};
