import React, { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import { Profit, LevelInfo, PartnersStructure } from './components';
import { PROGRAM_MAX_LEVELS, PROGRAM_NAMES } from '../../../helpers/constants';
import { bigIntToInt } from '../../../helpers/numbers';

export const ActiveLvl = ({
  levelNumber = 1,
  activationTimes,
  totalReward = 0,
  matrixs,
  recyclesTotal = 0,
  reward24 = 0,
  isFreezed,
  missedPartners = 0,
  missedProfit = 0,
}) => {
  const isMaxLvl = levelNumber === PROGRAM_MAX_LEVELS?.[PROGRAM_NAMES?.MATRIX_USDT];
  const [currentCycle, setCurrentCycle] = useState(recyclesTotal);
  const [isOpen, setIsOpen] = useState(false);

  const missedInfo = { missedPartners: missedPartners, missedProfit: bigIntToInt(missedProfit) || 0 };

  return (
    <Accordion
      allowZeroExpanded
      allowMultipleExpanded
      className={`flex flex-col w-full sm:rounded-3xl pb-6  ${isMaxLvl ? '' : 'mb-[-100px] pb-[100px]'}`}
      onChange={() => setIsOpen((temp) => !temp)}
    >
      <AccordionItem className="level_shadow flex flex-col rounded-t-large sm:rounded-t-3xl pb-[120px] mb-[-130px]">
        <AccordionItemHeading className="w-full">
          <AccordionItemButton className="flex items-center justify-between w-full sm:flex-col sm:space-y-4 sm:items-start p-9 sm:p-0 sm:py-6 sm:px-4">
            <Profit
              levelNumber={levelNumber}
              totalReward={totalReward}
              reward24={reward24}
              isOpen={isOpen}
              isFreezed={isFreezed}
            />
            <LevelInfo
              isOpen={isOpen}
              setCurrentCycle={setCurrentCycle}
              currentCycle={currentCycle}
              levelNumber={levelNumber}
              recyclesTotal={recyclesTotal}
              isFreezed={isFreezed}
            />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel className="px-9 sm:px-4 pb-[34px]">
          {/* <div className="flex flex-col space-y-2.5 text-white">
            <div className="flex space-x-2.5">
              <span>Missed profit</span>
              <span className='font-bold'>{bigIntToInt(missedProfit) || 0} USDT</span>
            </div>
            <div className="flex space-x-2.5">
              <span>Missed partners</span>
              <span className='font-bold'>{missedPartners}</span>
            </div>
          </div> */}
          <PartnersStructure
            levelNumber={levelNumber}
            missedInfo={missedInfo}
            isFreezed={isFreezed}
            matrixs={matrixs[currentCycle]}
          />
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};
