import config from './config';
import { MAX_VALUE, ContractNames, CONTRACT_NAMES } from './constants';
import { toWei } from 'helpers/numbers';

export const nameToContractConfig = {
  [ContractNames.FRGX]: 'contractFrgx',
  [ContractNames.USDT]: 'contractUsdt',
  [ContractNames.MATRIX_B]: 'contractMatrixBase',
  [ContractNames.MATRIX_USDT]: 'paymentRouter',
  [ContractNames.PAYMENT_ROUTER]: 'paymentRouter',
  [ContractNames.ROUTER]: 'router',
  [ContractNames.STAKING_POOL]: 'contractStakingPool',
};

export const checkBalance = async ({
  account,
  getContract,
  provider,
  tokenName = ContractNames.FRGX,
  minPrice = 10,
}) => {
  try {
    if (!!account && !!provider) {
      if (tokenName === 'bnb') {
        const resultBnb = await provider.getBalance(account);
        const balanceBnb = parseInt(resultBnb) / 1e18;

        if (balanceBnb >= Number(minPrice)) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      } else {
        const contract = await getContract(tokenName);
        let balanceToken = parseInt(await contract.balanceOf(account)) / 1e18;

        if (balanceToken >= Number(minPrice)) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      }
    }
  } catch (e) {
    return Promise.reject();
  }
};

export const checkApprove = async ({ getContract, account, tokenName = CONTRACT_NAMES.FRGX, name = 'frgx', price }) => {
  const MIN_BALANCE = parseInt(MAX_VALUE, 16);

  try {
    const contractToken = await getContract(tokenName);

    const approveBalance = await contractToken.allowance(account, config[nameToContractConfig[name]]);

    const isAllowed =
      config.stand !== 'dev' && price
        ? parseInt(approveBalance) >= parseInt(toWei(price))
        : approveBalance >= MIN_BALANCE;

    if (isAllowed || tokenName === 'bnb') {
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  } catch (e) {
    return Promise.reject(e);
  }
};
