import React from 'react';
import { Link } from 'react-router-dom';

export const FarmingBanner = () => {
  return (
    <Link
      to={'/farming' + location.search}
      className="w-full flex staking-banner-bg rounded-3xl h-full row-span-2 col-span-2 p-3 space-x-4 sm:max-w-full "
    >
      <img className="rounded-2xl shrink-0 w-[91px] h-[91px]" src="/images/dashboard/farmingBanner/tokens.png" />
      <div className="flex flex-col justify-center space-y-2 w-full">
        <span className="text-sm text-white-500">Qornex Farming</span>
        <span className="text-base text-white font-medium sm:text-sm sm:font-light">
          Check out your personal farming pool and withdraw the interest
        </span>
      </div>
    </Link>
  );
};
