import React from 'react';

export const Footer = () => {
  const socialsList = [
    {
      icon: 'telegram',
      href: 'https://t.me/frgxfinance',
    },
    {
      icon: 'instagram',
      href: '',
    },
    {
      icon: 'medium',
      href: '',
    },
    {
      icon: 'gitbook',
      href: '',
    },
    {
      icon: 'discord',
      href: '',
    },
    {
      icon: 'twitter',
      href: '',
    },
  ];

  return (
    <div className="flex justify-between mt-[160px] mb-[30px] w-full max-w-[1115px] sm:mt-[80px] sm:flex-col sm:space-y-6 sm:items-center sm:px-4">
      <div className="flex flex-col space-y-7 sm:items-center">
        <span className="text-white text-lg poppins font-semibold">Follow us</span>
        <div className="flex space-x-6">
          {socialsList.map((item, itemIndex) => {
            return (
              <a target="blank" href={item?.href}>
                <img src={`/icons/about/footer/${item?.icon}.svg`} />
              </a>
            );
          })}
        </div>
      </div>
      <div className="hidden sm:flex border-[1px] border-white-50 w-full" />
      <div className="flex sm:flex-col w-full sm:space-y-8">
        <div className="flex justify-evenly sm:justify-between w-full">
          <div className="flex flex-col space-y-7">
            <span className="text-white text-lg poppins font-semibold">Website</span>
            <div className="flex flex-col space-y-5">
              <a target="blank" href="https://qornex.com/" className="text-white-500 ">
                qornex.com
              </a>
              <a target="blank" href="https://frgx.finance/" className="text-white-500 ">
                frgx.finance
              </a>
            </div>
          </div>
          <div className="flex flex-col space-y-7">
            <span className="text-white text-lg poppins font-semibold">Promo</span>
            <div className="flex flex-col space-y-5">
              <a target="blank" href="https://qornex.com/docs/overview.pdf" className="text-white-500 ">
                Overview
              </a>
              <a target="blank" href="https://qornex.com/docs/whitepaper.pdf" className="text-white-500 ">
                Whitepaper
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-7">
          <span className="text-white text-lg poppins font-semibold">Telegram</span>
          <div className="flex flex-col space-y-5">
            <a target="blank" href="https://t.me/frgxfinance" className="text-white-500 whitespace-nowrap ">
              Telegram Channel
            </a>
            <a target="blank" href="https://t.me/frgxgroup" className="text-white-500 whitespace-nowrap">
              Telegram Group
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
