import React from 'react';
import { Link } from 'react-router-dom';

export const PageLockedBanner = () => {
  return (
    <div className="w-full min-h-[540px] border-[1px] border-white-100 rounded-3xl overflow-hidden sm:relative sm:min-h-[484px] ">
      <div className="flex flex-col space-y-[24px] items-center justify-center w-full h-full overflow-hidden claiming-bg pl-6 sm:pl-auto sm:px-4">
        <img src="/icons/pageLocked.svg" />
        <span className="text-base text-white-300 sm:text-center">This page is locked in preview mode</span>
        <Link
          to={'/' + location.search}
          className="flex items-center justify-center w-full max-w-[351px] h-[48px] rounded-2xl bg-white-70 backdrop-blur-large shadow-item sm:max-w-full"
        >
          <span className="text-white poppins font-semibold ">Go to Dashboard</span>
        </Link>
      </div>
    </div>
  );
};
