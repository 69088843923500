import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useCheckPreviewMode = () => {
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [isDoneCheckedPreview, setIsDoneCheckedPreview] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const previewUserId = searchParams.get('previewUser') || false;

  useEffect(() => {
    if (!!searchParams && !!searchParams.get('previewUser')) {
      setIsPreviewMode(true);
    }
    setIsDoneCheckedPreview(true);
  }, [searchParams]);

  return {
    isPreviewMode,
    previewUserId,
    isDoneCheckedPreview,
  };
};
