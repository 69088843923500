import { useWeb3React } from '@web3-react/core';
import config from '../config';
import { Contract } from '@ethersproject/contracts';
import { ContractNames } from '../constants';

export const useGetContract = () => {
  const { account, provider } = useWeb3React();

  const types = {
    [ContractNames?.MATRIX_B]: [config.contractMatrixBase, config.contractMatrixBaseAbi],
    [ContractNames?.MATRIX_USDT]: [config.contractMatrixUsdt, config.contractMatrixUsdtAbi],
    [ContractNames?.FRGX]: [config.contractFrgx, config.contractFrgxAbi],
    [ContractNames?.USDT]: [config.contractUsdt, config.contractUsdtAbi],
    [ContractNames?.INTERNAL_ROUTER]: [config.internalRouter, config.contractInternalRouterAbi],
    [ContractNames?.PAYMENT_ROUTER]: [config.paymentRouter, config.contractPaymentRouterAbi],
    [ContractNames?.FARMING_POOL]: [config.contractFarmingPool, config.contractFarmingPoolAbi],
    [ContractNames?.STAKING_POOL]: [config.contractStakingPool, config.contractStakingPoolAbi],
    [ContractNames?.PANCAKE]: [config.contractPancake, config.contractPancakeAbi],
  };

  const getContract = (type, otherProvider) => {
    return new Promise(function (resolve, rejected) {
      const currentProvider = otherProvider ? otherProvider : provider;

      if (types[type] && currentProvider) {
        const contract = new Contract(
          ...types[type],
          otherProvider ? otherProvider : provider?.getSigner(account).connectUnchecked() || provider,
        );

        resolve(contract);
      } else {
        rejected('error init contract: ' + type);
      }
    });
  };

  return {
    getContract,
  };
};
