import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from '../../Modal';
import { useChangeProfitCurrency } from '../../../helpers/hooks/useChangeProfitCurrency';
import { ContractNames } from '../../../helpers/constants';
import { useCallTransaction } from '../../../helpers/hooks/useCallTransaction';
import { useWeb3React } from '@web3-react/core';
import { useSelector } from 'react-redux';
import { getUser } from '../../../store/userSlice/selectors';
import config from '../../../helpers/config';
import { updateUser } from '../../../store/userSlice';
import { useDispatch } from 'react-redux';
import { useCheckPreviewMode } from '../../../helpers/hooks/useCheckPreviewMode';
import { useGetContract } from '../../../helpers/hooks/useGetContract';
import { DEFAULT_GAS_LIMIT } from '../../../helpers/constants';

export const OutputSettingsModal = ({ openedModal, onClose }) => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const currentUser = useSelector(getUser);
  const { isPreviewMode } = useCheckPreviewMode();
  const { getContract } = useGetContract();
  const { onCallTransaction, transactionInfo } = useCallTransaction();
  const {
    installedCurrency,
    currentCurrency,
    setCurrentCurrency,
    setInstalledCurrency,
    changeCurrency,
    isLoadingChangeCurrency,
  } = useChangeProfitCurrency();

  console.log(currentCurrency, installedCurrency);

  useEffect(() => {
    if (account) {
      checkUserTokenReceive();
    }
  }, [account, installedCurrency]);

  const checkUserTokenReceive = async () => {
    try {
      const contract = await getContract(ContractNames.INTERNAL_ROUTER);

      let gas = null;
      try {
        gas = await contract.estimateGas.tokenToReceive(account);
      } catch (e) {
        console.log(e);
        return Promise.reject(e);
      }

      const result = await contract.tokenToReceive(account, {
        gasLimit: DEFAULT_GAS_LIMIT,
      });

      dispatch(updateUser({ ...currentUser, tokenToReceive: result?.toLocaleLowerCase() }));
      setInstalledCurrency(result?.toLocaleLowerCase());
      setCurrentCurrency(result?.toLocaleLowerCase());
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  }

  // useEffect(() => {
  //   if (currentUser?.tokenToReceive?.toLocaleLowerCase() === config?.contractUsdt?.toLocaleLowerCase()) {
  //     setInstalledCurrency(ContractNames.USDT);
  //     setCurrentCurrency(ContractNames.USDT);
  //   } else {
  //     setInstalledCurrency(ContractNames.FRGX);
  //     setCurrentCurrency(ContractNames.FRGX);
  //   }
  // }, [currentUser]);


  // useEffect(() => {
  //   if (currentUser?.tokenToReceive?.toLocaleLowerCase() === config?.contractUsdt?.toLocaleLowerCase()) {
  //     setInstalledCurrency(config.contractUsdt);
  //     setCurrentCurrency(config.contractUsdt);
  //   } else {
  //     setInstalledCurrency(config.contractFrgx);
  //     setCurrentCurrency(config.contractFrgx);
  //   }
  // }, [currentUser]);

  const buttonState = useMemo(() => {
    if (isPreviewMode) {
      return {
        text: 'Disabled in preview mode',
        isDisabled: true,
      };
    }
    if (!account) {
      return {
        text: 'Connect wallet to change output',
        isDisabled: true,
      };
    }
    if (isLoadingChangeCurrency) {
      return {
        text: 'Loading...',
        isDisabled: true,
      };
    } else {
      if (installedCurrency === currentCurrency) {
        return {
          text: 'Already installed',
          isDisabled: true,
        };
      } else {
        return {
          text: 'Save',
          isDisabled: false,
        };
      }
    }
  }, [installedCurrency, currentCurrency, isLoadingChangeCurrency, account, isPreviewMode]);

  const settingsList = useMemo(() => {
    if (currentCurrency === config.contractFrgx) {
      return [
        {
          address: config.contractFrgx,
          key: ContractNames?.FRGX,
          bg: 'bg-secondary-500',
        },
      ];
    } else {
      return [
        {
          address: config.contractUsdt,
          key: ContractNames?.USDT,
          bg: 'bg-main-usdt',
        },
      ];
    }
  }, [currentCurrency]);

  const setAnotherCurrency = (next) => {
    if (next === config.contractUsdt?.toLocaleLowerCase()) {
      setCurrentCurrency(config.contractFrgx?.toLocaleLowerCase());
    } else {
      setCurrentCurrency(config.contractUsdt?.toLocaleLowerCase());
    }
  };

  const changeCurrencyFunc = async () => {
    try {
      const result = await changeCurrency();

      if (result) {
        const temp = currentCurrency === config.contractFrgx ? config.contractFrgx?.toLocaleLowerCase() : config.contractUsdt?.toLocaleLowerCase();
        dispatch(updateUser({ ...currentUser, tokenToReceive: temp?.toLocaleLowerCase() }));
      }

      onCallTransaction(result);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal isOpened={openedModal} onClose={onClose} withoutClose={true}>
      <div className="flex flex-col justify-between w-[464px] h-[375px]  rounded-3xl bg-lightBlack backdrop-blur-bold p-8 border-[1px] border-white-100">
        <h2 className="text-white poppins font-semibold">Output Settings</h2>
        <div className="flex flex-col space-y-6">
          {settingsList?.map((item, itemIndex) => {
            const isActive = item?.address === currentCurrency;
            return (
              <div
                className="flex items-center output-settings-item-bg p-4 flex w-full justify-between rounded-3xl border-[1px] border-white-100"
                key={itemIndex}
              >
                <div className="flex space-x-6 items-center">
                  <img src={`/icons/modal/outputSettings/${item?.key}.svg`} />
                  <span className="text-white-500 font-medium">
                    Receive payments <br /> in <span className="uppercase">{item?.key}</span>
                  </span>
                </div>
                <button
                  disabled={isLoadingChangeCurrency}
                  onClick={() => setAnotherCurrency(item?.address?.toLocaleLowerCase())}
                  className={`flex items-center w-[60px] h-[30px] ${item?.bg} rounded-full p-0.5 relative`}
                >
                  <div
                    className={`absolute top-1/2 -translate-y-1/2 bg-white w-[26px] h-[26px] rounded-full ${
                      currentCurrency === config.contractFrgx?.toLocaleLowerCase() ? 'left-0.5' : 'right-0.5'
                    }`}
                  />
                </button>
              </div>
            );
          })}
        </div>
        <button
          disabled={buttonState.isDisabled}
          onClick={changeCurrencyFunc}
          className="bg-secondary-500 w-full h-[48px] rounded-2xl"
        >
          <span className="poppins text-white font-medium">{buttonState.text}</span>
        </button>
        <button onClick={() => onClose()} className="absolute right-8 rounded-full top-8 bg-white-70">
          <img className="" src="/icons/closeIcon.svg" />
        </button>
      </div>
    </Modal>
  );
};
