import React, { useEffect, useMemo, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { checkBalance, checkApprove } from 'helpers/checks';
import { STATUSES_ENUM } from 'helpers/hooks/useChecks';
import { useGetContract } from 'helpers/hooks/useGetContract';
import { CONTRACT_NAMES, PROGRAM_NAMES, PROGRAM_PRICES, SERVICE_FEE } from 'helpers/constants';
import { useApproveWithChecks } from 'helpers/hooks/useApproveWithChecks';
import { useRegistration } from '../../helpers/hooks/useRegistration';
import { useUpgradeLvl } from '../../helpers/hooks/useUpgradeLvl';
import { useApolloClient } from '@apollo/client';
import { PROGRAM_COMMISIONS } from '../../helpers/constants';
import config from '../../helpers/config';
import { useBnbPriceByAmount } from '../../helpers/hooks/useBnbPriceByAmount';
import { useFrgxPriceByAmount } from '../../helpers/hooks/useFrgxPriceByAmount';
import { Timer } from './Timer';

const getChecksCallbacks = (web3Props, getContract, level = 1, count = 1, program = 'base', currency, finalPrice, isFirstBuy) => {
  const contractType = program === 'base' ? CONTRACT_NAMES.MATRIX_B : CONTRACT_NAMES.PAYMENT_ROUTER;
  const funcProps = { getContract, ...web3Props, contractType };
  const currentProgram = program === 'base' ? PROGRAM_NAMES.MATRIX_B : PROGRAM_NAMES.MATRIX_USDT;
  
  const callbacks = currency === 'bnb' ? [
    {
      func: checkBalance,
      key: 'checkBalance',
      funcProps: {
        ...funcProps,
        tokenName: program === 'base' ? CONTRACT_NAMES.FRGX : currency === 'usdt' ? CONTRACT_NAMES.USDT : currency === 'bnb' ? 'bnb' : CONTRACT_NAMES.FRGX,
        minPrice: finalPrice,
      },
    },
  ] : [
    {
      func: checkBalance,
      key: 'checkBalance',
      funcProps: {
        ...funcProps,
        tokenName: program === 'base' ? CONTRACT_NAMES.FRGX : currency === 'usdt' ? CONTRACT_NAMES.USDT : currency === 'bnb' ? 'bnb' : CONTRACT_NAMES.FRGX,
        minPrice: finalPrice,
      },
    },
    {
      func: checkApprove,
      key: 'checkApprove',
      funcProps: {
        ...funcProps,
        tokenName: program === 'base' ? CONTRACT_NAMES.FRGX : currency === 'usdt' ? CONTRACT_NAMES.USDT : CONTRACT_NAMES.FRGX,
        name: contractType,
        price: PROGRAM_PRICES[currentProgram][level] * count + PROGRAM_COMMISIONS[currentProgram][level],
      },
    },
  ];

  return {
    name: currentProgram,
    getContract,
    callbacks: callbacks,
  };
};

export const BuyLevelButton = ({
  onCallTransaction,
  text = 'Activate',
  buttonType = 'base',
  count = 1,
  level = 1,
  uplineData = null,
  isFirstBuy = false,
  disabled = false,
  loading = false,
  program = 'base',
  className,
  currency = 'bnb',
}) => {
  const [isEndedTime, setIsEndedTime] = useState(false);
  const [finalPrice, setFinalPrice] = useState(0);
  const web3Props = useWeb3React();
  const client = useApolloClient();
  const { account, active, chainId } = web3Props;
  const { getContract } = useGetContract();
  const { registration, registrationOld } = useRegistration();
  const { getBnbPriceByAmount, bnbPrice, bnbPriceBase, bnbPriceByAmountIsLoading } = useBnbPriceByAmount();

  const { getFrgxPriceByAmount, frgxPrice, frgxPriceBase, frgxPriceByAmountIsLoading } = useFrgxPriceByAmount();
  const { upgradeLvl } = useUpgradeLvl();
  const currentToken = program === 'base' ? CONTRACT_NAMES.FRGX : CONTRACT_NAMES.PAYMENT_ROUTER;
  const { statuses, callChecks, approveInfo, callApprove, callApproveFRGX } = useApproveWithChecks(
    getChecksCallbacks(web3Props, getContract, level, count, program, currency, finalPrice, isFirstBuy),
    currentToken,
    currency,
  );
  const isSuccessAll = Object.values(statuses).every((status) => status === STATUSES_ENUM.SUCCESS);
  const isLoadingAny = Object.values(statuses).some((status) => status === STATUSES_ENUM.WAIT);

  console.log(finalPrice, currentToken, currency);

  useEffect(() => {
    finalPriceExchange();
  }, []);


  useEffect(() => {
    finalPriceExchange();
  }, [currency, isEndedTime]);

  const finalPriceExchange = async () => {
    if (program === 'base') {
      setFinalPrice(PROGRAM_PRICES?.[PROGRAM_NAMES.MATRIX_B]?.[level] * count);
    } else {
      if (currency === 'usdt') {
        setFinalPrice(
          (
            PROGRAM_PRICES?.[PROGRAM_NAMES.MATRIX_USDT]?.[level] + PROGRAM_COMMISIONS[PROGRAM_NAMES.MATRIX_USDT][level]
          ).toFixed(4),
        );
      } else if (currency === 'frgx') {
        const result = await getFrgxPriceByAmount(PROGRAM_PRICES[PROGRAM_NAMES.MATRIX_USDT][level] + PROGRAM_COMMISIONS[PROGRAM_NAMES.MATRIX_USDT][level], 5, PROGRAM_PRICES[PROGRAM_NAMES.MATRIX_USDT][level]);
        if (result) {
          setFinalPrice(result);
        }
      } else if (currency === 'bnb') {
        const result = await getBnbPriceByAmount(PROGRAM_PRICES[PROGRAM_NAMES.MATRIX_USDT][level] + PROGRAM_COMMISIONS[PROGRAM_NAMES.MATRIX_USDT][level], 1, PROGRAM_PRICES[PROGRAM_NAMES.MATRIX_USDT][level]);
        if (result) {
          setFinalPrice(result);
        }
      }
    }
  };

  useEffect(() => {
    callChecks();
  }, [active, account, chainId, count, finalPrice, isEndedTime]);

  const handleClickAction = async () => {
    try {
      if (isSuccessAll) {
        if (level === 1 && isFirstBuy) {
          let result = null;
          // if (config.stand === 'prod') {
          //   result = await registrationOld(uplineData);
          // } else {
            result = await registration(uplineData, currency, finalPrice);
          // }

          onCallTransaction(result);
        } else {
          const result = await upgradeLvl(program, level, count, account, currency, finalPrice);

          onCallTransaction(result);
        }
      }

      if (statuses.checkApprove === STATUSES_ENUM.ERROR) {
        if (currency === 'usdt') {
          callApprove();
        } else {
          callApproveFRGX();
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const renderButtonAction = useMemo(() => {
    if (!!uplineData && uplineData?.error) {
      return <span className="text-black poppins font-medium">Upline does not exist</span>;
    }
    if (isLoadingAny || loading || bnbPriceByAmountIsLoading || frgxPriceByAmountIsLoading) {
      return <span className="text-black poppins font-medium">Loading ...</span>;
    }

    if (isSuccessAll) {
      if (program === 'base') {
        return (
          <span className="text-black poppins font-medium">
            {text} for {finalPrice} FRGX
          </span>
        );
      } else {
        return (
          <span className="text-black poppins font-medium">
            {text} <span className="mx-[1px]">for</span> {finalPrice}
            <span className="uppercase ml-[1px]">{currency}</span>
          </span>
        );
      }
    }

    if (statuses.checkApprove === STATUSES_ENUM.ERROR || approveInfo?.isApproved) {
      return <span className="text-black poppins font-medium">Approve {program === 'base' ? 'FRGX' : currency === 'usdt' ? 'USDT' : 'FRGX'}</span>;
    }

    if (statuses.checkBalance === STATUSES_ENUM.ERROR) {
      return <span className="text-black poppins font-medium">Insufficient Balance</span>;
    }

    return null;
  }, [statuses, count, isSuccessAll, isLoadingAny, text, loading, uplineData, currency, program, bnbPriceByAmountIsLoading, frgxPriceByAmountIsLoading, approveInfo]);

  if (!isEndedTime) {
    return (
    <button
      className={`rounded-[16px] bg-primary-500 text-primary-950 py-5 w-full ${className}`}
    >
      <Timer isCompleted={isEndedTime} setIsCompleted={setIsEndedTime} />
    </button>
    
    )
  } 
  return (
    <button
      disabled={disabled || statuses.checkBalance === STATUSES_ENUM.ERROR}
      onClick={() => handleClickAction()}
      className={`rounded-[16px] bg-primary-500 text-primary-950 py-5 w-full ${className}`}
    >
      {renderButtonAction}
    </button>
  );
};
