import React from 'react';
import { AboutBorder } from '../../../components/AboutBorder';

export const AboutFrgx = () => {
  return (
    <div className="flex flex-col items-center space-y-[120px] max-w-[1115px] pt-[200px] sm:pt-20 sm:space-y-0 relative w-full sm:px-4">
      <div className="flex flex-col items-center space-y-8 relative w-full sm:pb-10">
        <span className="text-[54px] text-white poppins font-semibold text-center sm:text-h2">
          FRGX Token Role in Qornex <br />
          <span className="about-title">Decentralized Marketing</span>
        </span>
        <span className="text-xl text-white poppins">Qornex runs on the FRGX token.</span>
        <span className="leading-8 text-white-500 font-light text-center">
          FRGX is a token in the BNB Chain network with limited emission, open smart contract code and multiple areas of
          use. FRGX Token Network Growth has been stable since its launch, and user interest is supported through
          regular releases of new products and solutions, where the token receives utility.
        </span>
      </div>
      <div className="flex space-x-3 w-full sm:flex-col sm:space-x-0 sm:space-y-3">
        <div className="flex flex-col space-y-3 w-full">
          <AboutBorder className="!min-h-[216px] w-full space-y-2 sm:!min-h-full sm:py-6 ">
            <span className="about-marketcap-text text-[80px] font-bold leading-[80px] sm:text-[60px] sm:leading-[60px]">
              $9M
            </span>
            <span className="text-xl text-white poppins font-semibold sm:text-lg">Market Cap</span>
          </AboutBorder>
          <div className="flex items-center h-[128px] justify-center w-full h-full no-fees-bg rounded-3xl">
            <span className="text-[60px] text-white poppins font-semibold">No Fees</span>
          </div>
          <AboutBorder className="px-6 py-12 w-full sm:py-3 ">
            <img src="/images/about/aboutFrgx/x.png" />
            <div className="flex flex-col items-center justify-center space-y-2">
              <span className="text-[36px] text-white poppins leading-10 font-semibold">#FRGX</span>
              <span className=" text-white-500 leading-5 font-light poppins sm:text-lg sm:text-white sm:text-center sm:font-semibold">
                trending on X(Twitter)
              </span>
            </div>
          </AboutBorder>
        </div>
        <div className="flex flex-col space-y-3 w-full">
          <AboutBorder className="!min-h-[427px] !justify-end w-full space-y-2 pb-12 sm:!min-h-[290px] sm:pb-6 ">
            <img className="absolute top-0 sm:top-[-30px] sm:max-h-[321px]" src="/images/about/aboutFrgx/holders.png" />
            <div className="flex flex-col items-center z-[1] sm:space-y-2">
              <span className="holder-text text-[91px] leading-[121px] font-semibold sm:text-[60px] sm:leading-[60px]">
                39,000
              </span>
              <span className="text-white text-[36px] leading-[49px] font-semibold sm:text-lg sm:leading-8">
                Holders
              </span>
            </div>
            <img className="absolute bottom-0 w-full" src="/images/about/aboutFrgx/holdersShadow.png" />
          </AboutBorder>
          <AboutBorder className="!justify-between px-6 pt-5 pb-[26px] w-full sm:px-[13px] sm:pt-6 sm:pb-6">
            <img src="/images/about/aboutFrgx/bnbChain.png" />
            <div className="flex flex-col items-center justify-center space-y-2">
              <span className="text-xl text-[#F4B617] poppins leading-8 font-semibold">One of 150 tokens</span>
              <span className="text-white-500 leading-5 font-light poppins sm:text-lg sm:text-white sm:text-center sm:font-semibold">
                On the BNB Chain by <br className="hidden sm:flex" /> number of holders
              </span>
            </div>
          </AboutBorder>
        </div>
        <div className="flex flex-col space-y-3 w-full">
          <AboutBorder className="w-full space-y-2 p-6 ">
            <span className="text-[#EBFF29] text-[140px] font-bold leading-[140px]">$2M</span>
            <span className="text-white-500 leading-8 font-light poppins sm:text-lg sm:text-white sm:text-center sm:font-semibold">
              Average daily trading <br className="hidden sm:flex" /> volume
            </span>
          </AboutBorder>
          <AboutBorder className="w-full space-y-2 p-6 price-growth-bg">
            <span className="price-growth-text text-[36px] font-semibold poppins leading-8">+112000%</span>
            <span className="price-growth-text text-[199px] poppins leading-[199px] font-semibold">%</span>
            <span className="text-white text-lg font-medium poppins leading-8 font-light sm:poppins sm:text-lg sm:text-white sm:text-center sm:font-semibold">
              Price growth
            </span>
          </AboutBorder>
          <AboutBorder className="px-6 w-full p-6 ">
            <span className="text-[60px] font-semibold leading-[80px] no-owner-bg">No Owner</span>
          </AboutBorder>
        </div>
      </div>
    </div>
  );
};
