import { React, useState, useEffect, useMemo } from 'react';
import { Modal } from '../../Modal';
import { PROGRAM_EXPIRED, PROGRAM_NAMES } from '../../../helpers/constants';
import { AnimatedCounter } from 'components/AnimatedCounter';
import { BuyLevelButton } from '../../BuyLevelButton';
import { useCallTransaction } from '../../../helpers/hooks/useCallTransaction';
import { useDispatch } from 'react-redux';
import { updateMatrixB } from '../../../store/matrixBSlice';
import { useLazyQuery } from '@apollo/client';
import { GET_MATRIX_DATA } from '../../../helpers/graphRequests';
import { useWeb3React } from '@web3-react/core';
import { TransactionStatuses } from '../../TransactionStatuses';
import { EXPLORER_URL } from '../../../helpers/constants';
import Spline from '@splinetool/react-spline';

export const ActivateLvlUsdtModal = ({ openedModal, onClose, level, program = PROGRAM_NAMES.MATRIX_USDT }) => {
  const [currency, setCurrency] = useState('usdt');
  const [count, setCount] = useState(1);
  const { account } = useWeb3React();
  const dispatch = useDispatch();
  const { onCallTransaction, transactionInfo, resetTransactionInfo } = useCallTransaction();
  const [callRequestMatrix] = useLazyQuery(GET_MATRIX_DATA, { variables: { user: null }, fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (transactionInfo?.isSuccess && account) {
      callRequestMatrix({ variables: { user: account.toLocaleLowerCase() } }).then((result) => {
        if (!!result?.data?.user?.levels) {
          dispatch(
            updateMatrixB({
              loading: result?.loading,
              called: result?.called,
              levels: result?.data?.user?.levels,
              levels2: result?.data?.user?.levels2,
            }),
          );
        }
      });
    }
  }, [transactionInfo]);

  const closeModal = () => {
    onClose();
    setCurrency('usdt');
    resetTransactionInfo();
  };

  const renderContent = useMemo(() => {
    if (transactionInfo?.hash) {
      return (
        <div className="w-full h-full flex items-center justify-center">
          <TransactionStatuses transactionInfo={transactionInfo} />
        </div>
      );
    } else {
      return (
        <div className="">
          <Spline scene="https://prod.spline.design/CtOQ6lsxErA2iyQN/scene.splinecode" />
        </div>
      );
    }
  }, [transactionInfo, count, level]);

  const renderButton = useMemo(() => {
    if (transactionInfo?.hash) {
      let hash = EXPLORER_URL + '/tx/' + transactionInfo?.hash;
      return (
        <a
          href={hash}
          target="_blank"
          rel="noreferrer"
          className="flex items-center justify-center text-white-300 poppins font-medium rounded-[16px] bg-white-50 py-5 w-full"
        >
          Explore transaction
        </a>
      );
    }
    return (
      <BuyLevelButton
        onCallTransaction={onCallTransaction}
        level={level}
        count={1}
        program={PROGRAM_NAMES.MATRIX_USDT}
        className={'!bg-main-usdt !mt-5'}
        currency={currency}
      />
    );
  }, [transactionInfo, level, count, program, currency]);

  const statusStyle = () => {
    if (transactionInfo?.isSuccess) {
      return {
        bg: 'activateModalBg_success',
        icon: '/icons/transactions/modal/successStar.svg',
      };
    }
    if (transactionInfo?.isError) {
      return {
        bg: 'activateModalBg_error',
        icon: '/icons/transactions/modal/errorStar.svg',
      };
    }
    return {
      bg: 'usdtBgShadow',
      icon: '/icons/transactions/modal/usdtStar.svg',
    };
  };

  const renderCurrencyToggle = useMemo(() => {
    return (
      <div className="flex w-full h-[55px] flex-shrink-0 border border-white-100 rounded-[16px] p-2 !mt-0">
        <div
          onClick={() => setCurrency('usdt')}
          className={`${
            currency === 'usdt' ? 'bg-main-usdt hover:opacity-75' : 'bg-transparent text-white'
          } mr-1 cursor-pointer uppercase font-semibold rounded-[12px] h-full flex flex-1 items-center justify-center`}
        >
          usdt
        </div>
        <div
          onClick={() => setCurrency('bnb')}
          className={`${
            currency === 'bnb' ? 'bg-main-usdt hover:opacity-75' : 'bg-transparent text-white hover:bg-white-50'
          } mx-1 cursor-pointer uppercase font-semibold rounded-[12px] h-full flex flex-1 items-center justify-center`}
        >
          bnb
        </div>
        <div
          onClick={() => setCurrency('frgx')}
          className={`${
            currency === 'frgx' ? 'bg-main-usdt hover:opacity-75' : 'bg-transparent text-white hover:bg-white-50'
          } ml-1 cursor-pointer uppercase font-semibold rounded-[12px] h-full flex flex-1 items-center justify-center`}
        >
          frgx
        </div>
      </div>
    );
  }, [currency]);

  return (
    <Modal isOpened={openedModal} onClose={closeModal}>
      <div
        className={`flex p-8 flex-col space-y-5 shadow-item backdrop-blur-bold h-[500px] sm:h-[600px] w-[376px] sm:w-full ${
          statusStyle()?.bg
        } items-start justify-start rounded-3xl space-y-11 sm:w-full sm:p-0 sm:px-4 sm:rounded-none sm:rounded-t-3xl sm:justify-end sm:pb-[121px] sm:space-y-10`}
      >
        <div className="flex absolute top-[-50px] left-1/2 -translate-x-1/2 sm:top-[-55px]">
          <img className="" src={statusStyle()?.icon} />
          <span className="whitespace-nowrap absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-[27.5px] poppins text-white font-medium">
            {level} Lvl
          </span>
        </div>
        <div className="flex items-center justify-center flex-col flex-1 w-full">{renderContent}</div>
        {!transactionInfo?.hash && renderCurrencyToggle}
        {renderButton}
      </div>
    </Modal>
  );
};
