import React, { useEffect, useMemo, useState } from 'react';
import { bigIntToInt } from '../../../../helpers/numbers';
import { CONTRACT_NAMES } from '../../../../helpers/constants';
import { useGetContract } from '../../../../helpers/hooks/useGetContract';
import { useWeb3React } from '@web3-react/core';
import { formatEther } from '@ethersproject/units';
import { JsonRpcProvider } from '@ethersproject/providers';
import { fromWei } from '../../../../helpers/numbers';
import { useCheckPreviewMode } from '../../../../helpers/hooks/useCheckPreviewMode';
import config from '../../../../helpers/config';
import { Link } from 'react-router-dom';
import { Timer } from './Timer';

export const UserProfit = ({ matrixInfo = {} }) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [farmingProfit, setFarmingProfit] = useState(0);
  const [stakingProfit, setStakingProfit] = useState(0);
  const { account } = useWeb3React();
  const { isPreviewMode, previewUserId } = useCheckPreviewMode();
  const currentUserId = !!isPreviewMode ? previewUserId : account;
  const { getContract } = useGetContract();

  const matrixProfitUsdt =
    !!matrixInfo?.levels2 &&
    matrixInfo?.levels2?.reduce((result, item) => {
      let totalReward = bigIntToInt(item?.totalReward) || 0;
      return result + totalReward;
    }, 0);

  const matrixProfitFrgx =
    !!matrixInfo?.levels &&
    matrixInfo?.levels?.reduce((result, item) => {
      let totalReward = bigIntToInt(item?.totalReward) || 0;
      return result + totalReward;
    }, 0);

  const callFarmingProfit = async () => {
    try {
      const otherProvider =
        !isPreviewMode || account
          ? undefined
          : new JsonRpcProvider('https://bsc-rpc.publicnode.com', parseInt(config.allowedChainId));

      const contract = await getContract(CONTRACT_NAMES.FARMING_POOL, otherProvider);

      const historicalReceived = await contract.historicalRewardReceived(currentUserId);

      setFarmingProfit(parseFloat(Number(formatEther(historicalReceived))?.toFixed(0)));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (currentUserId) {
      callFarmingProfit();
    }
  }, [currentUserId]);

  const callStakingProfit = async () => {
    try {
      const otherProvider =
        !isPreviewMode || account
          ? undefined
          : new JsonRpcProvider('https://bsc-rpc.publicnode.com', parseInt(config.allowedChainId));

      const contract = await getContract(CONTRACT_NAMES.STAKING_POOL, otherProvider);
      const dividents = await contract.rewardReceived(currentUserId);

      setStakingProfit(parseFloat(fromWei(dividents)).toFixed(0));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (currentUserId) {
      callStakingProfit();
    }
  }, [currentUserId]);

  const staticsInfo = useMemo(
    () => [
      {
        title: 'Farming',
        value: Number(farmingProfit) || 0,
      },
      {
        title: 'Staking',
        value: Number(stakingProfit) || 0,
      },
    ],
    [farmingProfit, stakingProfit],
  );

  return (
    <div className="flex flex-col justify-between relative rounded-3xl min-h-[280px] border-[1px] border-white-100 row-span-1 col-span-2 sm:row-span-1 sm:col-span-1 overflow-hidden user-profit-bg z-[1] shadow-item backdrop-blur-large poppins z-[1] sm:min-h-[280px] sm:col-span-2">
      <div className="flex flex-col justify-between w-full h-full p-6 z-[1] sm:p-0 sm:py-6 sm:px-4 profit-info-shadow">
        <span className="text-lg poppins text-white font-medium sm:mb-6">Your total result in Qornex</span>
        <div className="flex space-x-3 z-[11] sm:flex-col sm:space-x-0 sm:space-y-3 sm:w-full sm:mb-4">
          <Link
            to={`/programs/usdt` + location?.search}
            className="flex flex-col justify-between statics-usdt-bg rounded-xl w-[200px] px-3 py-[11px] space-y-6 sm:w-full"
          >
            <div className="flex items-center space-x-2">
              <img src="/icons/dashboard/profitInfo/usdtLogo.svg" />
              <span className="text-xs text-white-500 inter font-medium">Qornex USDT</span>
            </div>
            <div className="flex space-x-1 items-end">
              <span className="text-h2 text-white leading-8 font-semibold poppins">
                {Number(matrixProfitUsdt).toFixed(0)}
              </span>
              <span className="text-xs text-white font-medium inter leading-[18px]">USDT</span>
            </div>
          </Link>
          <Link
            to={`/programs/frgx` + location?.search}
            className="statics-frgx-bg rounded-xl w-[200px] px-3 py-[11px] space-y-6  sm:w-full"
          >
            <div className="flex items-center space-x-2">
              <img src="/icons/dashboard/profitInfo/frgxLogo.svg" />
              <span className="text-xs text-white-500 inter font-medium">Qornex FRGX</span>
            </div>
            <div className="flex space-x-1 items-end">
              <span className="text-h2 text-white leading-8 font-semibold poppins">
                {Number(matrixProfitFrgx).toFixed(0)}
              </span>
              <span className="text-xs text-white font-medium inter leading-[18px]">FRGX</span>
            </div>
          </Link>

          {/* <div className="flex items-center justify-center space-x-2  border-[1px] border-white-100 rounded-[40px] pl-1 pr-3 py-1">
            <div className="flex items-center justify-center w-6 h-6 bg-lightGreen rounded-full">
              <img className="" src="/icons/dashboard/userProfit/dollarIcon.svg" />
            </div>
            <span className="text-base text-white poppins">3339,12</span>
          </div> */}
        </div>
        <div className="flex space-x-12 sm:space-x-3 sm:justify-between z-[11]">
          {staticsInfo?.map((item, itemIndex) => {
            return (
              <div
                className="flex flex-col space-y-2 sm:bg-black-500 sm:items-center sm:rounded-xl sm:py-2 sm:w-full sm:px-3"
                key={itemIndex}
              >
                <span className="text-white-500 text-sm font-light sm:text-xs">{item?.title}</span>
                <span className="text-xl text-white font-medium sm:text-base">{item?.value}</span>
              </div>
            );
          })}
        </div>
        <img
          className="absolute right-0 bottom-0 z-[1] h-[225px] sm:hidden"
          src="/images/dashboard/profitInfo/qornexLogo.png"
        />
      </div>
    </div>
  );
};
