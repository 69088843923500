import React from 'react';

export const TeamBuilding = () => {
  const matrixList = [
    {
      shadow: 'yellow_circle_bg',
      border: 'yellow_border_color',
      icon: 'yellowPartnerIcon',
    },
    {
      shadow: 'yellow_circle_bg',
      border: 'yellow_border_color',
      icon: 'yellowPartnerIcon',
    },
    {
      shadow: 'yellow_circle_bg',
      border: 'yellow_border_color',
      icon: 'yellowPartnerIcon',
    },
    {
      shadow: 'purple_circle_bg',
      border: 'purple_border_color',
      icon: 'purpleRecycleIcon',
    },
    {
      shadow: 'yellow_circle_bg',
      border: 'yellow_border_color',
      icon: 'yellowPartnerIcon',
    },
    {
      shadow: 'purple_circle_bg',
      border: 'purple_border_color',
      icon: 'purpleRecycleIcon',
    },
  ];
  return (
    <div className="flex flex-col space-y-[120px] pt-[200px] w-full max-w-[1115px] sm:pt-20 sm:px-4 sm:space-y-10">
      <div className="flex flex-col space-y-8">
        <span className="text-[54px] text-white poppins font-semibold text-center sm:text-h2">
          Decentralized
          <span className="about-title"> Team Building</span> <br className="sm:hidden" /> on Qornex
        </span>
        <span className="text-white-500 leading-8 font-light text-center">
          Users can create partner structures and get rewarded every time their affiliates activate new levels. 80% of
          the level cost is distributed between the 1st line partner and the 2nd line partner.
        </span>
      </div>
      <div className="flex items-center justify-between rounded-[40px] backdrop-blur-large w-full team-building-matrix-bg py-[34.6px] px-[38.93px] sm:px-[17px] sm:py-0 sm:pb-6 sm:grid sm:gap-y-10 sm:gap-x-9 sm:grid-cols-3">
        {matrixList.map((item, itemIndex) => {
          return (
            <div className="flex flex-col items-center">
              <div className="absolute sm:relative top-[-60px] flex items-center justify-center rounded-full bg-white-70 h-[39px] w-[68px] backdrop-blur-large shadow-item absolute sm:top-[-10px]">
                <span className="text-[#EBFF29] text-lg font-semibold sm:leading-4">80%</span>
              </div>
              <div
                className={`${item?.border} flex items-center justify-center rounded-full p-[1px] sm:w-fit`}
                key={itemIndex}
              >
                <div className="relative flex items-center justify-center w-[80px] h-[80px] bg-[#2E2E2E] z-[1111] rounded-full  cursor-pointer">
                  <img className="z-[1111]" src={`/icons/instrument/${item?.icon}.svg`} />
                  <div className={`${item?.shadow} blur-[20px] w-[26px] h-[26px] absolute`} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <span className="text-white-500 leading-8 text-center font-light">
        Users can receive affiliate rewards as long as their levels are active. Each level has an expiration date that
        works like a subscription. When a level expires, you need to activate it again to continue receiving{' '}
        <span className="about-title">marketing network</span> rewards on{' '}
        <span className="about-title">blockchain.</span> Until then, the person higher up in the structure starts
        receiving the missed rewards.
      </span>
    </div>
  );
};
