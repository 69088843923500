import React, { useState } from 'react';
import { useMemo } from 'react';

export const CompletePlace = ({ setCurrentUser, value, firstly }) => {
  const slots = useMemo(() => {
    switch (value?.status) {
      case 'direct':
        return {
          placeBg: 'directPlace',
        };
      case 'overflow':
        return {
          placeBg: 'overflowPlace',
        };
      case 'reinvest':
        return {
          placeBg: 'reinvestPlace',
        };
      case 'gift':
        return {
          placeBg: 'giftPlace',
        };
      case 'stored':
        return {
          placeBg: 'storedPlace',
        };
    }
  }, [value]);

  const colorPlaceBg = {
    backgroundImage: `url(/images/programs/usdt/${slots?.placeBg}.png)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  return (
    <div
      onClick={() => setCurrentUser(value)}
      style={colorPlaceBg}
      className={`flex items-center justify-center hover:opacity-75 hover:cursor-pointer ${
        firstly ? 'w-[174px] h-[194px] sm:w-[74px] sm:h-[83px]' : 'w-[80px] h-[89px] sm:w-[34px] sm:h-[38px]'
      }`}
    >
      <div
        className={`flex items-center justify-center  rounded-full h-[36px] ${
          firstly
            ? 'max-w-[75%] bg-white-100 text-sm sm:bg-transparent sm:text-[6px] sm:max-w-full'
            : 'text-[10px] sm:text-[2.9px]'
        }  w-full`}
      >
        <span className="text-white inter font-semibold">ID {value?.user?.internalId}</span>
      </div>
    </div>
  );
};
