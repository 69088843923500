import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import Lottie from 'lottie-react';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { WALLETS } from '../../../connectors/wallets';
import { useTryActivation } from '../../../helpers/hooks/useTryActivation';
import loadingQornexAnimation from '../../../animations/loadingQornex.json';
import { updateUser } from '../../../store/userSlice';
import { updateMatrixB } from '../../../store/matrixBSlice';
import { GET_USER_DATA, GET_MATRIX_DATA } from '../../../helpers/graphRequests';
import { getUser } from '../../../store/userSlice/selectors';
import { Register } from './Register';
import { destroyCookie } from 'nookies';
import { UserRepository } from '../../../connectors/repositories/user';

export const LoginOrRegister = ({ setCurrentUserId, isNeedToRegister, data, loading, called, currentUserId }) => {
  const { account } = useWeb3React();
  const { tryActivation } = useTryActivation();

  const dispatch = useDispatch();
  const currentUser = useSelector(getUser);

  const onWalletClick = (wallet) => () => {
    tryActivation(wallet.connector);
  };

  useEffect(() => {
    if (account || currentUserId !== account) {
      setCurrentUserId(account);
    }
  }, [account, currentUserId]);

  const styleBg = useMemo(() => {
    if (account && !!data && !data?.user?.id) {
      return {
        backgroundImage: 'url(/images/checkConnect/register.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      };
    }
    return {
      backgroundImage: 'url(/images/checkConnect/connectWallet.png)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    };
  });

  const renderContent = useMemo(() => {
    if (!account) {
      return (
        <div className="flex w-full flex-col max-w-[527px] space-y-[52px]">
          <div className="flex flex-col space-y-3">
            <h1 className="text-white font-medium poppins">Connect your wallet</h1>
            <span className="text-base text-white-500 font-light">
              Select your favourite wallet to log in Qornex.{' '}
              {/* <a className="text-white hover:underline cursor-pointer">
                  What is a <br />
                  wallet?
                </a> */}
            </span>
          </div>
          <div className="flex w-full flex-col space-y-3.5 max-w-[460px] z-[11] overflow-auto sm:pb-5">
            {WALLETS?.map((wallet) => {
              return (
                <div
                  className="flex items-center space-x-5 rounded-[20px] border border-[1px] border-white-100 hover:bg-white-50 w-full p-2 cursor-pointer hover:bg-darkPurple"
                  onClick={onWalletClick(wallet)}
                  key={wallet.title}
                >
                  <img alt="walletIcon" src={wallet.icon} />
                  <div className="flex flex-col ml-5 space-y-3">
                    <span className="poppins text-medium text-white font-medium leading-none">{wallet.title}</span>
                    <span className="text-sm text-white-400 font-light leading-none">{wallet.subtitle}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    if (isNeedToRegister) {
      return <Register />;
    }
  }, [account, isNeedToRegister]);

  return (
    <div className="flex sm:flex-col items-center justify-center fixed top-0 left-0 right-0 bottom-0 h-screen bg-[#0B0B0B] z-[9998] overflow-hidden">
      <div style={styleBg} className="w-[41%] h-full sm:hidden" />
      <div className="z-[999] w-[59%] sm:w-full h-full flex flex-col items-center justify-center p-2.5 overflow-hidden">
        {currentUserId && (loading || !called) ? (
          <Lottie animationData={loadingQornexAnimation} loop={true} />
        ) : (
          renderContent
        )}
      </div>
      <img className="absolute bottom-0 right-0 z-[1]" src="/images/checkConnect/shadow.png" />
    </div>
  );
};
