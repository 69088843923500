import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useCheckUpline } from '../../helpers/hooks/useCheckUpline';
import { callNotification } from '../../helpers/notification';

export const PreviewMode = ({ previewUserId, className }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [inputUserId, setInputUserId] = useState(previewUserId);
  const navigate = useNavigate();

  const {
    isValidUpline: isValidPreviewId,
    loadingCheckUpline: loadingCheckPreviewId,
    checkUpline: checkPreviewId,
    isDoneChecked,
  } = useCheckUpline();

  const updatePreviewId = () => {
    checkPreviewId({ value: inputUserId });
  };

  useEffect(() => {
    if (isDoneChecked) {
      if (isValidPreviewId) {
        setSearchParams((params) => {
          params.set('previewUser', inputUserId);
          return params;
        });
        navigate(0);
        setIsOpen(false);
      } else {
        callNotification({ type: 'error', message: 'invalid ID' });
      }
    }
  }, [isDoneChecked, isValidPreviewId]);

  const removePreviewMode = () => {
    searchParams.delete('previewUser');
    setSearchParams(searchParams);
    navigate(0);
  };

  return (
    <div className="sm:flex sm:items-center sm:justify-center sm:px-4 sm:fixed sm:top-3 z-[1111] sm:w-full">
      <div className="flex flex-col w-full h-full bg-secondary-500 rounded-2xl sm:pl-4 sm:pr-[10px] space-y-6 ">
        <div
          className={`flex items-center justify-between  px-6  h-full min-h-[60px] space-x-3 sm:w-full sm:px-0 ${className}`}
        >
          <img className="hidden sm:flex " src="/icons/previewMode/qornexLogo.svg" />
          <img className="sm:hidden" src="/icons/previewMode/searchIcon.svg" />
          {!isOpen && (
            <div className="flex items-center justify-center space-x-6">
              <span className="text-base poppins text-white font-semibold">Preview mode</span>
              <input
                placeholder="Enter ID"
                value={inputUserId}
                onChange={(e) => setInputUserId(e.target.value)}
                className="min-w-[285px] sm:hidden pl-6 h-[44px] bg-white-100 rounded-xl outline-none text-white"
              />
            </div>
          )}
          <button
            onClick={() => updatePreviewId()}
            className="flex items-center justify-center h-11 w-11 bg-primary-500 rounded-xl sm:hidden"
          >
            <span className="text-[#0B0B0B] poppins text-sm font-medium">Go</span>
          </button>
          <button
            onClick={() => removePreviewMode()}
            className="flex items-center justify-center h-11 w-11 bg-white-100 rounded-xl sm:hidden"
          >
            <img src="/icons/previewMode/closeIcon.svg" />
          </button>
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center justify-center h-11 w-11 bg-white-100 rounded-xl hidden sm:flex"
          >
            <img src={`/icons/previewMode/${!isOpen ? 'searchIcon' : 'closeIcon'}.svg`} />
          </button>
        </div>
        {isOpen && (
          <div className="flex flex-col pb-4">
            <span className="text-xl text-white poppins font-semibold pb-4">Preview mode</span>
            <div className="flex flex-col space-y-3">
              <input
                placeholder="Enter ID"
                value={inputUserId}
                onChange={(e) => setInputUserId(e.target.value)}
                className="bg-white-100 border-[1px] border-white-100 h-12 rounded-2xl px-6 py-[14px] w-full outline-none text-white"
              />
              <button
                onClick={() => updatePreviewId()}
                className="flex items-center justify-center h-12 bg-primary-500 rounded-2xl w-full"
              >
                <span className="text-[#0B0B0B] poppins text-base font-semibold">Go</span>
              </button>
            </div>
            <button
              onClick={() => removePreviewMode()}
              className="flex items-center justify-center h-12 bg-white-100 rounded-2xl w-full mt-6"
            >
              <span className="text-white poppins text-base font-semibold">Exit Preview mode</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
