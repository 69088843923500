import React from 'react';
import { Modal } from '../../Modal';
import { TableItem } from './TableItem';
import { PROGRAM_NAMES, PROGRAM_PRICES } from '../../../helpers/constants';

export const PriceListModal = ({
  program = PROGRAM_NAMES.MATRIX_B,
  openedModal,
  handleCloseModal,
  nextLvl,
  activeLvls,
}) => {
  return (
    <Modal isOpened={openedModal} onClose={handleCloseModal}>
      <div className="flex p-8 flex-col  items-start justify-start w-[918px] max-h-[85vh] h-full bg-lightGray-300 rounded-3xl space-y-11 sm:w-full sm:rounded-none sm:rounded-t-3xl sm:px-4 sm:h-[650px] sm:p-0 sm:pt-10 sm:space-y-6">
        <h2 className="font-poppins text-white font-medium poppins leading-none sm:text-xl">Level Prices</h2>
        <div className="flex flex-col w-full space-y-2 overflow-auto sm:pb-10">
          {Object.values(PROGRAM_PRICES?.[program])?.map((item, itemIndex) => {
            const isReadyToActivate = itemIndex + 1 === nextLvl;
            const isActive = itemIndex + 1 < nextLvl;
            const activeLvlInfo = isActive ? activeLvls[itemIndex] : null;
            return (
              <TableItem
                program={program}
                activeLvlInfo={activeLvlInfo}
                price={item}
                lvl={itemIndex + 1}
                isActive={isActive}
                isReadyToActivate={isReadyToActivate}
                key={itemIndex}
              />
            );
          })}
        </div>
      </div>
    </Modal>
  );
};
