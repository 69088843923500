import React, { useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import CryptoJS from 'crypto-js';
import { useSelector } from 'react-redux';

import { shortenAddress } from '../../../../helpers/format';
import { Reflink } from './Components/Reflink';
import { Balance } from './Components/Balance';
import { getUser } from '../../../../store/userSlice/selectors';

import { copy } from '../../../../helpers/copy';
import { Partners } from './Components/Partners';

import { useCheckPreviewMode } from '../../../../helpers/hooks/useCheckPreviewMode';
import { useSearchParams, useNavigate } from 'react-router-dom';

export const UserInfo = ({ totalPartners = 0, partners24 = 0 }) => {
  const currentUser = useSelector(getUser);
  const avatar = localStorage.getItem('avatar');
  const nickname = localStorage.getItem('nickname');
  const { account } = useWeb3React();
  const { isPreviewMode, isDoneCheckedPreview } = useCheckPreviewMode();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const avatarImage = useMemo(() => {
    if (avatar) {
      return {
        backgroundImage: `url(${avatar})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      };
    }
    return {
      backgroundImage: `url(https://api.dicebear.com/7.x/thumbs/svg?seed=${CryptoJS.SHA256(account)}&scale=80)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    };
  }, [avatar]);

  const isHub = currentUser?.referral?.id === '0xc7cc0b22e980e493f86ec252b6275312e5d06f4e';

  const redirectToUpline = async (id) => {
    if (id !== '0xc7cc0b22e980e493f86ec252b6275312e5d06f4e') {
      setSearchParams((params) => {
        params.set('previewUser', id);
        return params;
      });
      navigate(0);
    }
  };

  return (
    <div className="relative flex flex-col max-w-[370px] h-fit space-y-[20px] row-span-2 rounded-3xl user-info-bg px-6 pt-8 pb-6 sm:px-4 sm:pt-6 sm:pb-4 shadow-wrapper border-[1px] border-white-100 sm:max-w-full sm:order-first sm:row-span-1 sm:col-span-2">
      <div className="flex items-center space-x-3 ">
        <div style={avatarImage} className="bg-black w-[60px] h-[60px] rounded-full overflow-hidden shrink-0" />
        <div className="flex flex-col space-y-0.5">
          <span className="text-lg text-white font-medium poppins">{nickname || 'Qornex Member'}</span>
          <div className="flex items-center space-x-1.5">
            <span className="text-white-500 font-light">ID {currentUser?.internalId}</span>
            <div onClick={() => copy(currentUser?.id)} className="text-sm bg-white-50 text-white-500 hover:text-white cursor-pointer font-light px-2.5 py-0.5 rounded-[16px]">{shortenAddress(currentUser?.id, 5)}</div>
          </div>
        </div>
      </div>
      {currentUser?.referral?.id && (
        <div
          onClick={() => redirectToUpline(currentUser?.referral?.internalId)}
          className={`z-[20] absolute left-1/2 -translate-x-1/2 bg-[#161616] border-[1px] border-white-100 ${
            isHub ? '' : 'cursor-pointer'
          } top-[-37.5px] sm:static sm:translate-x-0 sm:left-0 sm:top-0 flex items-center space-x-2.5 px-4 h-[36px] bg-white-30 backdrop-filter rounded-3xl upline-block-shadow`}
        >
          <span className="text-sm text-white poppins">Upline</span>
          <span className="text-sm text-white-500 poppins font-light whitespace-nowrap">
            {isHub ? 'hub' : `ID ${currentUser?.referral?.internalId}`}
          </span>
        </div>
      )}
      <Reflink internalId={currentUser?.internalId} partners24={partners24} />
      <Partners count={totalPartners} />
      {!isPreviewMode && <Balance />}
    </div>
  );
};
