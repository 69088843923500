import React from 'react';
import Marquee from 'react-fast-marquee';

export const Ticker = () => {
  return (
    <div className="flex space-x-3 items-center h-[60px] row-span-1 col-span-3 sm:row-span-1 sm:col-span-1 sm:!order-first rounded-full border-[1px] border-white-100 bg-[#161616] px-6 sm:space-x-0 sm:col-span-2">
      <div className="flex space-x-3 items-center  sm:min-w-fit ">
        <img src="/icons/ticker/newsIcon.svg" />
        <span className="text-lg text-white font-semibold poppins whitespace-nowrap sm:hidden min-w-[170px]">
          Breaking News
        </span>
      </div>
      <Marquee className="!flex " gradient gradientColor="#161616" gradientWidth={50} autoFill>
        <div className="flex space-x-2 items-center">
          <div className="flex items-center space-x-2 px-10 sm:px-5">
            <img className="w-6 h-6" src="/icons/ticker/usdtIcon.svg" />
            <span className="text-lg text-white poppins font-medium sm:text-sm ">Qornex USDT</span>
          </div>
          <div className="bg-white-500 h-[4px] w-[4px] rounded-full" />
        </div>
      </Marquee>
    </div>
  );
};
