import React, { useMemo } from 'react';
import { Timer } from './Timer';
import { bigIntToInt } from '../../../../helpers/numbers';
import { LEVEL_STATUSES } from '../../../../helpers/instrument';

export const Profit = ({
  levelNumber,
  totalReward,
  activationTimes,
  endTime,
  setLevelEnded,
  levelEnded,
  isOpen,
  levelStatus,
}) => {
  const renderStar = useMemo(() => {
    if (levelEnded) {
      return (
        <div className="relative flex-shrink-0 flex items-center justify-center">
          <img className="sm:w-[60px] sm:h-[60px]" src="/icons/instrument/star_disabled.svg" />
          <img
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-6 h-6"
            src="/icons/instrument/star_lock.svg"
          />
        </div>
      );
    }
    if (levelStatus?.status === LEVEL_STATUSES.ORANGE) {
      return (
        <div className="relative flex-shrink-0 flex items-center justify-center">
          <img className="sm:w-[60px] sm:h-[60px]" src="/icons/instrument/star_orange.svg" />
          <span className="absolute z-[1] text-black text-lg poppins font-medium sm:text-base">Lvl {levelNumber}</span>
        </div>
      );
    }
    if (levelStatus?.status === LEVEL_STATUSES.RED) {
      return (
        <div className="relative flex-shrink-0 flex items-center justify-center">
          <img className="sm:w-[60px] sm:h-[60px]" src="/icons/instrument/star_red.svg" />
          <span className="absolute z-[1] text-black text-lg poppins font-medium sm:text-base">Lvl {levelNumber}</span>
        </div>
      );
    }
    return (
      <div className="relative flex-shrink-0 flex items-center justify-center">
        <img className="sm:w-[60px] sm:h-[60px]" src="/icons/instrument/star.svg" />
        <span className="absolute z-[1] text-black text-lg poppins font-medium sm:text-base">Lvl {levelNumber}</span>
      </div>
    );
  }, [levelEnded, levelNumber, levelStatus]);

  const renderStatus = useMemo(() => {
    if (levelEnded) {
      return <span className="text-white-500 sm:text-base">Level expired</span>;
    }
    return (
      <span className="text-white-500 sm:text-base">
        <Timer time={endTime} onComplete={() => setLevelEnded(true)} />
      </span>
    );
  }, [levelEnded, endTime, activationTimes]);

  const levelReward = bigIntToInt(totalReward) || 0;

  return (
    <div className="flex items-center justify-between sm:w-full">
      <div className="flex items-center space-x-4">
        {renderStar}
        <div className="flex flex-col">
          <span className="text-h2 text-white font-medium sm:text-xl ">{levelReward} FRGX</span>
          <div className="flex flex-wrap items-center">
            <span className="text-white-500 sm:text-base mr-2">Activations: {activationTimes}</span>
            <div className="border border-[1px] border-white-500 h-[20px] mr-2" />
            {renderStatus}
          </div>
        </div>
      </div>
      <button className="hidden flex-shrink-0 sm:flex items-center justify-center bg-white-70 w-10 h-10 rounded-xl">
        <img className={`${isOpen ? 'rotate-[-90deg]' : ''}`} src="/icons/instrument/arrow.svg" />
      </button>
    </div>
  );
};
