import React, { useEffect, useState, useMemo } from 'react';
import { BreadCrumbs, LeftBar, LevelsStatus, Header } from 'components';
import { LevelActivating, ActiveLvl, NonActiveLvlList } from 'features/programUsdt';
import { BaseLayout } from 'layouts';
import { PROGRAM_MAX_LEVELS, PROGRAM_NAMES } from '../../../helpers/constants';
import { useWeb3React } from '@web3-react/core';
import Lottie from 'lottie-react';
import loadingQornexAnimation from '../../../animations/loadingQornex.json';
import { useSelector } from 'react-redux';
import { getMatrixB } from '../../../store/matrixBSlice/selectors';
import { LoadingAnimation } from '../../../components';
import { UsdtMatrix } from '../../../features/programUsdt/UsdtMatrix';
import { OutputSettingsModal } from '../../../components/Modals/OutputSettingsModal';
import { useModal } from '../../../helpers/hooks/useModal';

export const ProgramUsdt = () => {
  const { account } = useWeb3React();
  const matrixInfo = useSelector(getMatrixB);

  const allActiveLvls = useMemo(() => {
    return (
      !!matrixInfo?.levels2 &&
      matrixInfo?.levels2.filter((item) => {
        if (Number(item?.activationTimes) !== 0) {
          return item;
        }
      })
    );
  }, [matrixInfo]);

  const nextLvlToActivate =
    allActiveLvls.length < PROGRAM_MAX_LEVELS?.[PROGRAM_NAMES.MATRIX_USDT] ? allActiveLvls.length + 1 : null;

  const renderContent = useMemo(() => {
    if (matrixInfo?.loading) {
      return <LoadingAnimation />;
    } else {
      return (
        <>
          {/* <UsdtMatrix /> */}
          <div className="flex flex-col items-start rounded-large sm:rounded-3xl instrument-main-bg border-[1px] border-white-100">
            {!!allActiveLvls &&
              allActiveLvls?.map((item, itemIndex) => {
                return <ActiveLvl key={itemIndex} {...item} matrixs={item.matrixs} />;
              })}
            {nextLvlToActivate && <LevelActivating nextLvl={nextLvlToActivate} />}
          </div>
          {nextLvlToActivate && (
            <NonActiveLvlList
              program={PROGRAM_NAMES.MATRIX_USDT}
              activeLvls={allActiveLvls}
              nextLvl={nextLvlToActivate}
            />
          )}
        </>
      );
    }
  }, [allActiveLvls, matrixInfo]);

  const { openedModal, onClose, onOpen } = useModal();

  return (
    <BaseLayout>
      <div className="flex items-center justify-between space-x-6 pb-6 relative sm:flex-col sm:space-x-0 sm:space-y-4 sm:items-start">
        <BreadCrumbs className="sm:hidden" title="Qornex USDT">
          <button
            onClick={() => onOpen()}
            className="flex-shrink-0 flex items-center justify-center bg-white-70 rounded-xl w-[48px] h-[48px]"
          >
            <img src="/icons/programs/usdt/settingIcon.svg" />
          </button>
        </BreadCrumbs>
      </div>
      {renderContent}
      <img className="absolute right-0 z-[-1] top-0 sm:hidden" src="/images/instrument/main/purpleShadow.png" />
      <img className="absolute left-[8%]  z-[-1] top-0 sm:hidden" src="/images/instrument/main/yellowShadow.png" />
      <OutputSettingsModal openedModal={openedModal} onClose={onClose} />
    </BaseLayout>
  );
};
