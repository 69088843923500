import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_TOTAL_STATS, GET_TOTAL_STATS_USDT } from '../../helpers/graphRequests';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { bigIntToInt } from '../../helpers/numbers';
import { getUnixTime } from 'date-fns';

export const Activity = () => {
  const [callRequest, { data }] = useLazyQuery(GET_TOTAL_STATS, { fetchPolicy: 'cache-and-network' });
  const [callRequestUsdt, { data: dataUsdt }] = useLazyQuery(GET_TOTAL_STATS_USDT, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    callRequest();
    callRequestUsdt();
  }, []);

  const todayValue = (value, day, isBigInt) => {
    let isTodayReward = Number(day) === Math.floor(getUnixTime(new Date()) / (60 * 60 * 24));
    let temp = isTodayReward ? (isBigInt ? bigIntToInt(value) || 0 : value) : 0;

    return temp;
  };

  const activityList = useMemo(
    () => [
      {
        title: 'Total Users',
        total: (data?.stats[0]?.totalUsers || 0) + (data?.stats[0]?.totalUsers24 || 0),
        oneDay: todayValue(data?.stats[0]?.totalUsers24, data?.stats[0]?.lastUsers24Day, false),
      },
      {
        title: 'Total USDT',
        total:
          (bigIntToInt(dataUsdt?.stat2S[0]?.totalTokens) || 0) + (bigIntToInt(dataUsdt?.stat2S[0]?.totalTokens24) || 0),
        oneDay: todayValue(dataUsdt?.stat2S?.[0]?.totalTokens24, dataUsdt?.stat2S[0]?.lastTokens24Day, true),
      },
      {
        title: 'Total FRGX',
        total: (bigIntToInt(data?.stats[0]?.totalTokens) || 0) + (bigIntToInt(data?.stats[0]?.totalTokens24) || 0),
        oneDay: todayValue(data?.stats[0]?.totalTokens24, data?.stats[0]?.lastTokens24Day, true),
      },
      {
        title: 'Total Burned FRGX',
        total: (bigIntToInt(data?.stats[0]?.totalBurned) || 0) + (bigIntToInt(data?.stats[0]?.totalBurned24) || 0),
        oneDay: todayValue(data?.stats[0]?.totalBurned24, data?.stats[0]?.lastBurned24Day, true),
      },
    ],
    [data, dataUsdt],
  );

  return (
    <div className="grid grid-cols-2 sm:grid-cols-1 gap-x-0 gap-y-[30px] sm:gap-x-3 sm:gap-y-3 items-center justify-around activity-bg rounded-3xl w-full min-h-[142px] sm:w-full col-span-2 row-span-1 sm:h-full sm:flex-col p-6 ">
      {activityList?.map((item, itemIndex) => {
        return (
          <div
            className="flex flex-col items-center justify-center space-y-0 sm:items-start activity-shadow sm:w-full sm:bg-white-30 sm:px-6 sm:py-4 sm:rounded-2xl"
            key={itemIndex}
          >
            <span className="text-white-500 text-sm">{item?.title}</span>
            <div className="flex flex-col items-center space-y-0 sm:flex-row sm:items-center sm:justify-between sm:w-full">
              <h1 className="poppins text-white font-semibold sm:text-[36px]">{item?.total?.toFixed()}</h1>
              <span className="text-base text-primary-500">+{item?.oneDay?.toFixed()}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
