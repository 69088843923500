export const LEVEL_STATUSES = {
  GREEN: 'green',
  ORANGE: 'orange',
  RED: 'red',
};

export const LEVEL_STATUSES_COLOURS = {
  [LEVEL_STATUSES.GREEN]: {
    bg: 'bg-primary-500',
    text: 'text-primary-500',
  },
  [LEVEL_STATUSES.ORANGE]: {
    bg: 'bg-orange',
    text: 'text-orange',
  },
  [LEVEL_STATUSES.RED]: {
    bg: 'bg-errorPink',
    text: 'text-errorPink',
  },
};

export const levelPriceList = [
  {
    lvl: 1,
    price: 10,
    days: 13,
    tokenCounts: 20,
    isActive: true,
  },
  {
    lvl: 2,
    price: 20,
    days: 16,
    tokenCounts: 20,
    isActive: true,
  },
  {
    lvl: 3,
    price: 30,
    days: 17,
    tokenCounts: 20,
    isActive: false,
  },
  {
    lvl: 4,
    price: 40,
    days: 18,
    tokenCounts: 20,
    isActive: false,
  },
  {
    lvl: 5,
    price: 50,
    days: 19,
    tokenCounts: 20,
    isActive: false,
  },
  {
    lvl: 6,
    price: 60,
    days: 20,
    tokenCounts: 20,
    isActive: false,
  },
  {
    lvl: 7,
    price: 70,
    days: 21,
    tokenCounts: 20,
    isActive: false,
  },
];
