import React from 'react';
import { Footer } from 'components';

export const NonAuthLayout = ({ children }) => {
  return (
    <div className="h-full bg-[#080909] relative flex flex-col items-center overflow-hidden">
      <div className="flex flex-col items-center justify-center h-full w-full ">{children}</div>
    </div>
  );
};
