import { BigNumber } from '@ethersproject/bignumber';
import config from './config';

export const MAX_VALUE = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
export const DEFAULT_GAS_LIMIT = BigNumber.from(2000000);

export const startUsdtMatrixTime = config.stand === 'prod' ? '1714575600' : '1714562100';

export const ContractNames = {
  MATRIX_B: 'matrixBase',
  MATRIX_USDT: 'matrixUsdt',
  FRGX: 'frgx',
  USDT: 'usdt',
  ROUTER: 'router',
  STAKING_POOL: 'stakingPool',
  FARMING_POOL: 'farmingPool',
  INTERNAL_ROUTER: 'internalRouter',
  PAYMENT_ROUTER: 'paymentRouter',
  PANCAKE: 'pancake',
};

export const PROGRAM_NAMES = {
  MATRIX_B: 'matrixBase',
  MATRIX_USDT: 'matrixUsdt',
  FRGX: 'frgx',
  USDT: 'usdt',
};

export const CONTRACT_NAMES = {
  MATRIX_B: 'matrixBase',
  MATRIX_USDT: 'matrixUsdt',
  FRGX: 'frgx',
  USDT: 'usdt',
  ROUTER: 'router',
  STAKING_POOL: 'stakingPool',
  FARMING_POOL: 'farmingPool',
  INTERNAL_ROUTER: 'internalRouter',
  PAYMENT_ROUTER: 'paymentRouter',
};

export const nameToAddress = {
  [CONTRACT_NAMES.FRGX]: config.contractFrgx,
  [CONTRACT_NAMES.USDT]: config.contractUsdt,
};

export const nameToType = {
  [PROGRAM_NAMES.MATRIX_B]: CONTRACT_NAMES.MATRIX_B,
  [PROGRAM_NAMES.MATRIX_USDT]: CONTRACT_NAMES.MATRIX_USDT,
};

export const PROGRAM_MAX_LEVELS = {
  [PROGRAM_NAMES.MATRIX_B]: 10,
  [PROGRAM_NAMES.MATRIX_USDT]: 14,
};

export const PROGRAM_CURRENCY = {
  [PROGRAM_NAMES.MATRIX_B]: 'FRGX',
  [PROGRAM_NAMES.MATRIX_USDT]: 'USDT',
};

export const MATRIX_USDT_PRICES = {
  1: 5,
  2: 10,
  3: 15,
  4: 25,
  5: 40,
  6: 60,
  7: 90,
  8: 200,
  9: 400,
  10: 700,
  11: 1000,
  12: 2000,
  13: 4500,
  14: 7000,
};

export const PROGRAM_PRICES = {
  [PROGRAM_NAMES.MATRIX_B]: {
    1: 15,
    2: 25,
    3: 50,
    4: 85,
    5: 160,
    6: 275,
    7: 550,
    8: 1000,
    9: 1750,
    10: 3000,
  },
  [PROGRAM_NAMES.MATRIX_USDT]: MATRIX_USDT_PRICES,
};

export const MATRIX_USDT_СOMMISIONS = {
  1: 0.8,
  2: 1.5,
  3: 2.1,
  4: 3.25,
  5: 4.8,
  6: 6.6,
  7: 9,
  8: 18,
  9: 32,
  10: 49,
  11: 60,
  12: 100,
  13: 180,
  14: 210,
};

export const PROGRAM_COMMISIONS = {
  [PROGRAM_NAMES.MATRIX_B]: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
  },
  [PROGRAM_NAMES.MATRIX_USDT]: MATRIX_USDT_СOMMISIONS,
};

export const PROGRAM_EXPIRED = {
  [PROGRAM_NAMES.MATRIX_B]: {
    1: 14,
    2: 16,
    3: 18,
    4: 22,
    5: 28,
    6: 36,
    7: 46,
    8: 58,
    9: 70,
    10: 82,
  },
};

export const ADD_CHAIN_ETHS_PARAMS = {
  56: {
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com'],
    chainId: '0x38',
  },
  97: {
    chainName: 'Binance Smart Chain Testnet',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-2-s3.binance.org:8545'],
    blockExplorerUrls: ['https://testnet.bscscan.com'],
    chainId: '0x61',
  },
};

export const EXPLORER_URL = ADD_CHAIN_ETHS_PARAMS?.[config.allowedChainId]?.blockExplorerUrls[0];
