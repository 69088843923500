import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Dashboard } from './pages/dashboard';
import { Farming } from './pages/farming';
import { Settings } from './pages/settings/index.';
import { Staking } from './pages/staking';
import { Partners } from './pages/partners';
import { Promo } from './pages/promo';
import { About } from './pages/about';
import { Leaderboard } from './pages/leaderboard';
import { Programs } from './pages/programs';
import { ProgramFrgx } from './pages/programs/ProgramFrgx';
import { ProgramUsdt } from './pages/programs/ProgramUsdt';


export const RoutesFind = () => {
  return (
    <Router>
      <Routes>
        {/* <Route path="" component={Dashboard} /> */}
        <Route path="/" element={<Dashboard />} />
        <Route path="/profile" element={<Settings />} />
        <Route path="/farming" element={<Farming />} />
        <Route path="/staking" element={<Staking />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/promo" element={<Promo />} />
        <Route path="/about" element={<About />} />
        <Route path="/leaderboard" element={<Leaderboard />} />
        <Route path="/programs">
          <Route index path="/programs" element={<Programs />} />
          <Route path="/programs/frgx" element={<ProgramFrgx />} />
          <Route path="/programs/usdt" element={<ProgramUsdt />} />
        </Route>
      </Routes>
    </Router>
  );
};
