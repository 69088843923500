import React from 'react';
import { useMemo } from 'react';
import { Modal } from '../../Modal';
import { EXPLORER_URL } from '../../../helpers/constants';
import { shortenAddress } from '../../../helpers/format';
import { Link } from 'react-router-dom';
import { copy } from '../../../helpers/copy';

export const MatrixUserInfoModal = ({ openedModal, onClose, openedUser }) => {
  const slots = useMemo(() => {
    switch (openedUser?.status) {
      case 'direct_partner':
        return {
          bg: 'matrix-user-modal-bg-direct_partner',
          icon: '/icons/modal/matrixUserInfoModal/direct_partner.svg',
          borderColor: 'border-color-matrix-user-direct_partner',
          shadow: 'direct_partnerShadow.png',
        };
      case 'anotherReceiver':
        return {
          bg: 'matrix-user-modal-bg-anotherReceiver',
          icon: '/icons/modal/matrixUserInfoModal/anotherReceiver.svg',
          borderColor: 'border-color-matrix-user-anotherReceiver',
          shadow: 'anotherReceiverShadow.png',
        };
      case 'recycle':
        return {
          bg: 'matrix-user-modal-bg-recycle',
          icon: '/icons/modal/matrixUserInfoModal/recycle.svg',
          borderColor: 'border-color-matrix-user-recycle',
          shadow: 'recycleShadow.png',
        };
      case 'direct':
        return {
          bg: 'matrix-user-modal-bg-direct',
          icon: '/icons/modal/matrixUserInfoModal/direct.svg',
          borderColor: 'border-color-matrix-user-direct',
          shadow: 'directShadow.png',
        };
      case 'overflow':
        return {
          bg: 'matrix-user-modal-bg-overflow',
          icon: '/icons/modal/matrixUserInfoModal/overflow.svg',
          borderColor: 'border-color-matrix-user-overflow',
          shadow: 'overflowShadow.png',
        };
      case 'reinvest':
        return {
          bg: 'matrix-user-modal-bg-reinvest',
          icon: '/icons/modal/matrixUserInfoModal/reinvest.svg',
          borderColor: 'border-color-matrix-user-reinvest',
          shadow: 'reinvestShadow.png',
        };
      case 'gift':
        return {
          bg: 'matrix-user-modal-bg-gift',
          icon: '/icons/modal/matrixUserInfoModal/gift.svg',
          borderColor: 'border-color-matrix-user-gift',
          shadow: 'giftShadow.png',
        };
      case 'stored':
        return {
          bg: 'matrix-user-modal-bg-stored',
          icon: '/icons/modal/matrixUserInfoModal/stored.svg',
          borderColor: 'border-color-matrix-user-stored',
          shadow: 'storedShadow.png',
        };
    }
  }, [openedUser]);

  const transLink = EXPLORER_URL + '/tx/' + openedUser?.transaction;

  const filterId = (id) => {
    if (id === 1) {
      return 'hub';
    }
    return `ID ${id}`;
  };

  return (
    <Modal isOpened={openedModal} onClose={onClose}>
      <div className="border-[1px] border-white-100 rounded-3xl sm:w-full">
        <div
          className={`flex flex-col justify-end space-y-6 h-[525px] py-8 px-8 w-[376px] rounded-3xl ${slots?.bg} shadow-item backdrop-blur-bold sm:w-full`}
        >
          <div
            className={`absolute left-1/2 -translate-x-1/2 top-0 -translate-y-1/2 w-[110px] h-[110px] rounded-full p-[1px] ${slots?.borderColor}`}
          >
            <div className="flex items-center justify-center w-full h-full bg-[#2E2E2E] rounded-full">
              <img className="absolute " src={`/images/modals/matrixUserInfoModal/${slots?.shadow}`} />
              <img className="w-[33px] h-[35px]" src={slots?.icon} />
            </div>
          </div>
          <div className="flex flex-col space-y-3">
            <div className="flex justify-between items-center px-4 h-[56px] rounded-2xl bg-white-70">
              <span className="text-sm text-white-500 font-light">User id</span>
              <Link to={`/?previewUser=${openedUser?.user?.internalId}`} className="text-white">{filterId(openedUser?.user?.internalId)}</Link>
            </div>
            <div className="flex justify-between items-center px-4 h-[56px] rounded-2xl bg-white-70">
              <span className="text-sm text-white-500 font-light">{filterId(openedUser?.user?.internalId)} wallet</span>
              <div className="flex items-center justify-end space-x-1.5">
                <span className="text-white">{shortenAddress(openedUser?.user?.id, 4)}</span>
                <button className="p-1  bg-white-70 rounded-lg shrink-0" onClick={() => copy(openedUser?.user?.id)}>
                  <img src="/icons/partners/copyIcon.svg" />
                </button>
              </div>
            </div>
            <div className="flex justify-between items-center px-4 h-[56px] rounded-2xl bg-white-70">
              <span className="text-sm text-white-500 font-light">Upline</span>
              <Link to={`/?previewUser=${openedUser?.receiver?.internalId}`} className="text-white">{filterId(openedUser?.receiver?.internalId)}</Link>
            </div>
            {openedUser?.status === 'recycle' || openedUser?.status === 'stored' ? (
              <div className="flex justify-between items-center px-4 h-[56px] rounded-2xl bg-white-70">
                <span className="text-sm text-white-500 font-light">Receiver</span>
                <span className="text-white">Stored</span>
              </div>
            ) : (
              <div className="flex justify-between items-center px-4 h-[56px] rounded-2xl bg-white-70">
                <span className="text-sm text-white-500 font-light">User receiver</span>
                <Link to={`/?previewUser=${openedUser?.realReceiver?.internalId}`} className="text-white">{filterId(openedUser?.realReceiver?.internalId)}</Link>
              </div>
            )}
          </div>
          <div className="flex flex-col w-full space-y-2.5">
            <Link
              to={`/?previewUser=${openedUser?.user?.internalId}`}
              className="flex items-center justify-between pl-4 pr-2 h-[56px] bg-white-70 w-full rounded-2xl"
            >
              <span className="text-base poppins text-white font-medium">Open profile</span>
            </Link>
            <div className="flex items-center justify-between pl-4 pr-2 h-[56px] bg-white-70 w-full rounded-2xl">
              <span className="text-base poppins text-white font-medium">Transaction</span>
              <a
                href={transLink}
                target="_blank"
                rel="noreferrer"
                className="flex items-center justify-center bg-white-70 rounded-xl p-2 backdrop-blur-large shadow-item"
              >
                <img src="/icons/modal/matrixUserInfoModal/link.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
