import React from 'react';

export const Partners = () => {
  const partnersList = [
    {
      logo: 'bnbChain',
    },
    {
      logo: 'PS',
    },
    {
      logo: 'MMPro',
    },
    {
      logo: 'dexTools',
    },
    {
      logo: 'madApes',
    },
    {
      logo: 'meoWorld',
    },
    {
      logo: 'dexView',
    },
    {
      logo: 'dexScreener',
    },
    {
      logo: 'coinBrain',
    },
    {
      logo: 'coinMarketCap',
    },
  ];
  return (
    <div className="flex items-center pt-[200px] flex flex-col sm:px-5 sm:pt-[80px] z-[1]">
      <h2 className="text-center text-[54px] text-white font-semibold sm:text-[40px]">Trusted by</h2>
      <div className="grid gap-6 grid-cols-4 grid-rows-3 mt-[80px] justify-center items-center sm:grid-cols-2 sm:grid-rows-3 sm:gap-8 sm:mt-[40px] sm:w-full">
        {partnersList?.map((item, itemIndex) => {
          return (
            <a
              target="_blank"
              key={itemIndex}
              href={item?.link}
              className="flex items-center justify-center rounded-[54px] w-[288px] h-[70px] hover:bg-white-30 cursor-pointer grayscale sm:grayscale-0 hover:grayscale-0 sm:hover:bg-transparent  sm:w-[158px] sm:h-[38px]"
              rel="noreferrer"
            >
              <img alt="partnersImage" className="sm:w-full mx-auto" src={`/icons/about/partners/${item?.logo}.svg`} />
            </a>
          );
        })}
      </div>
    </div>
  );
};
