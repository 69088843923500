import React, { useState } from 'react';
import { useGetContract } from './useGetContract';
import { DEFAULT_GAS_LIMIT, ContractNames } from '../constants';
import { getAddress } from '../format';
import { toWei } from '../numbers';
import { realNumberToTransactionalNumber } from '../format';
import { increaseByPercent } from '../numbers';

export const useRegistration = () => {
  const [isLoadingRegistration, setIsLoadingRegistration] = useState(false);
  const { getContract } = useGetContract();

  const registration = async (uplineData, currency, finalPrice) => {
    const regNumber = getAddress(uplineData?.data) ? 0 : uplineData?.data;
    const regAddress = getAddress(uplineData?.data) ? uplineData?.data : '0x0000000000000000000000000000000000000000';

    if (!isLoadingRegistration) {
      setIsLoadingRegistration(true);
      if (currency === 'bnb') {
        try {
          const contract = await getContract(ContractNames.PAYMENT_ROUTER);
          let gas = null;
          try {
            gas = await contract.estimateGas.qornex2RegistrationBNB(regNumber, regAddress, {
              value: toWei(finalPrice),
            });
          } catch (e) {
            //
          }

          return await contract.qornex2RegistrationBNB(regNumber, regAddress, {
            value: toWei(finalPrice),
            gasLimit: parseInt(gas) ? increaseByPercent(gas) : DEFAULT_GAS_LIMIT,
          });
        } catch (e) {
          console.log(e);
          return Promise.reject(e);
        } finally {
          setIsLoadingRegistration(false);
        }
      } else if (currency === 'frgx') {
        try {
          const contract = await getContract(ContractNames.PAYMENT_ROUTER);
          const bigNumberValue = realNumberToTransactionalNumber(finalPrice);
          let gas = null;
          try {
            gas = await contract.estimateGas.qornex2RegistrationFRGX(bigNumberValue, regNumber, regAddress);
          } catch (e) {
            //
          }
  
          return await contract.qornex2RegistrationFRGX(bigNumberValue, regNumber, regAddress, {
            gasLimit: parseInt(gas) ? increaseByPercent(gas) : DEFAULT_GAS_LIMIT,
          });
        } catch (e) {
          console.log(e);
          return Promise.reject(e);
        } finally {
          setIsLoadingRegistration(false);
        }
      } else {
        try {
          const bigNumberValue = realNumberToTransactionalNumber(finalPrice);
          const contract = await getContract(ContractNames.PAYMENT_ROUTER);
          let gas = null;
          try {
            gas = await contract.estimateGas.qornex2RegistrationUSDT(regNumber, regAddress);
          } catch (e) {
            //
          }
  
          return await contract.qornex2RegistrationUSDT(regNumber, regAddress, {
            gasLimit: parseInt(gas) ? increaseByPercent(gas) : DEFAULT_GAS_LIMIT,
          });
        } catch (e) {
          console.log(e);
          return Promise.reject(e);
        } finally {
          setIsLoadingRegistration(false);
        }
      }
    }
  };

  const registrationOld = async (uplineData) => {
    if (!isLoadingRegistration) {
      setIsLoadingRegistration(true);

      const isAddress = !!getAddress(uplineData?.data);

      if (isAddress) {
        try {
          const contract = await getContract(ContractNames.MATRIX_B);

          let gas = null;
          try {
            gas = await contract.estimateGas.registration(uplineData?.data);
          } catch (e) {
            //
          }

          return await contract.registration(uplineData?.data, {
            gasLimit: parseInt(gas) ? increaseByPercent(gas) : DEFAULT_GAS_LIMIT,
          });
        } catch (e) {
          console.log(e);
          return Promise.reject(e);
        } finally {
          setIsLoadingRegistration(false);
        }
      } else {
        try {
          const contract = await getContract(ContractNames.MATRIX_B);
          const upline = !isNaN(uplineData?.data) ? uplineData?.data : 0;

          let gas = null;
          try {
            gas = await contract.estimateGas.registrationRefNumber(upline);
          } catch (e) {
            //
          }

          return await contract.registrationRefNumber(upline, {
            gasLimit: parseInt(gas) ? increaseByPercent(gas) : DEFAULT_GAS_LIMIT,
          });
        } catch (e) {
          console.log(e);
          return Promise.reject(e);
        } finally {
          setIsLoadingRegistration(false);
        }
      }
    }
  };

  return {
    registration,
    registrationOld,
    isLoadingRegistration,
  };
};
