export const contractPaymentRouterAbi = [
	{
		"stateMutability": "payable",
		"type": "fallback"
	},
	{
		"inputs": [
			{
				"internalType": "uint8[]",
				"name": "_levels",
				"type": "uint8[]"
			}
		],
		"name": "buyLevelBatch",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractOwner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "frgx",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "impl",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "contract IQornex",
				"name": "_qornex",
				"type": "address"
			}
		],
		"name": "init",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "contract IQornex2",
				"name": "_qornex2",
				"type": "address"
			}
		],
		"name": "initQornex2",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pancakeRouter",
		"outputs": [
			{
				"internalType": "contract IPancakeRouter",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "qornex",
		"outputs": [
			{
				"internalType": "contract IQornex",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "qornex2",
		"outputs": [
			{
				"internalType": "contract IQornex2",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "paymentAddress",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "userAddress",
				"type": "address"
			},
			{
				"internalType": "uint8[]",
				"name": "_levels",
				"type": "uint8[]"
			},
			{
				"internalType": "uint256",
				"name": "amountInMaxForFrgxOnly",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "bnbFrgxRatio",
				"type": "uint256"
			}
		],
		"name": "qornex2BuyLevelBatch",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint32",
				"name": "_refNumber",
				"type": "uint32"
			},
			{
				"internalType": "address",
				"name": "_referrerAddress",
				"type": "address"
			}
		],
		"name": "qornex2RegistrationBNB",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amountInMax",
				"type": "uint256"
			},
			{
				"internalType": "uint32",
				"name": "_refNumber",
				"type": "uint32"
			},
			{
				"internalType": "address",
				"name": "_referrerAddress",
				"type": "address"
			}
		],
		"name": "qornex2RegistrationFRGX",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint32",
				"name": "_refNumber",
				"type": "uint32"
			},
			{
				"internalType": "address",
				"name": "_referrerAddress",
				"type": "address"
			}
		],
		"name": "qornex2RegistrationUSDT",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "referrerAddress",
				"type": "address"
			}
		],
		"name": "registrationRefAddress",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint32",
				"name": "referrerId",
				"type": "uint32"
			}
		],
		"name": "registrationRefNumber",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "usdt",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "wbnb",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"stateMutability": "payable",
		"type": "receive"
	}
];