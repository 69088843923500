export const menuList = [
  {
    title: 'Dashboard',
    href: '/',
    icon: '/icons/leftBar/menuIcons/grayIcons/dashboard.svg',
    activeIcon: '/icons/leftBar/menuIcons/yellowIcons/dashboard.svg',
    mobIcon: '/icons/mobileMenuIcons/dashboard.svg',
    key: '',
  },
  {
    title: 'Programs',
    href: '/programs',
    icon: '/icons/leftBar/menuIcons/grayIcons/instruments.svg',
    activeIcon: '/icons/leftBar/menuIcons/yellowIcons/instruments.svg',
    mobIcon: '/icons/mobileMenuIcons/instruments.svg',
    key: 'programs',
  },
  {
    title: 'Your Partners',
    href: '/partners',
    icon: '/icons/leftBar/menuIcons/grayIcons/partners.svg',
    activeIcon: '/icons/leftBar/menuIcons/yellowIcons/partners.svg',
    key: 'partners',
  },
  {
    title: 'Staking',
    href: '/staking',
    icon: '/icons/leftBar/menuIcons/grayIcons/staking.svg',
    activeIcon: '/icons/leftBar/menuIcons/yellowIcons/staking.svg',
    mobIcon: '/icons/mobileMenuIcons/staking.svg',
    key: 'staking',
  },
  {
    title: 'Farming',
    href: '/farming',
    icon: '/icons/leftBar/menuIcons/grayIcons/farm.svg',
    activeIcon: '/icons/leftBar/menuIcons/yellowIcons/farm.svg',
    mobIcon: '/icons/mobileMenuIcons/farm.svg',
    key: 'farming',
  },
  {
    title: 'Promo',
    href: '/promo',
    icon: '/icons/leftBar/menuIcons/grayIcons/promo.svg',
    activeIcon: '/icons/leftBar/menuIcons/yellowIcons/promo.svg',
    key: 'promo',
  },
  {
    title: 'Profile',
    href: '/profile',
    icon: '/icons/leftBar/menuIcons/grayIcons/settings.svg',
    activeIcon: '/icons/leftBar/menuIcons/yellowIcons/settings.svg',
    mobIcon: '/icons/mobileMenuIcons/menu.svg',
    mobIconClose: '/icons/mobileMenuIcons/menuCloseArrow.svg',
    bg: '!bg-primary-500 !rounded-[20px] !mobMenu-yellow-shadow !h-[60px] !w-[60px]',
    key: 'profile',
  },
];
