import React, { useMemo, useState } from 'react';
import { Main } from './components/main';
import { Statics } from './components/statics';
import { useSelector } from 'react-redux';
import { getMatrixB } from '../../store/matrixBSlice/selectors';
import { bigIntToInt } from '../../helpers/numbers';
import { Timer } from './components/timer';

export const ProgramCard = ({ programType }) => {
  const [isCompleteTimer, setIsCompleteTimer] = useState(programType === 'usdt' ? false : true);

  const matrixInfo = useSelector(getMatrixB);
  const [currentTab, setCurrentTab] = useState(0);

  const switchInfo = [
    {
      svg: 'programIcon',
    },
    {
      svg: 'staticIcon',
    },
  ];

  const matrixProfit =
    programType === 'usdt'
      ? !!matrixInfo?.levels2 &&
        matrixInfo?.levels2?.reduce((result, item) => {
          let totalReward = bigIntToInt(item?.totalReward) || 0;
          return result + totalReward;
        }, 0)
      : !!matrixInfo?.levels &&
        matrixInfo?.levels?.reduce((result, item) => {
          let totalReward = bigIntToInt(item?.totalReward) || 0;
          return result + totalReward;
        }, 0);

  if (!isCompleteTimer) {
    return <Timer setIsComplete={setIsCompleteTimer} />;
  }

  return (
    <div className="relative max-w-[546px] w-full sm:max-w-full ">
      {!currentTab ? (
        <Main matrixProfit={matrixProfit} programType={programType} />
      ) : (
        <Statics matrixProfit={matrixProfit} programType={programType} />
      )}
      <div
        className={`flex space-x-1 ${
          currentTab ? `${programType === 'usdt' ? 'bg-black-300' : 'bg-white-200'}` : 'bg-white-100'
        } w-[60px] h-[32px] rounded-lg p-1 absolute top-6 right-6 z-[11]`}
      >
        <div onClick={() => setCurrentTab(currentTab === 0 ? 1 : 0)} className="flex space-x-1 w-full">
          {switchInfo.map((item, itemIndex) => {
            const isActive = itemIndex === currentTab;
            return (
              <button
                className={`flex items-center  ${
                  isActive ? `${programType === 'usdt' ? 'bg-[#50AF95]' : 'bg-[#6850F3]'}` : ''
                }
                justify-center h-full w-full  rounded-md`}
                key={itemIndex}
              >
                <img src={`/icons/instruments/${item?.svg}.svg`} />
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
