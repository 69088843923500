import React from 'react';
import { CompletePlace } from './Components/CompletePlace';
import { EmptyPlace } from './Components/EmptyPlace';

export const UsdtMatrixItem = ({ setCurrentUser, value, firstly = false }) => {
  if (value) {
    return <CompletePlace setCurrentUser={setCurrentUser} value={value} firstly={firstly} />;
  }
  return <EmptyPlace firstly={firstly} />;
};
