import React, { useMemo, useState } from 'react';
import { Modal } from '../../Modal';

export const MatrixLevelInfoModal = ({ levelNumber = 1, missedInfo, openedModal, onClose }) => {
  const infoList = [
    {
      key: 'missedProfit',
      title: 'Missed profit',
      value: `${missedInfo?.missedProfit || 0} USDT`,
    },
    {
      key: 'missedPartners',
      title: 'Missed partners',
      value: missedInfo?.missedPartners || 0,
    },
  ];

  return (
    <Modal isOpened={openedModal} onClose={onClose} withoutClose={true}>
      <div className="flex flex-col justify-between w-[464px] h-[250px] sm:h-[275px]  rounded-3xl bg-lightBlack backdrop-blur-bold p-8 border-[1px] border-white-100">
        <h2 className="text-white poppins font-semibold">Level {levelNumber} info</h2>
        <div className="w-full flex-1 flex flex-col justify-end sm:justify-center items-center space-y-2.5">
          {infoList.map((item, itemIndex) => {
            return (
              <div
                className="flex items-center justify-between w-full p-2.5 text-white bg-white-50 rounded-[16px]"
                key={item?.key}
              >
                <div className="flex items-center justify-start space-x-2.5">
                  <img className="h-[30px] w-[30px]" src={`/icons/instrument/${item?.key}.svg`} alt={item?.key} />
                  <span className="text-[18px] font-semibold">{item?.title}</span>
                </div>
                <div className="flex items-center justify-end">
                  <span className="text-lg font-bold">{item?.value}</span>
                </div>
              </div>
            );
          })}
        </div>
        <button onClick={() => onClose()} className="absolute right-8 rounded-full top-8 bg-white-70">
          <img className="" src="/icons/closeIcon.svg" />
        </button>
      </div>
    </Modal>
  );
};
