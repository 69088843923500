import React, { useState } from 'react';

import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

export const FaqItem = ({ title, desc }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <AccordionItem className="border-[1px] border-white-100  rounded-3xl ">
      <AccordionItemHeading className="">
        <AccordionItemButton className="flex items-center justify-between ">
          <button
            onClick={() => setIsActive(!isActive)}
            className="flex items-center justify-between w-full space-x-2.5 p-6"
          >
            <h3 className="text-lg poppins text-white sm:text-base text-left font-medium">{title}</h3>
            <img
              alt=""
              className={`w-[24px] h-[24px] sm:w-[20px] sm:h-[20px] transition-all ease-out ${
                isActive ? 'rotate-45' : 'rotate-0'
              }`}
              src="/icons/about/faqList/cross.svg"
            />
          </button>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel className="px-6 pb-6 w-[595px] sm:w-full">
        <span className="text-[#828282] font-light inter sm:text-[13px]">{desc}</span>
      </AccordionItemPanel>
    </AccordionItem>
  );
};
