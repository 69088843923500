import React from 'react';
import { PROGRAM_NAMES, PROGRAM_PRICES, PROGRAM_EXPIRED } from '../../../helpers/constants';

export const Marketing = () => {
  return (
    <div className="flex flex-col items-center  pt-[200px] sm:pt-20 relative w-full sm:px-4">
      <div className="flex flex-col items-center space-y-8 relative pb-[160px] w-full sm:pb-10">
        <span className="text-[54px] text-white poppins font-semibold text-center sm:text-h2">
          How Qornex <br className="hidden sm:flex" /> <span className="about-title">blockchain marketing</span> <br />{' '}
          <span className="about-title">solutions</span> work
        </span>
        <span className="leading-8 text-white-500 font-light text-center max-w-[500px] sm:max-w-full">
          The financial instruments framework is based on a sequence of levels that can be activated for a certain
          period of time. They can be opened consecutively from the first to the last.
        </span>
        <img className="absolute bottom-[15.5px] w-full sm:hidden" src="/images/about/advantages/yellowArrow.png" />
      </div>
      <div className="flex items-center justify-center w-full">
        <div className="grid grid-cols-4 grid-rows-3 gap-3 sm:grid-cols-1 justify-center max-w-[1115px] sm:max-w-full sm:w-full">
          {Object.values(PROGRAM_PRICES?.[PROGRAM_NAMES.MATRIX_B])?.map((item, itemIndex) => {
            return (
              <div
                className={`${
                  itemIndex === 8 ? 'col-start-2 sm:col-start-1' : ''
                } flex items-center space-x-4 pl-[17px] w-[265px] sm:w-full h-[63px] bg-white rounded-2xl border-[0.5px] border-white-100 backdrop-blur-medium marketing-levels-bg relative`}
              >
                <div className="flex items-center justify-center">
                  <img className="w-10 h-10" src="/icons/instrument/star.svg" />
                  <span className="absolute font-semibold text-[10px] poppins text-black">Lvl {itemIndex + 1}</span>
                </div>
                <span className="poppins text-lg text-white font-semibold">{item} FRGX </span>
                <div className="absolute flex items-center space-x-0.5 top-[9px] right-[9px]">
                  <span className="poppins text-[10px] font-semibold text-white-500">1x</span>
                  <div className="flex items-center justify-center bg-white-70 rounded-full w-[55px] h-[20px]">
                    <span className="text-[#47FF91] text-[10px]">
                      {PROGRAM_EXPIRED?.[PROGRAM_NAMES.MATRIX_B]?.[itemIndex + 1]} days
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  // grid grid-cols-4 gap-3 sm:grid-cols-1
};
