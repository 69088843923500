import { React, useState, useEffect, useMemo } from 'react';
import { Modal } from '../../Modal';
import { useCallTransaction } from '../../../helpers/hooks/useCallTransaction';
import { EXPLORER_URL } from '../../../helpers/constants';
import Spline from '@splinetool/react-spline';
import { useWeb3React } from '@web3-react/core';
import { useGetContract } from '../../../helpers/hooks/useGetContract';
import { CONTRACT_NAMES } from '../../../helpers/constants';
import { fromWei, toWei } from '../../../helpers/numbers';
import { increaseByPercent } from '../../../helpers/numbers';
import { DEFAULT_GAS_LIMIT } from '../../../helpers/constants';
import { TransactionStatuses } from '../../TransactionStatuses';
import { useFrgxBnbPriceByAmount } from '../../../helpers/hooks/useFrgxBnbPriceByAmount';

export const BuyStakingBoostModal = ({ openedModal, onClose, index, fetchStakingInfo }) => {
  const { account } = useWeb3React();
  const { getContract } = useGetContract();
  const [boostPrice, setBoostPrice] = useState(null);

  const { frgxPrice, frgxPriceBase, frgxPriceByAmountIsLoading, getFrgxPriceByAmount } = useFrgxBnbPriceByAmount();

  useEffect(() => {
    if (account) {
      getFrgxPriceByAmount(boostPrice, 0, boostPrice);
    }
  }, [boostPrice, account]);

  const checkBoostPrice = async () => {
    try {
      const contract = await getContract(CONTRACT_NAMES.STAKING_POOL);

      const calcboostPrice = await contract.getBoostPrice(account, index);
      setBoostPrice(fromWei(calcboostPrice));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (account) {
      checkBoostPrice();
    }
  }, [account]);

  console.log(boostPrice, `frgx price ${frgxPrice * 0.97}`)

  const buyBoost = async () => {
    if (boostPrice && frgxPrice) {
      try {
        const contract = await getContract(CONTRACT_NAMES.STAKING_POOL);
        let gas;
        let result;
        try {
          gas = await contract.estimateGas.buyBoost(account, index, toWei(frgxPrice * 0.97), {
            value: toWei(boostPrice),
          });
        } catch (e) {
          //
        }

        result = await contract.buyBoost(account, index, toWei(frgxPrice * 0.97), {
          value: toWei(boostPrice),
          gasLimit: parseInt(gas) ? increaseByPercent(gas) : DEFAULT_GAS_LIMIT,
        });

        onCallTransaction(result);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const { onCallTransaction, transactionInfo, resetTransactionInfo } = useCallTransaction();

  const renderButton = useMemo(() => {
    if (transactionInfo?.hash) {
      let hash = EXPLORER_URL + '/tx/' + transactionInfo?.hash;
      return (
        <a
          href={hash}
          target="_blank"
          rel="noreferrer"
          className="flex items-center justify-center text-white-300 poppins font-medium rounded-[16px] bg-white-50 py-5 w-full"
        >
          Explore transaction
        </a>
      );
    }
    if (!boostPrice || frgxPriceByAmountIsLoading) {
      return (
        <button className="flex-shrink-0 buyBoost-gradient rounded-[16px] w-full text-white h-[62px] font-semibold space-x-1.5">
          Loading
        </button>
      );
    }
    return (
      <button
        onClick={buyBoost}
        className="flex-shrink-0 buyBoost-gradient rounded-[16px] w-full text-white h-[62px] font-semibold space-x-1.5"
      >
        <span>Buy boost</span>
        <span>{boostPrice} BNB</span>
      </button>
    );
  }, [transactionInfo, boostPrice, frgxPriceByAmountIsLoading]);

  useEffect(() => {
    if (transactionInfo.isSuccess) {
      fetchStakingInfo();
    }
  }, [transactionInfo]);

  const statusStyle = () => {
    if (transactionInfo?.isSuccess) {
      return {
        bg: 'activateModalBg_success',
        icon: '/icons/transactions/modal/successStar.svg',
      };
    }
    if (transactionInfo?.isError) {
      return {
        bg: 'activateModalBg_error',
        icon: '/icons/transactions/modal/errorStar.svg',
      };
    }
    return {
      icon: '/icons/transactions/modal/usdtStar.svg',
    };
  };

  const renderContent = useMemo(() => {
    if (transactionInfo?.hash) {
      return (
        <div className="w-full h-full flex items-center justify-center">
          <TransactionStatuses transactionInfo={transactionInfo} />
        </div>
      );
    } else {
      return (
        <>
          <div className="max-h-[250px] h-full">
            <Spline scene="https://prod.spline.design/K2dCRf8qtiTs2bTb/scene.splinecode" />
          </div>
          <div className="flex flex-col items-center text-center space-y-0.5 max-w-[260px]">
            <span className="text-[32px] font-semibold font-poppins text-white ">Boost your APY</span>
            <span className="text-white-500">Your can activate boost and add 16% percent for your APY </span>
          </div>
        </>
      );
    }
  }, [transactionInfo]);

  const closeModal = () => {
    resetTransactionInfo();
    fetchStakingInfo();
    onClose();
  }

  return (
    <Modal isOpened={openedModal} onClose={closeModal}>
      <div
        className={`relative overflow-hidden flex p-8 flex-col space-y-5 shadow-item backdrop-blur-bold bg-lightGray-300 h-[500px] sm:h-[600px] w-[376px] sm:w-full ${
          statusStyle()?.bg
        } items-start justify-start rounded-3xl space-y-11 sm:w-full sm:p-0 sm:px-4 sm:rounded-none sm:rounded-t-3xl sm:justify-end sm:pb-[121px] sm:space-y-2.5`}
      >
        <img className="absolute top-0 left-0 w-full z-0" src="/images/staking/buyBgBlur.png" alt="" />
        <div className="z-[3] flex items-center justify-center flex-col flex-1 w-full">{renderContent}</div>
        {renderButton}
      </div>
    </Modal>
  );
};
