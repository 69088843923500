import React from 'react';

export const EmptyPlace = ({ firstly }) => {
  const placeBg = {
    backgroundImage: `url(/images/programs/usdt/emptyPlace.png)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };
  return (
    <div
      style={placeBg}
      className={`flex items-center justify-center ${
        firstly ? 'w-[174px] h-[194px] sm:w-[74px] sm:h-[83px]' : 'w-[80px] h-[89px] sm:w-[34px] sm:h-[38px]'
      } `}
    >
      <img
        className={firstly ? 'w-9 h-9 sm:w-6 sm:h-6' : 'w-7 h-7 sm:w-4 sm:h-4'}
        src="/icons/programs/usdt/profile.svg"
      />
    </div>
  );
};
