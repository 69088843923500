import React from 'react';

export const Apy = () => {
  return (
    <div className="flex items-center justify-between pt-[200px] w-full max-w-[1211px] sm:pt-20 sm:px-4 sm:flex-col">
      <img className="" src="/images/about/apy/apy.png" />
      <div className="flex flex-col space-y-8 max-w-[530px] sm:space-y-6 sm:max-w-full">
        <span className="text-[54px] text-white poppins font-semibold text-left sm:text-h2">
          Annual Percentage <span className="about-title">Yield formula APY</span>
        </span>
        <div className="flex items-center justify-center staking-table-bg min-h-[104px] sm:min-h-[80px] w-full rounded-3xl">
          <h1 className="flex items-center justify-center text-white font-bold poppins sm:text-h2">
            APY <span className="text-xl font-normal px-3 sm:text-lg">=</span> Y/X{' '}
            <span className="text-xl font-normal px-3 sm:text-lg">x</span> 100%{' '}
            <span className="text-xl font-normal px-3 sm:text-lg">x</span> A
          </h1>
        </div>
        <div className="flex flex-col space-y-1">
          <div className="flex items-center space-x-1">
            <span className="font-bold text-lg text-white">Y</span>
            <span className="text-sm text-sm text-white">-</span>
            <span className="text-sm text-sm text-white">Accumulation pool volume</span>
          </div>
          <div className="flex items-center space-x-1">
            <span className="font-bold text-lg text-white">X</span>
            <span className="text-sm text-sm text-white">-</span>
            <span className="text-sm text-sm text-white">Volume of participants' staked assets</span>
          </div>
          <div className="flex items-center space-x-1">
            <span className="font-bold text-lg text-white">A</span>
            <span className="text-sm text-sm text-white">-</span>
            <span className="text-sm text-sm text-white">
              Qornex Staking Factor, which is determined at the end of the week.
            </span>
          </div>
        </div>
        <span className="text-white-500 leading-8 font-light">
          Yield calculation is based only on the number of FRGX in staking. There is no compound interest.
        </span>
      </div>
    </div>
  );
};
