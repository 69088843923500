import React from 'react';
import { useModal } from '../../../helpers/hooks/useModal';
import { ActivateLvlUsdtModal } from '../../../components/Modals/ActivateLvlUsdtModal';
import { PROGRAM_NAMES, PROGRAM_PRICES } from '../../../helpers/constants';
import { MAIN_BG_COLOR, NEXT_ACTIVATE_BG_CLASS } from '../../../helpers/program';
import { useCheckPreviewMode } from '../../../helpers/hooks/useCheckPreviewMode';

export const LevelActivating = ({ nextLvl = 1 }) => {
  const { openedModal, onOpen, onClose } = useModal();
  const { isPreviewMode } = useCheckPreviewMode();

  return (
    <>
      <div
        className={`flex bg-[#1D1D1E] w-full items-center justify-center py-[30px] rounded-large sm:rounded-3xl sm:h-fit sm:pt-6 sm:pb-[26px] sm:px-3 ${
          NEXT_ACTIVATE_BG_CLASS?.[PROGRAM_NAMES.MATRIX_USDT]
        } `}
      >
        <div className="flex flex-col space-y-3 sm:w-full">
          <div className="flex items-center justify-center bg-lightGray rounded-2xl h-[56px] text-white space-x-2.5 shadow-item sm:w-full sm:backdrop-blur-large">
            <span className="text-white-500">Price:</span>
            <span className="font-semibold">{PROGRAM_PRICES?.[PROGRAM_NAMES.MATRIX_USDT][nextLvl]} USDT</span>
            <img className="w-5 h-5" src="/icons/dashboard/tokenPurchase/usdt.svg" alt="" />
          </div>
          <button
            disabled={isPreviewMode}
            onClick={() => onOpen()}
            className={`flex items-center justify-center ${
              MAIN_BG_COLOR?.[PROGRAM_NAMES.MATRIX_USDT]
            } text-white rounded-2xl h-[62px] w-[351px] shadow-item sm:w-full`}
          >
            <span className="poppins text-base font-semibold ">{isPreviewMode ? 'Disabled in preview mode' : `Activate Level ${nextLvl}`}</span>
          </button>
        </div>
      </div>
      <ActivateLvlUsdtModal level={nextLvl} onClose={onClose} openedModal={openedModal} />
    </>
  );
};
