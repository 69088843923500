import { useState } from 'react';
import { setCookie, parseCookies } from 'nookies';

export const useCheckReflink = () => {
  const [uplineData, setUplineData] = useState(null);

  const checkReflink = () => {
    const refData = new URL(window.location.toString()).searchParams.get('ref');
    const refIdData = new URL(window.location.toString()).searchParams.get('refid');
    if (refData) {
      setUplineData({ type: 'ref', value: refData });
      setCookie(null, 'qornex_upline', refData, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      });
    } else {
      if (!refIdData) {
        const parseUpline = !!parseCookies()?.['qornex_upline_id'] ? null : parseCookies()?.['qornex_upline'];
        if (!!parseUpline) {
          setUplineData({ type: 'ref', value: parseUpline });
        }
      }
    }
  };

  const checkReflinkId = () => {
    const refData = new URL(window.location.toString()).searchParams.get('ref');
    const refIdData = new URL(window.location.toString()).searchParams.get('refid');
    if (refIdData) {
      setUplineData({ type: 'refid', value: refIdData });
      setCookie(null, 'qornex_upline_id', refIdData, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
      });
    } else {
      if (!refData) {
        const parseUpline = parseCookies()?.['qornex_upline_id'];
        if (!!parseUpline) {
          setUplineData({ type: 'refid', value: parseUpline });
        }
      }
    }
  };

  return {
    uplineData,
    checkReflink,
    checkReflinkId,
  };
};
