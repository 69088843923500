import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_LEADERBOARD_USERS, GET_LEADERBOARD_USERS_FLINE } from '../../helpers/graphRequests';

export const Leaderboard = () => {
  const [callRequest, props] = useLazyQuery(GET_LEADERBOARD_USERS, { fetchPolicy: 'cache-and-network' });
  const [callRequestFline, propsFLine] = useLazyQuery(GET_LEADERBOARD_USERS_FLINE, {
    variables: { downlines: null },
    fetchPolicy: 'cache-and-network',
  });

  const { loading, data, called } = props ?? {};
  const { loadingFline, dataFline, calledFline } = propsFLine ?? {};

  const getLeaderboard = async () => {
    try {
      await callRequest();
    } catch (e) {
      console.log(e);
    }
  };

  const getLeaderboardFline = async (data) => {
    let leadersId =
      data?.users &&
      data?.users?.reduce((result, item) => {
        return [...result, item?.id];
      }, []);

    if (!!leadersId.length) {
      try {
        await callRequestFline({ variables: { downlines: leadersId } });
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    getLeaderboard();
  }, []);

  useEffect(() => {
    if (data?.users && !calledFline) {
      getLeaderboardFline(data);
    }
  }, [data, calledFline]);

  console.log(data, 'main list');

  console.log(dataFline, 'downlines');

  return <div className="">1234</div>;
};
