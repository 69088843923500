import React from 'react';

export const Burning = () => {
  return (
    <div className="flex sm:flex-col items-start justify-between pt-[80px] w-full max-w-[1115px] sm:pt-20 sm:px-4">
      <div className="relative">
        <img className="" src="/images/about/burning/burning.png" />
        <img className="absolute bottom-[-25%] right-[-30%]" src="/images/about/burning/botShadow.png" />
        <img className="absolute top-[-47%] sm:top-[-53%] left-0" src="/images/about/burning/topShadow.png" />
      </div>
      <div className="flex flex-col space-y-8 max-w-[530px] sm:max-w-full">
        <span className="text-[54px] text-white poppins font-semibold text-left sm:text-h2">Burning</span>
        <span className="text-white-500 leading-8 font-light">
          Qornex is accumulating a pool of FRGX tokens for the purpose of burning them. The pool is replenished with
          each activated level, as 2% of the level cost is automatically sent to it.
        </span>
        <span className="text-white-500 leading-8 font-light">
          Deflationary model maintains a balance between supply and demand, allowing a portion of the emission to be
          taken out of circulation.
        </span>
      </div>
    </div>
  );
};
