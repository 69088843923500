import React, { useEffect, useMemo, useState } from 'react';
import { fromWei } from '../../../../helpers/numbers';
import { useModal } from '../../../../helpers/hooks/useModal';
import { CurrentStakingModal } from '../../../../components/Modals/CurrentStakingModal';
import { BuyStakingBoostModal } from '../../../../components/Modals/BuyStakingBoostModal';
import { differenceInSeconds, fromUnixTime } from 'date-fns';
import { useGetContract } from '../../../../helpers/hooks/useGetContract';
import { CONTRACT_NAMES } from '../../../../helpers/constants';
import { useWeb3React } from '@web3-react/core';

export const ItemFarm = ({ stakingState, activationTime, endTime, amount, index, fetchStakingInfo }) => {
  const { account } = useWeb3React();
  const [isBoosted, setIsBoosted] = useState(false);
  const { onOpen, onClose, openedModal } = useModal();
  const { onOpen: onOpenBoost, onClose: onCloseBoost, openedModal: openedBoostModal } = useModal();
  const titles = ['Basic', 'Advanced', 'Increased', 'Enhanced', 'Maximum'];

  const { getContract } = useGetContract();

  const checkBoost = async () => {
    try {
      const stakingContract = await getContract(CONTRACT_NAMES.STAKING_POOL);
      const checkBoost = await stakingContract.userBoosts(account, index);
      if (checkBoost) {
        setIsBoosted(true);
      }
    } catch (e) {
      console.log(e);
      setIsBoosted(false);
    }
  };

  useEffect(() => {
    if (account) {
      checkBoost();
    }
  }, [account]);

  const stakingParams = useMemo(() => {
    return stakingState?.availablePeriods?.map((period, index) => ({
      days: period / 86400,
      title: titles[index],
    }));
  }, [stakingState?.availablePeriods]);

  const apy = Number(stakingState?.apy[index]) * 100;

  const remDays =
    (differenceInSeconds(new Date(), fromUnixTime(activationTime)) /
      differenceInSeconds(fromUnixTime(endTime), fromUnixTime(activationTime))) *
    100;

  return (
    <>
      <div
        className="cursor-pointer flex flex-col justify-between rounded-3xl farms-bg space-y-6 min-w-[261px] min-h-[398px] sm:min-h-[318px] sm:min-w-full p-4 border-[1px] border-white-100 backdrop-blur-large"
        key={index}
      >
        <div className="flex justify-between items-center">
          {/* <div className="flex items-center justify-center bg-white-10 border-[1px] border-white-100 rounded-full py-1 px-3">
            <span className="text-xs text-white font-medium">{titles[index]}</span> 
          </div> */}
          <span className="text-white font-medium">
            {stakingParams[index]?.title} ({stakingParams[index]?.days} days)
          </span>
          <button onClick={onOpen} className="flex space-x-1 px-3 py-2 bg-[#EC336A] rounded-full">
            <span className="text-xs text-white font-medium">Unstake</span>
          </button>
        </div>
        {isBoosted && (
          <div className="px-3 flex items-center justify-between h-[80px] w-full buyBoost-gradient rounded-[16px]">
            <span className='text-white font-semibold'>APY Boosted</span>
            <img className='h-[84px]' src="/images/staking/rocketPreview.png" alt="" />
          </div>
        )}
        <div className="flex flex-col space-y-6 w-full">
          <div className="flex flex-col space-y-0.5">
            <span className="text-xs text-white-500 font-light">Current Weekly APY</span>

            <div className="flex items-center space-x-2.5">
              {apy ? (
                <h2 className="text-white text-xl font-semibold poppins font-light leading-10">
                  {parseFloat(apy).toFixed(1)}%
                </h2>
            ) : (
              <h2 className="text-white text-xl font-semibold poppins font-light leading-10">-</h2>
              )}
              {!isBoosted && (
                <button onClick={onOpenBoost} className='flex items-center space-x-1 py-1 px-3 bg-[#EBFF29] rounded-full font-semibold'>
                  <span className='text-[12px]'>Boost APY</span>
                  <img src="/icons/staking/rocket.svg" alt="" />
                </button>
              )}
            </div>
            {!apy && (
              <div className="flex flex-col">
                <div className="flex items-center justify-center bg-white-30 rounded-2xl h-[72px] px-4">
                  <span className="text-white-500 text-xs font-light leading-[20px]">
                    Your first rewards will be credited starting from Monday [12:00 UTC]
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col space-y-0.5">
            <span className="text-xs text-white-500 font-light">Staked</span>
            <h2 className="text-white text-xl font-semibold poppins font-light leading-10">
              {fromWei(`${amount}`)}
              <span className="text-white-500"> FRGX</span>
            </h2>
          </div>
          <div className="flex flex-col space-y-2 ">
            <span className="text-xs text-white-500 font-light">Staking Period, %</span>
            <div className="overflow-hidden h-[26px] rounded-lg bg-white-70">
              <div style={{ width: `${remDays}%` }} className="bg-[#EBFF29] h-full" />
            </div>
          </div>
        </div>
      </div>
      {openedModal && (
        <CurrentStakingModal
          endTime={endTime}
          index={index}
          amount={amount}
          fetchStakingInfo={fetchStakingInfo}
          openedModal={openedModal}
          handleCloseModal={onClose}
        />
      )}
      {openedBoostModal && (
        <BuyStakingBoostModal 
          index={index}
          fetchStakingInfo={fetchStakingInfo}
          openedModal={openedBoostModal}
          onClose={onCloseBoost}
        />
      )}
    </>
  );
};
