import React, { useState } from 'react';
import { menuList } from 'helpers/menu';
import { Link } from 'react-router-dom';
import { BurgerMenu } from '../BurgerMenu';

export const MobMenu = ({ setChoosedMode }) => {
  let mobileMenuList = [menuList[1], menuList[3], menuList[6], menuList[4], menuList[0]];
  const [isOpenBurger, setIsOpenBurger] = useState(false);

  return (
    <>
      <div className="hidden sm:flex w-full fixed bottom-4 z-[1111] px-4">
        <div className="hidden sm:flex flex-shrink-0 px-6 h-[72px] rounded-3xl w-full z-[9999] overflow-hidden backdrop-blur-large  bg-[#131313]">
          <div className="flex justify-between items-center w-full z-[1]">
            {mobileMenuList?.map((item, itemIndex) => {
              return (
                <div
                  key={itemIndex}
                  className={`flex items-center justify-center mobMenu-gray-shadow bg-mobMenuButton rounded-2xl w-12 h-12 ${item?.bg}`}
                >
                  {item?.title === 'Profile' ? (
                    <button onClick={() => setIsOpenBurger((prev) => !prev)}>
                      <img
                        className="p-3 z-[50] flex-shrink-1"
                        src={isOpenBurger ? item?.mobIconClose : item?.mobIcon}
                      />
                    </button>
                  ) : (
                    <Link to={item.href + location.search}>
                      <img className="p-3 z-[50] flex-shrink-1" src={item?.mobIcon} />
                    </Link>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {isOpenBurger && (
        <BurgerMenu setChoosedMode={setChoosedMode} isActive={isOpenBurger} setIsActive={setIsOpenBurger} />
      )}
    </>
  );
};
