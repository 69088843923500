import React from 'react';
import { Link } from 'react-router-dom';

export const QornexInfo = ({ joinFunc }) => {
  return (
    <div className="flex flex-col items-center justify-center space-y-10 w-full sm:space-y-20">
      <div className="flex items-center justify-center w-full sm:flex-col-reverse sm:px-4 sm:pt-[76px]">
        <div className="flex flex-col space-y-12">
          <div className="flex flex-col">
            <span className="poppins text-[100px] text-white font-semibold sm:text-[54px] sm:text-center">Qornex</span>
            <span className="text-white-500 text-base font-light max-w-[550px] leading-8 sm:text-center">
              Qornex is an innovative blockchain and smart contract based platform for managing funds and building an
              online business on staking, farming, and team growth.
            </span>
          </div>
          <div className="flex space-x-3">
            {joinFunc ? (
              <button
                onClick={() => joinFunc(0)}
                className="flex items-center justify-center bg-[#EBFF29] rounded-2xl px-8 h-[50px] sm:px-0 sm:w-full"
              >
                <span className="poppins text-black text-base font-semibold leading-[18px]">Join Qornex</span>
              </button>
            ) : (
              <Link
                to={'/' + location.search}
                className="flex items-center justify-center bg-[#EBFF29] rounded-2xl px-8 h-[50px] sm:px-0 sm:w-full"
              >
                <span className="poppins text-black text-base font-semibold leading-[18px]">Join Qornex</span>
              </Link>
            )}
            <a
              href="https://qornex.com/docs/overview.pdf"
              target="_blank"
              className="flex items-center justify-center learn-more-button-bg rounded-2xl px-8 h-[50px] sm:px-0 sm:w-full"
              rel="noreferrer"
            >
              <span className="poppins text-white text-base font-semibold leading-[18px]">Learn More</span>
            </a>
          </div>
        </div>
        <img src="/images/about/qornexInfo/logo.png" />
      </div>
      <div className="flex flex-col items-center justify-center space-y-8 py-[60px] sm:py-6 sm:px-4 relative w-full">
        <span className="text-[#EBFF29] text-5xl poppins sm:text-[32px] ">Qornex</span>
        <span className="leading-8 max-w-[660px] text-white-500 text-center font-light">
          Stands out from the other decentralized marketing platforms due to its unique multi-component diversification
          model that includes a number of financial tools for a well-balanced performance and stable earnings in FRGX
          Token.
        </span>
        <img
          className="absolute left-0 top-[-200px] sm:h-[400px] sm:left-[-40px] sm:top-[-120px]"
          src="/images/about/qornexInfo/circle.png"
        />
        <img
          className="absolute right-0 top-[-200px] rotate-180 sm:h-[400px] sm:right-[-40px] sm:top-[-120px]"
          src="/images/about/qornexInfo/circle.png"
        />
      </div>
    </div>
  );
};
