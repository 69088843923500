import React from 'react';
import { QornexInfo } from '../../../features/about/QornexInfo';
import { Header } from '../../../features/about/Header';
import { Advantages } from '../../../features/about/Advantages';
import { Marketing } from '../../../features/about/Marketing';
import { LevelCost } from '../../../features/about/LevelCost';
import { TeamBuilding } from '../../../features/about/TeamBuilding';
import { Farming } from '../../../features/about/Farming';
import { Burning } from '../../../features/about/Burning';
import { Staking } from '../../../features/about/Staking';
import { Apy } from '../../../features/about/Apy';
import { AboutFrgx } from '../../../features/about/AboutFrgx';
import { Partners } from '../../../features/about/Partners';
import { FaqList } from '../../../features/about/FaqList';
import { SmartContract } from '../../../features/about/SmartContract';
import { Footer } from '../../../features/about/Footer';

export const MoreAboutQornex = ({ setInitStep }) => {
  return (
    <div className="flex flex-col items-center w-full h-full overflow-auto">
      <Header />
      <QornexInfo joinFunc={setInitStep} />
      <Advantages />
      <Marketing />
      <LevelCost />
      <TeamBuilding />
      <Farming />
      <Burning />
      <Staking />
      <Apy />
      <AboutFrgx />
      <Partners />
      <FaqList />
      <SmartContract />
      <Footer />
    </div>
  );
};
