import React from 'react';
import { copy } from '../../../helpers/copy';

export const SmartContract = () => {
  return (
    <div className="flex flex-col items-center  space-y-8 pt-[200px] sm:pt-20">
      <img className="w-fit" src="/images/about/smartContract/frgxLogo.png" />
      <span className="text-[54px] poppins leading-[80px] font-semibold text-white sm:text-h2 sm:leading-8">
        Smart Contract
      </span>
      <div className="flex items-center justify-center space-x-3 staking-table-bg px-6 py-4 rounded-3xl sm:px-4 sm:py-2 sm:rounded-2xl">
        <span className="text-white leading-8 sm:text-[11px]">0xc703da39ae3b9db67c207c7bad8100e1afdc0f9c</span>
        <button
          onClick={() => copy('0xc703da39ae3b9db67c207c7bad8100e1afdc0f9c')}
          className="bg-[#6850F3] p-1 rounded-lg"
        >
          <img src="/icons/about/smartContract/copyIcon.svg" />
        </button>
      </div>
    </div>
  );
};
