import React from 'react';
import { QornexInfo } from '../../features/about/QornexInfo';
import { Header } from '../../features/about/Header';
import { Advantages } from '../../features/about/Advantages';
import { Marketing } from '../../features/about/Marketing';
import { LevelCost } from '../../features/about/LevelCost';
import { TeamBuilding } from '../../features/about/TeamBuilding';
import { Farming } from '../../features/about/Farming';
import { Burning } from '../../features/about/Burning';
import { Staking } from '../../features/about/Staking';
import { Apy } from '../../features/about/Apy';
import { AboutFrgx } from '../../features/about/AboutFrgx';
import { Partners } from '../../features/about/Partners';
import { FaqList } from '../../features/about/FaqList';
import { SmartContract } from '../../features/about/SmartContract';
import { Footer } from '../../features/about/Footer';
import { NonAuthLayout } from '../../layouts';

export const About = () => {
  return (
    <NonAuthLayout>
      <Header />
      <QornexInfo />
      <Advantages />
      <Marketing />
      <LevelCost />
      <TeamBuilding />
      <Farming />
      <Burning />
      <Staking />
      <Apy />
      <AboutFrgx />
      <Partners />
      <FaqList />
      <SmartContract />
      <Footer />
    </NonAuthLayout>
  );
};
