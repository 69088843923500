import React from 'react';

export const LevelCost = () => {
  const distributionlist = [
    {
      percent: '8%',
      desc: 'is sent to automatic farming of FRGX tokens.',
    },
    {
      percent: '8%',
      desc: 'is sent to an innovative crypto staking pool on Qornex.',
    },
    {
      percent: '2%',
      desc: 'is sent for burning to support FRGX demand and rate.',
    },
    {
      percent: '2%',
      desc: 'is sent to cover the marketing purposes.',
    },
  ];
  return (
    <div className="flex flex-col space-y-[60px] pt-[200px] w-full max-w-[1115px] sm:px-4 sm:pt-[80px]">
      <span className="text-[54px] text-white poppins font-semibold text-center sm:text-h2">
        Level
        <span className="about-title"> Cost</span> Allocation
      </span>
      <div className="flex flex-col space-y-6">
        <div className="flex items-center justify-center  rounded-medium h-[124px] w-full overflow-hidden relative sm:h-full ">
          <div className="flex items-center justify-center space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 sm:p-6">
            <span className="text-[80px] text-[#EBFF29] poppins font-semibold sm:text-[60px] sm:leading-[60px]">
              100%
            </span>
            <span className="leading-8 text-white-500 font-light sm:text-center">
              of the cost of level activation is divided <br />
              into 2 parts and allocated as follows.
            </span>
            <div className="flex items-center justify-center">
              <img className="w-20 h-20" src="/icons/instrument/star.svg" />
              <span className="absolute font-semibold text-lg poppins text-black">Lvl 1</span>
            </div>
          </div>
          <img className="absolute bottom-0 left-0" src="/images/about/levelCost/purpleShadow.png" />
          <img
            className="absolute bottom-0 right-0 sm:top-0 sm:h-full"
            src="/images/about/levelCost/yellowShadow.png"
          />
        </div>
        <div className="flex space-x-6 sm:flex-col sm:space-x-0 sm:space-y-6 ">
          <div className="about-blocks-border p-[1px] rounded-medium h-full w-full sm:rounded-3xl">
            <div className="rounded-medium h-full bg-[#080909] w-full px-6 pt-8 sm:pt-6 relative sm:rounded-3xl overflow-hidden">
              <div className="flex flex-col space-y-3">
                <h2 className="text-white font-semibold">80%</h2>
                <span className="leading-8 text-white-500 font-light">
                  Is allocated in the marketing program <br /> of your partner structure.{' '}
                </span>
                <img className="z-[1]" src="/images/about/levelCost/mobile.png" />
                <img className="absolute left-0" src="/images/about/levelCost/mobileShadow.png" />
              </div>
            </div>
          </div>
          <div className="about-blocks-border p-[1px] rounded-medium h-full w-full sm:rounded-3xl">
            <div className="rounded-medium h-full bg-[#080909] w-full px-6 py-8 sm:py-6 relative sm:rounded-3xl">
              <div className="flex flex-col space-y-3">
                <h2 className="text-white font-semibold">20%</h2>
                <span className="leading-8 text-white-500 font-light">
                  is allocated to a set of financial instruments
                </span>
                {distributionlist.map((item, itemIndex) => {
                  return (
                    <div
                      className="about-blocks-border p-[1px] rounded-medium h-full w-full sm:rounded-2xl"
                      key={itemIndex}
                    >
                      <div className="flex items-center space-x-3 rounded-medium h-full bg-[#080909] w-full px-6 py-3 relative sm:rounded-2xl">
                        <span className="text-white text-xl font-semibold leading-">{item?.percent}</span>
                        <span className="text-white-500 text-sm sm:leading-8">{item?.desc}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
