import { useState, useEffect } from 'react';
import BigNumber from 'bignumber.js';
import { liquidityAbi } from '../contractsAbi/liquidityAbi';
import { useWeb3React } from '@web3-react/core';
import { Contract } from '@ethersproject/contracts';

export function transactionalNumberToNumber(amount, decimals = 18) {
  return new BigNumber(amount, 10).dividedBy(new BigNumber(10).pow(decimals)).toNumber();
}

export function forPrice(amount, decimals = 18) {
  const multiplier = new BigNumber(10).pow(decimals);
    return new BigNumber(amount).multipliedBy(multiplier).toFixed(0);
  }

export const useFrgxPriceByBnbAmount = () => {
  const [frgxPriceByAmountIsLoading, setFrgxPriceByAmountIsLoading] = useState(false);
  const [frgxPrice, setFrgxPrice] = useState(null);
  const { account, provider } = useWeb3React();

  const getFrgxPriceByBnbAmount = async () => {
    if (!frgxPriceByAmountIsLoading) {
      setFrgxPriceByAmountIsLoading(true);

      const contractAddr = '0x55f303a94b8b085917459ae7E0b252b0E20b5adD';

      try {
        const contractPancake = await new Promise(function(resolve, rejected) {
          if (provider) {
            const contract = new Contract(
              contractAddr,
              liquidityAbi,
              provider?.getSigner(account).connectUnchecked() || provider
            );
  
            resolve(contract);
          } else {
            rejected("error init contract: ");
          }
        });
  
        const [reserve0, reserve1] = await contractPancake.getReserves();
  
        let price;
  
          price =
            transactionalNumberToNumber(reserve1.toString()) /
            transactionalNumberToNumber(reserve0.toString());
      
        setFrgxPrice(forPrice(price * 0.95, 18));
      } catch (e) {
        console.log(e);
      } finally {
        setFrgxPriceByAmountIsLoading(false);
      }
    }
  };

  return {
    frgxPrice,
    frgxPriceByAmountIsLoading,
    getFrgxPriceByBnbAmount,
  };
};
