import { requestApi } from '../api';
import { BaseRepository } from './base';

class Repository extends BaseRepository {
  getNonce = (params) => requestApi('get', `${this.path}/nonce`, params);

  login = (address, signedMessage, message) => requestApi('post', `${this.path}/login`, { address, signedMessage, message });
}

export const AuthRepository = new Repository('/auth');
