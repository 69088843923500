import React, { useState } from 'react';
import { format } from 'date-fns';
import config from '../../helpers/config';
import { shortenAddress } from '../../helpers/format';
import { copy } from '../../helpers/copy';
import { EXPLORER_URL } from '../../helpers/constants';
import { Timer } from './Timer';

export const Footer = () => {
  const [isCompleted, setIsCompleted] = useState(false);
  const contractItem = (contract) => {
    const link = EXPLORER_URL + '/address/' + contract;
    return (
      <div className="flex items-center space-x-2.5 hover:text-white hover:underline">
        {!isCompleted && contract === config?.contractMatrixUsdt ? (
          <Timer isCompleted={isCompleted} setIsCompleted={setIsCompleted} />
        ) : (
          <>
            <a href={link} className="hidden sm:block">
              {shortenAddress(contract, 6)}
            </a>
            <a href={link} className="sm:hidden">
              {shortenAddress(contract, 12)}
            </a>
            <button className="p-1  bg-white-70 rounded-lg shrink-0" onClick={() => copy(contract)}>
              <img src="/icons/partners/copyIcon.svg" />
            </button>
          </>
        )}
      </div>
    );
  };

  return (
    <footer className="flex w-full px-12 pb-9 sm:px-4 sm:pb-4 z-[1] max-w-[1250px] ">
      <div className="flex flex-col items-start space-y-6 w-full rounded-3xl justify-between text-white-500 smart-contracts-container-bg p-6 sm:p-4">
        <h3 className="text-white text-lg poppins font-semibold text-white-500">Smart contracts</h3>
        <div className="grid gap-6 grid-cols-2 sm:grid-cols-1 w-full sm:gap-3">
          <div className="w-full justify-between flex sm:flex-row items-center bg-white-30 py-[14px] px-4 rounded-2xl sm:space-y-0">
            <h4 className="text-base font-semibold text-white">FRGX</h4>
            <span className="text-sm font-medium text-white-500">{contractItem(config?.contractFrgx)}</span>
          </div>
          <div className="w-full justify-between flex sm:flex-row items-center bg-white-30 py-[14px] px-4 rounded-2xl sm:space-y-0">
            <h4 className="text-base font-semibold text-white">USDT</h4>
            <span className="text-sm font-medium text-white-500">{contractItem(config?.contractUsdt)}</span>
          </div>
          <div className="w-full justify-between flex sm:flex-row items-center bg-white-30 py-[14px] px-4 rounded-2xl sm:space-y-0">
            <h4 className="text-base font-semibold text-white">Program FRGX</h4>
            <span className="text-sm font-medium text-white-500">{contractItem(config?.contractMatrixBase)}</span>
          </div>
          <div className="w-full justify-between flex sm:flex-row items-center bg-white-30 py-[14px] px-4 rounded-2xl sm:space-y-0">
            <h4 className="text-base font-semibold text-white">Program USDT</h4>
            <span className="text-sm font-medium text-white-500">{contractItem(config?.contractMatrixUsdt)}</span>
          </div>
          <div className="w-full justify-between flex sm:flex-row items-center bg-white-30 py-[14px] px-4 rounded-2xl sm:space-y-0">
            <h4 className="text-base font-semibold text-white">Farming</h4>
            <span className="text-sm font-medium text-white-500">{contractItem(config?.contractFarmingPool)}</span>
          </div>
          <div className="w-full justify-between flex sm:flex-row items-center bg-white-30 py-[14px] px-4 rounded-2xl sm:space-y-0">
            <h4 className="text-base font-semibold text-white">Staking</h4>
            <span className="text-sm font-medium text-white-500">{contractItem(config?.contractStakingPool)}</span>
          </div>
        </div>
      </div>
    </footer>
  );
};
