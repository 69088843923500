import React, { useEffect, useMemo, useState } from 'react';
import { BreadCrumbs, LevelsStatus } from 'components';
import { BaseLayout } from 'layouts';
import { MainBanner } from '../../features/staking/MainBanner';
import { Statics } from '../../features/staking/Statics';
import { Farms } from '../../features/staking/Farms';
import { useGetContract } from '../../helpers/hooks/useGetContract';
import { useWeb3React } from '@web3-react/core';
import { CONTRACT_NAMES } from '../../helpers/constants';
import { fromWei } from '../../helpers/numbers';
import { useCheckPreviewMode } from '../../helpers/hooks/useCheckPreviewMode';
import { PageLockedBanner } from '../../components';

export const Staking = () => {
  const { account } = useWeb3React();
  const { getContract } = useGetContract();
  const { isPreviewMode, isDoneCheckedPreview } = useCheckPreviewMode();

  const [stakingState, setStakingState] = useState({
    availablePeriods: [],
    userStakes: [],
    dividents: 0,
    apy: 0,
    userDep: 0,
  });

  const fetchStakingInfo = async () => {
    try {
      const stakingContract = await getContract(CONTRACT_NAMES.STAKING_POOL);
      const { periods, bonuses } = await stakingContract.availablePeriods();
      const dividents = await stakingContract.cumulativeDividendsOf(account);
      const apy = await stakingContract.getTotalCurrentUserApy(account);
      const userDep = await stakingContract.getUserTotalStake(account);
      const stakes = [];

      for (let i = 0; i < 5; i++) {
        const { activationTime, amount, amountWithBonus, endTime } = await stakingContract.userStakes(account, i);
        stakes.push({
          index: i,
          activationTime: parseInt(activationTime),
          amount: amount,
          amountWithBonus: amountWithBonus,
          endTime: parseInt(endTime),
        });
      }

      setStakingState({
        availablePeriods: periods.map((period) => parseInt(period)),
        bonuses: bonuses.map((period) => fromWei(period)),
        userStakes: stakes,
        dividents: fromWei(dividents),
        userDep: fromWei(userDep),
        apy: [fromWei(apy[0]), fromWei(apy[1]), fromWei(apy[2]), fromWei(apy[3]), fromWei(apy[4])],
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (account && !isPreviewMode && isDoneCheckedPreview) {
      fetchStakingInfo();
    }
  }, [account, isDoneCheckedPreview, isPreviewMode]);

  const filteredUserStakes = useMemo(() => {
    return stakingState?.userStakes.filter((item) => !!item.activationTime);
  }, [stakingState?.userStakes]);

  return (
    <BaseLayout>
      <div className="flex items-center space-x-6 pb-6 relative sm:flex-col sm:space-x-0 sm:space-y-4 sm:items-start">
        <BreadCrumbs title="Staking">
          <LevelsStatus />
        </BreadCrumbs>
      </div>
      <div className="flex flex-col space-y-14 sm:space-y-6">
        {isPreviewMode ? (
          <PageLockedBanner />
        ) : (
          <>
            <MainBanner
              fetchStakingInfo={fetchStakingInfo}
              stakingState={stakingState}
              isStakeExist={!!filteredUserStakes?.length}
            />
            {/*<Statics />*/}
            <Farms fetchStakingInfo={fetchStakingInfo} stakingState={stakingState} />
          </>
        )}
      </div>
    </BaseLayout>
  );
};
