import React, { useEffect, useState } from 'react';
import { BreadCrumbs, LevelsStatus } from 'components';
import { UserProfit } from '../../features/dashboard/Main/UserProfit';
import { UserInfo } from '../../features/dashboard/RightBar/UserInfo';
import { BaseLayout } from 'layouts';
import { getUser } from '../../store/userSlice/selectors';
import { useSelector } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { TotalActivity } from '../../features/dashboard/Main/TotalActivity';
import { LevelsDays } from '../../features/dashboard/Main/LevelsDays';
import { StakingBanner } from '../../features/dashboard/RightBar/StakingBanner';
import { FarmingBanner } from '../../features/dashboard/RightBar/FarmingBanner';
import { BuyFrgxBanner } from '../../features/dashboard/RightBar/BuyFrgxBanner';
import { getMatrixB } from '../../store/matrixBSlice/selectors';
import { useLazyQuery } from '@apollo/client';
import { GET_PARTNERS_24 } from '../../helpers/graphRequests';
import { add, isAfter, fromUnixTime } from 'date-fns';
import { Activity } from '../../components/Activity';
import { Ticker } from '../../components/Ticker';
import { BuyLevelButton } from '../../components/BuyLevelButton';

export const Dashboard = () => {
  const { account } = useWeb3React();
  const currentUser = useSelector(getUser);
  const matrixInfo = useSelector(getMatrixB);
  const [partners24, setPartners24] = useState(0);

  const [callRequest, props] = useLazyQuery(GET_PARTNERS_24, {
    variables: { user: null },
    fetchPolicy: 'cache-and-network',
  });
  const { loading, data, called } = props ?? {};

  const findPartners24 = async (account) => {
    try {
      const result = await callRequest({ variables: { user: account.toLocaleLowerCase() } });
      if (result) {
        const countUsers24 = result.data.users.reduce((result, item) => {
          const temp = add(new Date(), { hours: -24 });
          if (isAfter(fromUnixTime(item.registrationTime), temp)) {
            return result + 1;
          }
          return result;
        }, 0);
        setPartners24(countUsers24);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (account) {
      findPartners24(account);
    }
  }, [account]);

  return (
    <BaseLayout>
      <BreadCrumbs title="Dashboard">
        <LevelsStatus className="sm:hidden" />
      </BreadCrumbs>
      <div className="z-[10] grid grid-rows-8 grid-cols-3 w-full h-auto gap-6 sm:grid-cols-1 z-[1] pt-6 sm:gap-0 sm:gap-y-6 sm:pt-0 ">
        <Ticker />
        <UserProfit matrixInfo={matrixInfo} />
        <UserInfo {...currentUser} partners24={partners24} />
        <div className="flex flex-col space-y-6 w-full row-span-2 col-span-2 sm:space-x-0 sm:space-y-6 z-[1]">
          <div className="flex space-x-6 w-full sm:flex-col sm:space-x-0 sm:space-y-6 ">
            <TotalActivity matrixInfo={matrixInfo} partners24={partners24} />
            <LevelsDays matrixInfo={matrixInfo} />
          </div>
          <Activity />
        </div>
        <div className="flex flex-col space-y-6 sm:w-full z-[1]">
          <BuyFrgxBanner />
          <StakingBanner />
          <FarmingBanner />
        </div>
      </div>
    </BaseLayout>
  );
};
