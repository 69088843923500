import React from 'react';
import { BaseLayout } from '../../layouts';
import { BreadCrumbs, LevelsStatus } from '../../components';
import { copy } from '../../helpers/copy';

export const Promo = () => {
  const promoList = [
    {
      key: 'qornexUsdtEn',
      title: 'Qornex Usdt en',
      backgroundImage: '/images/promo/bgUsdt.webp',
      fileUrl: 'https://qornex.com/docs/usdt.pdf',
    },
    {
      key: 'qornexUsdtEn',
      title: 'Qornex Usdt ar',
      backgroundImage: '/images/promo/bgUsdt.webp',
      fileUrl: 'https://qornex.com/docs/usdt_ar.pdf',
    },
    {
      key: 'qornexUsdtEn',
      title: 'Qornex Usdt bn',
      backgroundImage: '/images/promo/bgUsdt.webp',
      fileUrl: 'https://qornex.com/docs/usdt_bn.pdf',
    },
    {
      key: 'qornexUsdtEn',
      title: 'Qornex Usdt hi',
      backgroundImage: '/images/promo/bgUsdt.webp',
      fileUrl: 'https://qornex.com/docs/usdt_hi.pdf',
    },
    {
      key: 'qornexUsdtEn',
      title: 'Qornex Usdt ur',
      backgroundImage: '/images/promo/bgUsdt.webp',
      fileUrl: 'https://qornex.com/docs/usdt_ur.pdf',
    },
    {
      key: 'qornexUsdtEn',
      title: 'Qornex Usdt es',
      backgroundImage: '/images/promo/bgUsdt.webp',
      fileUrl: 'https://qornex.com/docs/usdt_es.pdf',
    },
    {
      key: 'qornexUsdtEn',
      title: 'Qornex Usdt fr',
      backgroundImage: '/images/promo/bgUsdt.webp',
      fileUrl: 'https://qornex.com/docs/usdt_fr.pdf',
    },
    {
      key: 'qornexUsdtEn',
      title: 'Qornex Usdt ru',
      backgroundImage: '/images/promo/bgUsdt.webp',
      fileUrl: 'https://qornex.com/docs/usdt_ru.pdf',
    },
    {
      key: 'qornexFrgxEn',
      title: 'Qornex Frgx en',
      backgroundImage: '/images/promo/bgFrgx.webp',
      fileUrl: 'https://qornex.com/docs/overview.pdf',
    },
  ];

  return (
    <BaseLayout>
      <div className="flex items-center space-x-6 pb-6 relative sm:flex-col sm:space-x-0 sm:space-y-4 sm:items-start">
        <BreadCrumbs title="Promo">
          <LevelsStatus />
        </BreadCrumbs>
      </div>
      <div className="grid grid-cols-3 gap-10 sm:grid-cols-1">
        {promoList?.map((item, itemIndex) => {
          return (
            <div
              className="flex flex-col space-y-6 promo-banner-bg rounded-3xl overflow-hidden border-[1px] border-white-100 sm:w-full"
              key={item?.key}
            >
              <div
                style={{
                  backgroundImage: `url(${item?.backgroundImage})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
                className="flex items-center justify-center h-[180px] w-full"
              ></div>
              <div className="flex flex-col justify-between space-y-9 px-4 pb-4">
                <div className="flex space-x-3">
                  <span className="text-xl text-white poppins font-semibold">{item?.title}</span>
                  <button onClick={() => copy(item?.fileUrl)} className="p-1 rounded-lg bg-white-70">
                    <img className="shrink-0" src="/icons/partners/copyIcon.svg" />
                  </button>
                </div>
                <a
                  target="blank"
                  href={item?.fileUrl}
                  className="flex items-center justify-center bg-secondary-500 h-[40px] w-full shadow-item rounded-3xl"
                >
                  <span className="text-sm poppins text-white font-medium">Learn</span>
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </BaseLayout>
  );
};
