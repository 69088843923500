import { PROGRAM_NAMES } from './constants';

export const MAIN_BG_COLOR = {
  [PROGRAM_NAMES.MATRIX_B]: 'bg-primary-500',
  [PROGRAM_NAMES.MATRIX_USDT]: 'bg-main-usdt',
};

export const MAIN_TEXT_COLOR = {
  [PROGRAM_NAMES.MATRIX_B]: 'text-primary-500',
  [PROGRAM_NAMES.MATRIX_USDT]: 'text-main-usdt',
};

export const NEXT_ACTIVATE_BG_CLASS = {
  [PROGRAM_NAMES.MATRIX_B]: 'level-activating-bg',
  [PROGRAM_NAMES.MATRIX_USDT]: 'level-activating-usdt-bg',
};
