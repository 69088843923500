import React from 'react';
import { Accordion } from 'react-accessible-accordion';
import { FaqItem } from './item';

import 'react-accessible-accordion/dist/fancy-example.css';

export const FaqList = () => {
  const info = [
    {
      title: 'Why should I try Qornex?',
      desc: (
        <span>
          When it comes to blockchain marketing solutions, users are usually looking for profitability, security,
          usability and a low entry threshold. All of this is found in Qornex!
        </span>
      ),
    },
    {
      title: 'Why the Qornex marketing strategies, including staking and farming, are in FRGX tokens on BNB Chain?',
      desc: (
        <span>
          The FRGX token was chosen as a safe and secure asset with no additional transaction fees, no owner and an open
          source smart contract. There are already several tens of thousands of people from 150+ countries in the
          community of holders. This token fully meets the needs of the project.
        </span>
      ),
    },
    {
      title: 'Are there any strategies for maximizing rewards with FRGX token on Qornex?',
      desc: (
        <span>
          To create a stable online business on the platform, you should focus on two aspects - inviting new users and
          the timely activation of levels. It also makes sense to open higher levels, as they generate more profit when
          filled with partners.
        </span>
      ),
    },
    {
      title: 'Who created Qornex?',
      desc: (
        <span>
          Qornex was created and set up by a dedicated team of Cryptocurrency professionals with a vast experience in
          Community Building and Decentralized Marketing Tactics. They put into working on the platform all their
          experience in launching and managing blockchain projects with millions of users.
        </span>
      ),
    },
  ];

  return (
    <div className="flex items-start justify-between w-full max-w-[1115px] pt-[200px] sm:px-4 sm:flex-col sm:space-y-10 sm:pt-20">
      <span className="text-[54px] text-white poppins font-semibold text-left sm:text-h2 sm:text-center">
        Frequently <br className="sm:hidden" /> <span className="about-title">Asked</span>
        <br className="sm:hidden" /> <span className="about-title">Questions</span>
      </span>
      <Accordion allowZeroExpanded allowMultipleExpanded className="w-[595px] sm:w-full space-y-5 ">
        {info.map((item, itemIndex) => {
          return <FaqItem {...item} key={itemIndex} />;
        })}
      </Accordion>
    </div>
  );
};
