import React, { useEffect, useState } from 'react';
import { Modal } from '../../Modal';
import { CONTRACT_NAMES } from '../../../helpers/constants';
import { useCallTransaction } from '../../../helpers/hooks/useCallTransaction';
import { useGetContract } from '../../../helpers/hooks/useGetContract';
import { useWeb3React } from '@web3-react/core';
import { fromWei } from '../../../helpers/numbers';
import clsx from 'clsx';
import { format } from 'date-fns';
import { TransactionStatuses } from '../../TransactionStatuses';
import { Input } from '../../Input';

const PASSWORD = 'unstake';

export const CurrentStakingModal = ({ openedModal, amount, handleCloseModal, index, fetchStakingInfo, endTime }) => {
  const [isWantUnStake, setIsWantUnStake] = useState(false);
  const [checkInputValue, setCheckInputValue] = useState('');
  const { account } = useWeb3React();
  const { getContract } = useGetContract();
  const [stakingState, setStakingState] = useState({
    withdrawComission: 0,
  });

  const [isLoadingUnstake, setIsLoadingUnstake] = useState(false);
  const { onCallTransaction, transactionInfo } = useCallTransaction();

  const onFetchInfo = async () => {
    try {
      const contract = await getContract(CONTRACT_NAMES.STAKING_POOL);
      const comission = await contract.calculateWithdrawComission(account, index);

      setStakingState({
        withdrawComission: fromWei(comission),
      });
    } catch (e) {}
  };

  const unstake = async () => {
    try {
      if (!isWantUnStake) {
        setIsWantUnStake(true);
      } else if (checkInputValue.toLowerCase() === PASSWORD.toLowerCase()) {
        if (!isLoadingUnstake) {
          setIsLoadingUnstake(true);

          const stakingContract = await getContract(CONTRACT_NAMES.STAKING_POOL);
          const tx = await stakingContract.unstake(index);

          onCallTransaction(tx);

          setIsLoadingUnstake(false);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingUnstake(false);
    }
  };

  useEffect(() => {
    if (transactionInfo.isSuccess || transactionInfo.isError) {
      fetchStakingInfo();
    }
  }, [transactionInfo]);

  useEffect(() => {
    if (account) {
      onFetchInfo();
    }
  }, [account]);

  useEffect(() => {
    if (!Number(stakingState.withdrawComission)) {
      setIsWantUnStake(true);
      setCheckInputValue('unstake');
    }
  }, [stakingState]);

  return (
    <Modal isOpened={openedModal} onClose={handleCloseModal} withoutClose={true}>
      <div className="flex flex-col items-center justify-center space-y-6 sm:w-full ">
        <div className="flex flex-col items-center justify-center space-y-2">
          <h2 className="leading-10 text-white poppins font-medium sm:text-[36px]">Unstake FRGX</h2>
        </div>
        <div className="flex flex-col items-start justify-center staking-modal-bg backdrop-blur-large  border border-[1px] border-white-100 w-[508px] rounded-3xl space-y-6 p-6 sm:p-0 sm:p-4 sm:rounded-none sm:rounded-t-3xl sm:w-full sm:pt-6 sm:pb-[50px] mt-auto">
          {transactionInfo.hash ? (
            <div className="p-6 w-full items-center justify-center">
              <TransactionStatuses transactionInfo={transactionInfo} />
            </div>
          ) : (
            <>
              <div className="flex flex-col w-full space-y-4">
                <div className="flex justify-between">
                  <span className="text-white text-xs">Amount</span>
                  <span className="text-white text-xs">{fromWei(amount)}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-white text-xs">Expiration date</span>
                  <span className="text-white text-xs">{format(new Date(endTime * 1000), 'yyyy-MM-dd HH:mm')}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-white text-xs">Penalty</span>
                  <span
                    className={clsx('text-errorPink text-xs', {
                      'text-white': !stakingState.withdrawComission,
                    })}
                  >
                    {(stakingState.withdrawComission * 100).toFixed(2)} %
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-white text-xs">Total</span>
                  <span className="text-white text-xs">
                    {(fromWei(amount) * (1 - stakingState.withdrawComission)).toFixed(2)}
                  </span>
                </div>
              </div>
              <div className="flex-col flex space-y-3 w-full">
                <button
                  onClick={unstake}
                  disabled={isLoadingUnstake || transactionInfo.isWaiting}
                  className="flex items-center justify-center w-full bg-secondary-500 rounded-2xl py-3.5 outline-none"
                >
                  <span className="text-white poppins font-medium leading-5">
                    {isLoadingUnstake || transactionInfo.isWaiting ? 'Loading...' : 'Unstake'}
                  </span>
                </button>
                {isWantUnStake && !!Number(stakingState.withdrawComission) && (
                  <>
                    <div className="">
                      <span className="text-errorPink text-xs">
                        Are you sure you want to claim your staking deposit with a penalty before the end of term? If
                        so, please type <span className="text-white font-semibold">UNSTAKE</span> in the field below.
                      </span>
                    </div>
                    <Input value={checkInputValue} onChange={(e) => setCheckInputValue(e.target.value)} />
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
