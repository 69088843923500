import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { Modal } from '../../Modal';
import { Days } from './Days';
import { useBalance } from '../../../helpers/hooks/useBalance';
import { useWeb3React } from '@web3-react/core';
import { useGetContract } from '../../../helpers/hooks/useGetContract';
import { useApproveWithChecks } from '../../../helpers/hooks/useApproveWithChecks';
import { useCallTransaction } from '../../../helpers/hooks/useCallTransaction';
import { STATUSES_ENUM } from '../../../helpers/hooks/useChecks';
import { CONTRACT_NAMES, ContractNames } from '../../../helpers/constants';
import { toWei } from '../../../helpers/numbers';
import { callNotification } from '../../../helpers/notification';
import { parseErrorToUserReadableMessage } from '../../../helpers/format';
import { checkApprove } from '../../../helpers/checks';
import { TransactionStatuses } from '../../TransactionStatuses';

const getChecksCallbacks = (web3Props, getContract, amount) => {
  const funcProps = { getContract, ...web3Props };

  return {
    name: ContractNames.STAKING_POOL,
    getContract,
    callbacks: [
      {
        func: checkApprove,
        key: 'checkApprove',
        funcProps: {
          ...funcProps,
          name: CONTRACT_NAMES.STAKING_POOL,
          price: amount,
        },
      },
    ],
  };
};

export const CreateStakingModal = ({ openedModal, handleCloseModal, stakingState, fetchStakingInfo }) => {
  const [isLoadingStake, setIsLoadingStake] = useState(false);
  const web3Props = useWeb3React();
  const { account } = web3Props;
  const [inputAmount, setInputAmount] = useState(0);
  const [currentDay, setCurrentDay] = useState(null);
  const { balance, fetchBalance, isLoadingBalance } = useBalance();
  const { getContract } = useGetContract();
  const { callApproveFRGX, statuses, callChecks } = useApproveWithChecks(getChecksCallbacks(web3Props, getContract, 100));
  const { onCallTransaction, transactionInfo } = useCallTransaction();

  const filteredUserStakes = useMemo(() => {
    return stakingState?.userStakes.filter((item) => !!item.activationTime && item.endTime >= Date.now() / 1000);
  }, [stakingState?.userStakes]);

  const stakingDays = useMemo(() => {
    return stakingState?.availablePeriods?.map((period, index) => ({
      days: period / 86400,
      disabled: !!filteredUserStakes?.find((item) => item.index === index),
      contractTime: period,
    }));
  }, [stakingState?.availablePeriods, filteredUserStakes]);

  const totalApy = (parseFloat(stakingState?.apy) * 100).toFixed(2);

  const onChangeInputAmount = (e) => {
    setInputAmount(parseFloat(e.target.value ? e.target.value : 0));
  };

  const onClickMax = () => {
    setInputAmount(balance - 0.001);
  };

  const onClose = () => {
    handleCloseModal();
    fetchStakingInfo();
  };

  const onClickStake = async () => {
    if (!isLoadingStake) {
      setIsLoadingStake(true);
      try {
        if (statuses.checkApprove === STATUSES_ENUM.ERROR) {
          callApproveFRGX();
        } else {
          const stakingContract = await getContract(CONTRACT_NAMES.STAKING_POOL);
          const tx = await stakingContract.stake(toWei(inputAmount), stakingDays[currentDay].contractTime);

          onCallTransaction(tx);
        }
      } catch (e) {
        console.log(e);
        callNotification({ type: 'error', message: parseErrorToUserReadableMessage(e) });
      }

      setIsLoadingStake(false);
    }
  };

  useEffect(() => {
    if (account) {
      fetchBalance(ContractNames.FRGX);
    }
  }, [account]);

  useEffect(() => {
    if (transactionInfo.isSuccess || transactionInfo.isError) {
      fetchStakingInfo();
    }
  }, [transactionInfo]);

  return (
    <Modal isOpened={openedModal} onClose={onClose} withoutClose={true}>
      <div className="flex flex-col items-center justify-center space-y-6 sm:w-full ">
        <div className="flex flex-col items-center justify-center space-y-2">
          <h2 className="leading-10 text-white poppins font-medium sm:text-[36px]">Stake FRGX</h2>
          <span className="text-xs text-white font-light sm:text-sm sm:text-center">Receive rewards for staking.</span>
        </div>
        <div className="flex flex-col items-start justify-center staking-modal-bg backdrop-blur-large  border border-[1px] border-white-100 w-[508px] rounded-3xl p-6 sm:p-0 sm:p-4 sm:rounded-none sm:rounded-t-3xl sm:w-full sm:pt-6 sm:pb-[50px] mt-auto">
          {transactionInfo.hash ? (
            <div className="p-6 flex w-full items-center justify-center">
              <TransactionStatuses transactionInfo={transactionInfo} />
            </div>
          ) : (
            <>
              <div className="flex w-full flex-col space-y-3">
                <div className="flex items-center justify-between w-full h-[56px] bg-white-10 rounded-2xl border-[1px] border-white-100 pl-6 pr-2">
                  <div className="flex space-x-3 text-white-500">
                    <img src="/images/modals/stakingModal/frgxLogo.png" />
                    <input
                      value={inputAmount}
                      onChange={onChangeInputAmount}
                      className="bg-transparent outline-none"
                      placeholder="FRGX Amount"
                    ></input>
                  </div>

                  <button
                    onClick={onClickMax}
                    className="flex items-center justify-center bg-[#EBFF29] px-3 py-2 rounded-xl"
                  >
                    <span className="text-black">Max</span>
                  </button>
                </div>
                <span className="text-white text-xs text-right">Your balance: {balance || 'loading'}</span>
              </div>
              <Days
                transactionInfo={transactionInfo}
                statuses={statuses}
                isLoadingStake={isLoadingStake}
                stakingState={stakingState}
                amount={inputAmount}
                onClickStake={onClickStake}
                callChecks={callChecks}
                setCurrentDay={setCurrentDay}
                currentDay={currentDay}
                stakingDays={stakingDays}
              />
              <div className="flex flex-col w-full space-y-4 pt-6">
                {!!totalApy && (
                  <div className="flex justify-between">
                    <span className="text-white text-xs">Total APY %</span>
                    <span className="text-white text-xs">{totalApy}</span>
                  </div>
                )}
                {!!parseFloat(stakingState?.bonuses[currentDay]) && (
                  <div className="flex flex-col space-y-2">
                    <span className="text-white text-xs">* Reward distribution boost</span>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
