import React, { useMemo } from 'react';
import { bigIntToInt } from '../../../../helpers/numbers';
import { LEVEL_STATUSES } from '../../../../helpers/instrument';

export const Profit = ({ levelNumber, totalReward, activationTimes, isOpen, levelStatus, isFreezed }) => {
  const renderStar = useMemo(() => {
    return (
      <div className="relative flex-shrink-0 flex items-center justify-center">
        <img
          className="sm:w-[60px] sm:h-[60px]"
          src={isFreezed ? '/icons/instrument/star_freezed.svg' : '/icons/instrument/star_usdt.svg'}
        />
        <span className="absolute z-[1] text-white text-lg poppins font-medium sm:text-base">Lvl {levelNumber}</span>
      </div>
    );
  }, [levelNumber, isFreezed]);

  const levelReward = bigIntToInt(totalReward) || 0;

  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center space-x-4 ">
        {renderStar}
        <div className="flex flex-col">
          <span className="text-h2 text-white font-medium sm:text-xl ">{levelReward} USDT</span>
        </div>
      </div>
      <div className="hidden flex-shrink-0 sm:flex items-center space-x-3">
        {isFreezed && (
          <div className="flex-shrink-0 bg-[#61B4E2] rounded-xl w-10 h-10 flex items-center justify-center">
            <img className="max-h-[60%]" src="/icons/instrument/freeze.svg" alt="" />
          </div>
        )}
        <button className="hidden flex-shrink-0 sm:flex items-center justify-center bg-white-70 w-10 h-10 rounded-xl">
          <img className={`${isOpen ? 'rotate-[-90deg]' : ''}`} src="/icons/instrument/arrow.svg" />
        </button>
      </div>
    </div>
  );
};
