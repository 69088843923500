import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getMatrixB } from '../../../../store/matrixBSlice/selectors';

export const Statics = ({ matrixProfit, programType }) => {
  const matrixInfo = useSelector(getMatrixB);

  const activeLvls =
    programType === 'usdt'
      ? matrixInfo.levels2.reduce((acc, item) => {
          if (!!item.activationTimes) {
            return (acc = acc + 1);
          }
          return acc;
        }, 0)
      : matrixInfo.levels.reduce((acc, item) => {
          if (!!item.activationTimes) {
            return (acc = acc + 1);
          }
          return acc;
        }, 0);

  const programPartners =
    programType === 'usdt'
      ? matrixInfo?.levels2[0]?.matrixs?.length + matrixInfo?.levels2[0]?.recyclesTotal * 14
      : matrixInfo?.levels[0]?.matrixs?.length + matrixInfo?.levels[0]?.recyclesTotal * 6;

  const staticsInfo = useMemo(
    () => [
      {
        count: activeLvls,
        desc: 'Activated levels',
        icon: 'levels',
      },
      // {
      //   count: '293 USDT',
      //   desc: 'Missed profit',
      //   icon: 'missedProfits',
      // },
      {
        count: programPartners,
        desc: 'Partners',
        icon: 'partners',
      },
      // {
      //   count: '14',
      //   desc: 'Missed partners',
      //   icon: 'missedPartners',
      // },
    ],
    [programPartners, activeLvls],
  );

  const cardInfo = useMemo(() => {
    switch (programType) {
      case 'usdt': {
        return {
          bg: 'statics-usdt-bg',
          tokenIcon: 'usdt',
          arrow: 'greenArrow',
        };
      }
      case 'frgx': {
        return {
          bg: 'statics-frgx-bg',
          tokenIcon: 'frgx',
          arrow: 'purpleArrow',
        };
      }
    }
  }, [programType]);

  return (
    <div
      className={`flex flex-col justify-between h-[275px] ${cardInfo?.bg} w-full  border-[1px] border-white-100 instruments-nonactive-bg rounded-3xl p-6 relative overflow-hidden backdrop-blur-large`}
    >
      <div className="flex flex-col space-y-[20px] z-[1] ">
        {/* <div className="flex justify-between w-full ">
          <span className="text-lg text-white poppins font-semibold">Statistic</span>
        </div> */}
        <div className="flex space-x-6 items-end">
          <img className="w-20 h-20" src={`/images/instruments/statics/${cardInfo?.tokenIcon}.png`} />
          <div className="flex flex-col space-y-1">
            <span className="text-base text-white-500 poppins font-semibold">Profit</span>
            <span className="text-h1 text-white poppins font-semibold">{matrixProfit}</span>
          </div>
        </div>
      </div>
      <div className="flex flex-col space-y-3 z-[1]">
        <div className="w-full flex justify-between items-end">
          <div className="grid grid-cols-2 gap-x-8 gap-y-6 ">
            {staticsInfo.map((item, itemIndex) => {
              return (
                <div className="flex space-x-3 items-center" key={itemIndex}>
                  <div className="flex items-center justify-center bg-black-300 rounded-xl w-10 h-10">
                    <img src={`/icons/instruments/statics/${item?.icon}.svg`} />
                  </div>
                  <div className="flex flex-col ">
                    <span className="text-lg text-white poppins font-semibold">{item?.count}</span>
                    <span className="text-xs text-white-500">{item?.desc}</span>
                  </div>
                </div>
              );
            })}
          </div>
          <Link
            to="/programs/frgx"
            className="flex-shrink-0 w-10 h-10 flex justify-center items-center rounded-xl bg-white"
          >
            <img src={`/icons/instruments/${cardInfo?.arrow}.svg`} />
          </Link>
        </div>
      </div>
    </div>
  );
};
