import React from 'react';
import { PreviewMode } from '../PreviewMode';
import { useSearchParams } from 'react-router-dom';
import { useCheckPreviewMode } from '../../helpers/hooks/useCheckPreviewMode';

export const BreadCrumbs = ({ title, children }) => {
  const { isPreviewMode, previewUserId } = useCheckPreviewMode();
  return (
    <div
      className={`flex justify-between z-[10] flex items-center space-x-6 sm:mb-3.5 ${
        isPreviewMode ? 'w-full' : 'w-fit'
      }`}
    >
      {/* sm:space-x-0 sm:flex-col sm:justify-start */}
      <h1 className="text-white font-medium sm:text-xl sm:text-left sm:w-full">{title}</h1>
      {children}
      {!!previewUserId && <PreviewMode className="sm:hidden" previewUserId={previewUserId} />}
    </div>
  );
};
