import React from 'react';
import { useModal } from '../../../../helpers/hooks/useModal';
import { CreateStakingModal } from '../../../../components/Modals/CreateStakingModal';

export const CreateNewFarm = ({ stakingState, fetchStakingInfo }) => {
  const { openedModal, onClose, onOpen } = useModal();
  return (
    <>
      <button
        onClick={onOpen}
        className="flex justify-center items-center rounded-3xl farms-bg min-h-[222px] min-w-[261px] sm:min-w-full border-[1px] border-white-100 backdrop-blur-large"
      >
        <div className="flex items-center justify-center farms-plus-icon-bg h-[60px] w-[60px] rounded-full">
          <img src="/icons/staking/yellowPlusIcon.svg" />
        </div>
      </button>
      {openedModal && (
        <CreateStakingModal
          fetchStakingInfo={fetchStakingInfo}
          stakingState={stakingState}
          openedModal={openedModal}
          handleCloseModal={onClose}
        />
      )}
    </>
  );
};
